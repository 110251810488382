import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import * as dates from 'src/app/helpers/date';
import { Store } from '@ngrx/store';
import { GET_CART, SET_CART, reducer, SET_AUTH } from 'src/app/store';
import * as reducers from 'src/app/store/reducers';

@Component({
	selector: 'app-order-success',
	templateUrl: './order-success.component.html',
	styleUrls: ['./order-success.component.scss'],
})
export class OrderSuccessComponent implements OnInit {
	orders = [];
	order_id = '';
	attribute_id = '';
	product_id = '';
	selectedItem: any = {};
	order = {
		_id: '',
		order_number: '',
		user_id: '',
		address_id: '',
		address: {
			_id: '',
			user_id: '',
			person_name: '',
			mobile: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			pincode: '',
			type: '',
			status: '',
		},
		file: '',
		payment_method: '',
		sub_total: '',
		shipping: '',
		discount: '',
		tax: '',
		final_total: '',
		order_date: '',
		delivery_date: '',
		items: <any>[],
		shipping_details: '',
		status: '',
		return_status: 0,
		days: 15,
		return_id: '',
		return_reason: '',
		is_returnable: '',
		return_submit: 0,
	};
	year: any = 2020;
	month: any = '0';
	user: any;
	viewOrder = false;
	isOpenModal = false;
	isReturnPress = false;
	order_error: any = '';
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
		private store: Store<reducers.AppState>
	) {}

	ngOnInit() {
		this.year = dates._getDayMonthYear('', 'y', '');
		this.month = dates._getDayMonthYear('', 'm', '');
		this.month = this.month < '10' ? '0' + this.month : this.month;
		this.user = this.httpService.getAuth();
		let params: any = this.route.snapshot.queryParams;
		if (params && params.data) {
			let data = JSON.parse(params.data);
			if (
				this.user &&
				this.user._id &&
				(data.STATUS === 'TXN_SUCCESS' || data.STATUS === 'TXN_FAILURE')
			) {
				this.updateUserList({
					type: 1,
					items: [],
					user_id: this.user._id,
				});
			}
			if (
				(data.STATUS === 'TXN_SUCCESS' ||
					data.STATUS === 'TXN_FAILURE') &&
				(!this.user || !this.user._id)
			) {
				this.store.dispatch(SET_CART({ cart: [] }));
			}
			if (data.ORDERID && data.STATUS === 'TXN_SUCCESS') {
				this.verifyOrderPurchase(data);
			} else {
				this.order_error = data.RESPMSG;
			}
		}
		if (params && params.order_id) {
			this.getOrderDetailsByPaytmOrderId(params.order_id);
		}
	}

	updateUserList(data: any) {
		this.http
			.post(this.httpService.mainUrl + 'addToUserList', {
				type: data.type,
				items: [],
				user_id: data.user_id,
			})
			.subscribe(async (response: any) => {
				if (response.status != 1) {
					this.notify.generateToast(
						'warning',
						'Warning!',
						response.message
					);
				} else {
					// this.iscartLoaded = false;
					// this.getUsersDetailsById(this.user._id);
					// this.store.dispatch(SET_CART({cart: items}));
					// this.cart.items = items;
					// this.items = this.cart.items;
				}
			});
	}

	verifyOrderPurchase(data: any) {
		this.http
			.post(this.httpService.mainUrl + 'verifyOrderPurchase', data)
			.subscribe((response: any) => {
				if (response.status == 1) {
					const data = response.data;
					this.getOrderDetailsByPaytmOrderId(data._id);
					// this.notify.generateToast('success', 'Success!', response.message);
				} else {
					this.notify.generateToast(
						'warning',
						'Payment Failed!',
						response.message
					);
				}
			});
	}

	getOrderDetailsByPaytmOrderId(orderId: any) {
		var apiUrl = this.httpService.mainUrl + 'getOrderDetailsByPaytmOrderId';
		this.http
			.post(apiUrl, { orderId: orderId })
			.subscribe((response: any) => {
				if (response.status == 1) {
					let data = response.data;
					this.order = data;
					this.notify.generateToast(
						'success',
						'Success!',
						response.message
					);
				} else {
					this.order_error = response.message;
					this.notify.generateToast(
						'warning',
						'Payment Failed!',
						response.message
					);
				}
			});
	}
}

<app-breadcrumb></app-breadcrumb>
<section>
	<div class="infrastructure">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="infrastructure-title">
						<h1>Infrastructure</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="infrastructure-box">
						<div class="infrastructure-content">
							<p>
								infrastructure details goes here!
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

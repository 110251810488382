import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpService, EmiterService, ToastService } from 'src/app/services';
import * as dates from 'src/app/helpers/date';
import * as $ from 'jquery';
import { Lightbox } from 'ngx-lightbox';


@Component({
  selector: 'app-product-review-details',
  templateUrl: './product-review-details.component.html',
  styleUrls: ['./product-review-details.component.scss']
})
export class ProductReviewDetailsComponent implements OnInit {
	product_slug = '';
	attribute_id = '';
	images:any =  [];
	reviews:any =  [];
	review = {
		average_ratings: 0,
		category_id: "",
		category_name: "",
		current_stock: 0,
		days: 0,
		description: "",
		five_star_rating_count: 0,
		four_star_rating_count: 0,
		image: "",
		is_returnable: true,
		name: "",
		offer_discount: 0,
		offer_price: 0,
		offer_type: true,
		one_star_rating_count: 0,
		price: 0,
		product_id: "",
		return_status: false,
		review_images: [],
		reviews: [],
		specification: "",
		status: true,
		three_star_rating_count: 0,
		total_ratings: 0,
		two_star_rating_count: 0,
		attribute_name: "",
		attribute_id: "",
		_id: "",
	};
	visible = false;
	index = 0;
	sortFilter = 'MOST_RECENT';
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public $http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
		public _lightbox: Lightbox
	) {

	}

	ngOnInit() {
		if(this.route.snapshot.params){
			if(this.route.snapshot.params.slug && this.route.snapshot.params._id){
				this.product_slug = this.httpService.decmpressSLUG(this.route.snapshot.params.slug);
				this.attribute_id = this.route.snapshot.params._id;
				if(this.product_slug){
					this.getProductReview(this.product_slug, this.attribute_id);
				}
			}
		}
	}

	getRatingClass(data:any){
		let str = '';
		if(data.rating == 1){
			str = "_1nLEql";
		}
		if(data.rating == 2){
			str = "_1x2VEC";
		}
		return str;
	}

	getSimilarRatingClass(data:any){
		let str = '';
		if(data.average_ratings > 0 && data.average_ratings < 2){
			str = "_1sq5Tn";
		}
		if(data.average_ratings > 2 && data.average_ratings < 3){
			str = "_2MYUs6";
		}
		return str;
	}

	getProductReview(product_slug:string, attribute_id:string){
		if(product_slug && attribute_id){
			let review = {
				product_slug: product_slug ? product_slug : '',
				attribute_id: attribute_id ? attribute_id : '',
				sort: this.sortFilter ? this.sortFilter: 'MOST_RECENT',
			}
			this.$http.post(this.httpService.mainUrl + 'getProductReview', review).subscribe(async (response:any) => {
				if(response.status == 1){
					let data = response.data;
					let images:any = [];
					if(data.review_images && data.review_images.length > 0){
						await data.review_images.map((x:any) => {
							images.push({
								title : x.image,
								caption : x.image,
								thumb : this.httpService.imgURL + x.image,
								src : this.httpService.imgURL + x.image,
								image : this.httpService.imgURL + x.image,
								visible : false,
							})
						})
						data.review_images = await images;
					}
					if(data.reviews && data.reviews.length > 0){
						for(let i = 0; i < data.reviews.length; i++){
							let imgs = await data.reviews[i].images && data.reviews[i].images.length > 0 ? data.reviews[i].images : [];
							if(imgs.length > 0){
								for(let j = 0; j < imgs.length; j++){
									imgs[j] = {
										title : imgs[j].image,
										caption : imgs[j].image,
										thumb : this.httpService.imgURL + imgs[j].image,
										src : this.httpService.imgURL + imgs[j].image,
										image : this.httpService.imgURL + imgs[j].image,
										visible : false,
									};
								}
							}
							data.reviews[i].images = await imgs;
						}
					}
					this.review = data;
					this.reviews = data.reviews;
					this.images = data.review_images;
				}
			});
		}
	}

	sortChange(event:any){
		this.sortFilter = event.target.value;
		this.getProductReview(this.product_slug, this.attribute_id);
	}

	open(index: number): void {
		// open lightbox
		this._lightbox.open(this.images, index);
	}

	close(): void {
		// close lightbox programmatically
		this._lightbox.close();
	}


}

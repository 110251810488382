// export const mainUrl = 'http://localhost:3010/Development/api/';
// export const imageUrl = 'http://localhost:3010/';
// export const chatUrl = 'http://localhost:3020/Development/api/';
// export const chatApiUrl = 'http://localhost:3020/Development/api/';
// export const chatImgUrl = 'http://localhost:3020/';


export const mainUrl = 'https://maaschoice.in:3011/Development/api/';
export const imageUrl = 'https://maaschoice.in:3011/';
export const chatUrl = 'https://maaschoice.in:3020/Development/api/';
export const chatApiUrl = 'https://maaschoice.in:3020/Development/api/';
export const chatImgUrl = 'https://maaschoice.in:3020/';


export const Auth = {
	_id: "",
	email: "",
    user_type: false,
    social_id: null,
    pass_string: "",
    mobile: "",
    secret: "",
    first_name: null,
    last_name: null,
    gender: true,
    image: null,
    status: true,
};

export const Company = {
	_id: "",
	name : "",
    logo : "",
    office_address : "",
    lat : "",
    long : "",
	mapURL: "",
    contact_number : "",
    contact_email : "",
    support_email : "",
    sales_email : "",
    facebook_link : "",
    instagram_link : "",
    youtube_link : "",
    twitter_link : "",
    pinterest_link : "",
    linkedin_link : "",
    google_plus_link : "",
};

export const Rights = [];

export const Cart = [];

export const Category = [];

export const Review = [];

export const Order = [];

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GET_AUTH, SET_AUTH, reducer} from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import * as dates from 'src/app/helpers';
import * as $ from 'jquery';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [ToastService]
})
export class LoginComponent implements OnInit {
	email:any = '';
	password:any = '';
	redirection: string = '';
	constructor(
		private http: HttpClient,
		private httpService: HttpService,

		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		private _location: Location,
		public emitter: EmiterService,
		public cd: ChangeDetectorRef,
		private store: Store<reducers.AppState>,
	) {
		const params:any = this.route.snapshot.queryParams;
		this.redirection = params && params.redirection && params.redirection !== "" ? params.redirection : "";
	}

	ngOnInit(): void {
	}

	submitLogin(event:any){
		const login = {
			username: this.email ? this.email : null,
			password: this.password ? this.password : null,
		}
		this.http.post(this.httpService.mainUrl + 'loginUser', login).subscribe((res:any) => {
			if(res.status == 1){
				this.notify.generateToast('success', "Login Success!", res.message);
				this.store.dispatch(SET_AUTH({auth: res.login}));
				console.log("this.redirection", this.redirection)
				if(this.redirection === ""){
					this.router.navigate(['/profile/personal'], {queryParams:{user_id: res.login._id}});
				}else {
					this.router.navigateByUrl(`/${this.redirection}`);
				}

			}else {
				this.notify.generateToast('error', "", res.message)
			}
		})
	}

}

import { imageUrl } from "src/app/store/models"

const addZero = (i:any) => { if (i < 10) { i = '0' + i; } return i; };
const dateNow = new Date();
const dd = addZero(dateNow.getDate());
const monthSingleDigit = dateNow.getMonth() + 1;
const mm = addZero(monthSingleDigit);
const yy = dateNow.getFullYear().toString();
const hh = addZero(dateNow.getHours().toString());
const ii = addZero(dateNow.getMinutes().toString());
const ss = addZero(dateNow.getSeconds().toString());
const ampm = hh > 12 ? 'pm' : 'am';
let twentyHourTime = hh;
if (hh > 12) {
	twentyHourTime = addZero((parseInt(hh) - 12));
}
const timestamp = yy + '-' + mm + '-' + dd + ' ' + twentyHourTime + ':' + ii + ':' + ss + ', ' + ampm;
console.log('application starting time ==> ' + timestamp);

export function _generateTimes(date: any, units: any, interval: any) {
	let ret = new Date(date); // don't change original date
	const newDate = new Date(date);
	const checkRollover = () => { if(ret.getDate() !== newDate.getDate()) {ret.setDate(0);}};
	switch (units.toLowerCase()) {
		case 'year'   :  ret.setFullYear(ret.getFullYear() + parseInt(interval)); checkRollover();  break;
		case 'quarter':  ret.setMonth(ret.getMonth() + (3 * parseInt(interval))); checkRollover();  break;
		case 'month'  :  ret.setMonth(ret.getMonth() + parseInt(interval)); checkRollover();  break;
		case 'week'   :  ret.setDate(ret.getDate() + (7 * parseInt(interval)));  break;
		case 'day'    :  ret.setDate(ret.getDate() + parseInt(interval));  break;
		case 'hour'   :  ret.setTime(ret.getTime() + (parseInt(interval) * 3600000));  break;
		case 'minute' :  ret.setTime(ret.getTime() + (parseInt(interval) * 60000));  break;
		case 'second' :  ret.setTime(ret.getTime() + (parseInt(interval) * 1000));  break;
		default       :  ret = newDate;  break;
	}
	const dd = addZero(ret.getDate().toString());
	const monthSingleDigit = ret.getMonth() + 1;
	const mm = addZero(monthSingleDigit);
	const yy = ret.getFullYear().toString();
	const hh = addZero(ret.getHours().toString());
	const ii = addZero(ret.getMinutes().toString());
	const ss = addZero(ret.getSeconds().toString());
	const timestamp = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + ii +':'+ ss;
	return timestamp;
}

export function _generateDate(date: any, units: any, interval: any, format: any) {
	let ret = new Date(date); // don't change original date
	const newDate = new Date(date);
	const checkRollover = () => { if(ret.getDate() !== newDate.getDate()) {ret.setDate(0);}};
	switch(units.toLowerCase()) {
		case 'year'   :  ret.setFullYear(ret.getFullYear() + parseInt(interval)); checkRollover();  break;
		case 'quarter':  ret.setMonth(ret.getMonth() + (3*parseInt(interval))); checkRollover();  break;
		case 'month'  :  ret.setMonth(ret.getMonth() + parseInt(interval)); checkRollover();  break;
		case 'week'   :  ret.setDate(ret.getDate() + (7*parseInt(interval)));  break;
		case 'day'    :  ret.setDate(ret.getDate() + parseInt(interval));  break;
		case 'hour'   :  ret.setTime(ret.getTime() + (parseInt(interval)*3600000));  break;
		case 'minute' :  ret.setTime(ret.getTime() + (parseInt(interval)*60000));  break;
		case 'second' :  ret.setTime(ret.getTime() + (parseInt(interval)*1000));  break;
		default       :  ret = newDate;  break;
	}
	const dd = addZero(ret.getDate().toString());
	const monthSingleDigit = ret.getMonth() + 1;
	const mm = addZero(monthSingleDigit);
	const yy = ret.getFullYear().toString();
	const hh = addZero(ret.getHours().toString());
	const ii = addZero(ret.getMinutes().toString());
	const ss = addZero(ret.getSeconds().toString());
	const ms = addZero(ret.getMilliseconds().toString());
	const ampm = hh > 12 ? 'pm' : 'am';
	let twentyHourTime = hh;
	if(hh > 12){
		twentyHourTime = addZero((parseInt(hh) - 12));
	}
	let timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
	if(format.length >= 4){
		switch(format.toLowerCase()) {
			case 'hh:ii ap, dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii + ' ' + ampm + ', ' + dd +'-'+ mm +'-'+ yy ; break;
			case 'hh:ii ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii + ' ' + ampm + ' ' + dd +'-'+ mm +'-'+ yy ; break;
			case 'hh:ii:ss ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii +':'+ ss + ' ' + ampm + ' ' + dd +'-'+ mm +'-'+ yy ; break;
			case 'dd-mm-yyyy hh:ii:ss,ap'  : timestamp =  dd +'-'+ mm +'-'+ yy +' '+ twentyHourTime +':'+ ii +':'+ ss + ', ' + ampm; break;
			case 'hh:ii:ss,ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii +':'+ ss +', '+ ampm +' '+ dd +'-'+ mm +'-'+ yy; break;
			case 'dd-mm-yyyy hh:ii:ss:ms'  : timestamp = dd +'-'+ mm +'-'+ yy +' '+ hh +':'+ ii +':'+ ss +':'+ ms; break;
			case 'dd-mm-yyyy hh:ii:ss'  : timestamp = dd +'-'+ mm +'-'+ yy +' '+ hh +':'+ ii +':'+ ss; break;
			case 'yyyy-mm-dd hh:ii:ss'  : timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss; break;
			case 'yyyy_mm_dd_hh_ii_ss'  : timestamp = yy +'_'+ mm +'_'+ dd +'_'+ hh +'_'+ ii +'_'+ ss; break;
			case 'dd-mm-yyyy'           : timestamp = dd +'-'+ mm +'-'+ yy; break;
			case 'mm/dd/yyyy'           : timestamp = mm +'/'+ dd +'/'+ yy; break;
			case 'dd:mm:yyyy'           : timestamp = dd +':'+ mm +':'+ yy; break;
			case 'dd_mm_yyyy'           : timestamp = dd +'_'+ mm +'_'+ yy; break;
			case 'yyyy_mm_dd'           : timestamp = yy +'_'+ mm +'_'+ dd; break;
			case 'yyyy-mm-dd'           : timestamp = yy +'-'+ mm +'-'+ dd; break;
			case 'yyyy:mm:dd'           : timestamp = yy +'-'+ mm +'-'+ dd; break;
			case 'yyyy'                 : timestamp = yy; break;
			default                     : break;
		}
	}
	return timestamp;
}

export function _generateTimeStamp(date: any) {
	const ret = (date) ? new Date(date) : new Date();
	const dd = addZero(ret.getDate());
	const monthSingleDigit = ret.getMonth() + 1;
	const mm = addZero(monthSingleDigit);
	const yy = ret.getFullYear().toString();
	const hh = addZero(ret.getHours().toString());
	const ii = addZero(ret.getMinutes().toString());
	const ss = addZero(ret.getSeconds().toString());
	const timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
	return timestamp;
}

export function _generateDateFormat(date: any, format: any) {
	var ret = (date) ? new Date(date) : new Date();
    let fullMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'Octomber',
        'November',
        'December',
    ]
    let shortMonths = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    let fullDays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    let shortDays = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
    ];
    var dd = addZero(ret.getDate());
    var d:any = ret.getDay();
    var monthSingleDigit = ret.getMonth() + 1;
    var mm = addZero(monthSingleDigit);
    var yy = ret.getFullYear().toString();
    var shortYear = yy.substring(2, 4);
    var hh = addZero(ret.getHours().toString());
    var ii = addZero(ret.getMinutes().toString());
    var ss = addZero(ret.getSeconds().toString());
    var ms = addZero(ret.getMilliseconds().toString());
    var ampm = hh > 12 ? 'PM' : 'AM';
    var AMPM = hh > 12 ? 'pm' : 'am';
    var twentyHourTime = hh;
    if(hh > 12){
        twentyHourTime = addZero((parseInt(hh) - 12));
    }
    var timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
    if(format.length >= 4){
        switch(format) {
            case 'hh:ii ap, dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii + ' ' + ampm + ', ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'hh:ii ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii + ' ' + ampm + ' ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'hh:ii:ss ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii +':'+ ss + ' ' + ampm + ' ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'dd-mm-yyyy hh:ii:ss,ap'  : timestamp =  dd +'-'+ mm +'-'+ yy +' '+ twentyHourTime +':'+ ii +':'+ ss + ', ' + ampm; break;
            case 'hh:ii:ss,ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii +':'+ ss +', '+ ampm +' '+ dd +'-'+ mm +'-'+ yy; break;
            case 'dd-mm-yyyy hh:ii:ss:ms'  : timestamp = dd +'-'+ mm +'-'+ yy +' '+ hh +':'+ ii +':'+ ss +':'+ ms; break;
            case 'dd-mm-yyyy hh:ii:ss'  : timestamp = dd +'-'+ mm +'-'+ yy +' '+ hh +':'+ ii +':'+ ss; break;
            case 'yyyy-mm-dd hh:ii:ss'  : timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss; break;
            case 'yyyy_mm_dd_hh_ii_ss'  : timestamp = yy +'_'+ mm +'_'+ dd +'_'+ hh +'_'+ ii +'_'+ ss; break;
            case 'mm-dd-yyyy'           : timestamp = mm +'-'+ dd +'-'+ yy; break;
            case 'mm/dd/yyyy'           : timestamp = mm +'/'+ dd +'/'+ yy; break;
            case 'dd-mm-yyyy'           : timestamp = dd +'-'+ mm +'-'+ yy; break;
            case 'dd:mm:yyyy'           : timestamp = dd +':'+ mm +':'+ yy; break;
            case 'dd_mm_yyyy'           : timestamp = dd +'_'+ mm +'_'+ yy; break;
            case 'yyyy_mm_dd'           : timestamp = yy +'_'+ mm +'_'+ dd; break;
            case 'yyyy-mm-dd'           : timestamp = yy +'-'+ mm +'-'+ dd; break;
            case 'yyyy:mm:dd'           : timestamp = yy +'-'+ mm +'-'+ dd; break;
            case 'yyyy'                 : timestamp = yy; break;
            case 'hh:ii:ss,ap'          : timestamp = twentyHourTime + ':' + ii + ':' + ss + ' ' + ampm; break;
            case 'hh:ii:ss,AP'          : timestamp =  twentyHourTime +':'+ ii +':'+ ss + ' ' + AMPM; break;
            case 'DDDD, MMMM, YYYY'           : timestamp = fullDays[parseInt(d)] +', '+ fullMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'DDDD, MM, YYYY'           : timestamp = fullDays[parseInt(d)] +', '+ shortMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'DDDD, MM, YY'           : timestamp = fullDays[parseInt(d)] +', '+ shortMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'DDDD, MMMM, YY'           : timestamp = fullDays[parseInt(d)] +', '+ fullMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'DD, MMMM, YYYY'           : timestamp = shortDays[parseInt(d)] +', '+ fullMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'DD, MMMM, YY'           : timestamp = shortDays[parseInt(d)] +', '+ fullMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'DD, MM, YY'           : timestamp = shortDays[parseInt(d)] +', '+ shortMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'dd, MMMM, YYYY'           : timestamp = dd +', '+ fullMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'dd, MM, YYYY'           : timestamp = dd +', '+ shortMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'dd, MM, YY'           : timestamp = dd +', '+ shortMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'dd, MMMM, YY'           : timestamp = dd +', '+ fullMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'M d, Y'           : timestamp = fullMonths[parseInt(mm)-1] +' '+ dd + ', '+ yy; break;
			case 'DD MMMM YYYY'           : timestamp = dd +' ' + fullMonths[parseInt(mm)-1] +' '+ yy; break;
			case 'DD MM YYYY'           : timestamp = dd +' ' + shortMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'DDDD, MM D, YYYY, hh:ii, AP'           : timestamp = fullDays[parseInt(d)] +', '+ shortMonths[parseInt(mm)-1] +' '+ dd +', '+ yy +', '+ twentyHourTime +':'+ ii + ' ' + ampm; break;
            default                     : break;
        }
    }
    return timestamp;
}

export function generate_token(length: any){
	const a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
	const b = [];
	for (let i = 0; i<length; i++) {
		const j = parseInt((Math.random() * (a.length-1)).toFixed(0));
		b[i] = a[j];
	}
	return b.join("");
}

export function generate_img_name(length: any){
	const a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
	const b = [];
	for (let i = 0; i<length; i++) {
		const j = parseInt((Math.random() * (a.length-1)).toFixed(0));
		b[i] = a[j];
	}
	return b.join("");
}

export function _generate_otp(length: any){
	const a = "1234567890".split("");
	const b = [];
	for (let i = 0; i<length; i++) {
		const j = parseInt((Math.random() * (a.length-1)).toFixed(0));
		b[i] = a[j];
	}
	return b.join("");
}

export function _getDifference(from_date: any, to_date: any){
	const date1 = from_date ? new Date(from_date) : new Date();
	const date2 = to_date ? new Date(to_date) : new Date();
	const diff = Math.abs(date1.getTime() - date2.getTime());
	const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
	return diffDays;
}

export function _generateYearMonth(units: any, interval: any) {
	const ret = new Date(); // don't change original date
	const newDate = new Date();
	const checkRollover = () => { if(ret.getDate() !== newDate.getDate()) {ret.setDate(0);}};
	if(units == 'm'){
		ret.setMonth(ret.getMonth() + parseInt(interval));
		checkRollover();
	}else {
		ret.setFullYear(ret.getFullYear() + parseInt(interval));
		checkRollover();
	}
	const dd = addZero(ret.getDate().toString());
	const monthSingleDigit = ret.getMonth() + 1;
	const mm = addZero(monthSingleDigit);
	const yy = ret.getFullYear().toString();
	const hh = addZero(ret.getHours().toString());
	const ii = addZero(ret.getMinutes().toString());
	const ss = addZero(ret.getSeconds().toString());
	const timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
	return yy;
}

export function _getDayMonthYear(date: any, type: any, format: any) {
	const nDate = date != undefined && date ? new Date(date) : new Date();
	const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
	const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const day = nDate.getDay();
	const month = nDate.getMonth();
	const yy = nDate.getFullYear().toString();
	let result;
	if(format == '1' && format != undefined){
		if(type == 'd'){
			result = day;
		}else if(type == 'm'){
			result = month + 1;
		}else {
			result = yy;
		}
	}else if(format == '2' && format != undefined){
		if(type == 'd'){
			result = days[day];
		}else if(type == 'm'){
			result = months[month];
		}else {
			result = yy;
		}
	}else {
		if(type == 'd'){
			result = day;
		}else if(type == 'm'){
			result = month + 1;
		}else {
			result = yy;
		}
	}
	return result;
}

export function _getDayMonthName(data: any, type: any) {
	const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
	const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	let result;
	if(type == 'd'){
		result = days[data];
	}else {
		result = months[(data - 1)];
	}
	return result;
}

export function _getCurrentDateMonthYear(date: any, type: any) {
	const nDate = date != undefined && date ? new Date(date) : new Date();
	const day = nDate.getDate();
	const month = nDate.getMonth();
	const yy = nDate.getFullYear().toString();
	let result;
	if(type == 'd'){
		result = day;
	}else if(type == 'm'){
		result = month + 1;
	}else {
		result = yy;
	}
	return result;
}

export function generateSecretKey(data: any){
	const key = '';
	return key;
}

export function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
	  // tslint:disable-next-line:no-bitwise
	  const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
	  return v.toString(16);
	});
}

export function generate_confirmation_code(length:any) {
	const a = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
	const b = [];
	for (let i = 0; i<length; i++) {
		const j = parseInt((Math.random() * (a.length-1)).toFixed(0));
		b[i] = a[j];
	}
	return b.join("");
}

export function getRatingClass(data:any){
	let str = '';
	// ._1nLEql //1
	// ._1x2VEC //2
	if(data.rating == 1){
		str = "_1nLEql";
	}
	if(data.rating == 2){
		str = "_1x2VEC";
	}
	return str;
}


import { Injectable, OnInit} from '@angular/core';
import {ToasterService} from 'angular2-toaster';

@Injectable({
  providedIn: 'root',
})

export class ToastService implements OnInit{
	constructor(
		private toasterService: ToasterService,

	) {
		this.toasterService = toasterService;
	}

	ngOnInit() {
	}

	generateToast(type: any, title: string, body: string) {
		this.toasterService.clear();
		this.toasterService.pop(type, title, body);
	}
}

<app-breadcrumb></app-breadcrumb>
<section>
	<div class="return-policy">
		<div class="container">
			<div class="row justify-content-center text-justify">
				<div class="col-xl-12 col-10">
					<div class="return-policy-title">
						<h1>Cancellation & Refund Policy</h1>
					</div>
				</div>
				<!-- <div class="col-xl-12 col-10">
					<div class="return-policy-box">
						<div class="return-policy-content">
							<p>
								If you are not happy with your purchase, we will accept a return of a unused product within 14 days. Once we receive the returned item we will then give a full refund (excluding shipping as we are unable to refund the initial shipping cost of your order).
								Please allow 1-2 weeks for your return to be processed.
								Discounted items are not eligible for a return.
							</p>
							<p>
								Maas choice will not issue refunds for products purchased through other entities, such as distributors or retail partners.
								Returned items must be delivered to us unused, in original packaging and in the condition they were received or may not be eligible for refund or be subject to a restocking fee. We cannot be held responsible for items damaged or lost in return shipment, therefore we recommend an insured and trackable mail service.
								This Return & Refund Policy was created by xyz return co for Maas Choice.
							</p>
							<p>
								We are unable to issue a refund without actual receipt of the item(s) or proof of received return delivery.
								We aim to accept all returns. In the unlikely event that an item is returned to us in an unsuitable condition, we may have to send it back to you. All goods will be inspected on return.
							</p>
							<p class="font-28">Key points:</p>
							<ul>
								<li>
									<label>- How long does the customer have to return the item?</label>
								</li>
								<li>
									<label>- In what condition can items be returned?</label>
								</li>
								<li>
									<label>- Will a refund be issued, and if so, how much and what type (e.g. store credit or refund)?</label>
								</li>
								<li>
									<label>- How do exchanges work?</label>
								</li>
								<li>
									<label>- Do you accept returns or refunds for sale items?</label>
								</li>
								<li>
									<label>- Who will pay for shipping?</label>
								</li>
							</ul>
						</div>
					</div>
				</div> -->
				<!-- <div class="col-xl-12 col-10 ">
					<div class="return-policy-title">
						<h1>Cancellation & Refund Policy</h1>
					</div>
				</div> -->
				<div class="col-xl-12 col-10">
					<div class="return-policy-box">
						<div class="return-policy-content">
							<p>

							</p>
							<ul>
								<li>
									<label>Cancellation</label>
									<p>
										Save and except as provided hereunder, You cannot cancel the order and demand refund once the order is successfully placed and processed by the payment gateway. Refund requests will only be considered in the following cases:
											&nbsp;&nbsp; 1. If the shipping location is not serviced by our partner courier companies.
											&nbsp;&nbsp; 2. If the buyer does not receive the ordered products within 30 days of placing the order.
										No refund requests will be entertained for damaged products. Damaged products will be exchanged as per our exchange policy.
									</p>
								</li>
								<li>
									<label>Refund</label>
									<p>
										1. From the date of written confirmation of refund to the customer, the amount will be refunded within the next 7-10 business days.
										2. The amount will be refunded in the customer's mode of payment.
									</p>
								</li>
								<li>
									<label>Note: Sale/ Discounted items (if applicable)</label>
									<p>
										Only regular priced items may be refunded. Unfortunately, items on sale/ discount cannot be refunded or exchanged
									</p>
								</li>
								<li>
									<label>No Returns will be entertained if a customer wants to return the product for the reason that he/she doesn't like it after delivery of the product or feels the product doesn't match his or her expectations. No refunds will be given in the following cases:</label>
									<p>
										1. Incorrect or insufficient address mentioned by the customer.
									</p>
									<p>
										2. Non- availability of recipient at the mentioned address and/or premises.
									</p>
									<p>
										3. Refusal to accept products.
									</p>
									<p>
										4. Delivered at the place/to the person specifically mentioned by the customer other than the customer himself.
									</p>
									<p>
										5. Force majeure event.
									</p>
									<p>
										6. In case the product has undergone any tampering by the customer.
									</p>
								</li>
							</ul>
							<p class=""font-28>
								All emails in this regard are to be sent to <strong class="themeColor"><i>[<a target="_blank" rel="noopener" href="mailto:info@masschoice.com">info@masschoice.com</a>]</i></strong>
							</p>
						</div>
					</div>
				</div>
				<div class="col-xl-12 col-10">
					<div class="return-policy-title">
						<h1>Return & Refund</h1>
					</div>
				</div>
				<div class="col-xl-12 col-10">
					<div class="return-policy-box">
						<div class="return-policy-content">
							<p>
								Return & Refunds
								We have a “7-day return and refund policy” which entitles all our members to return the product at the time of delivery if due to some reason they are not satisfied with the quality, freshness or quantity of the product. We will take the returned product back with us and issue a refund to you by way of a discount coupon of same amount that can be accrued on any product on our website.
								RETURN, REFUND & CANCELLATION POLICY
								Thanks for shopping at www.maaschoice.in  If you are not entirely satisfied with your purchase, we're here to help.
							</p>
							<ul>
								<!-- <li>
									<label>RETURNS</label>
									<p>
										You have 24 hours to report the returning reason for an item from the date you received it.
										We do not entertain Refund and Cancellation in any circumstances. Products if found defective can be returned within 48 hours of order delivery. To be eligible for a return, your item must be unused and in the same condition that you received it.
										Your item must be in the original packaging.
										Your item needs to have the receipt or proof of purchase.
										Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you of the status of your refund after inspecting the item.
										If your return is approved.
										Apart from condition reserved herein above, the following products shall not be eligible for return or replacement, viz:
										• Any product that exhibits physical damage to the box or to the product;
										• Any product that is returned without all original packaging and accessories, including the retail box and all other items originally included with the product at the time of delivery;
										• Any product without a valid, readable, untampered serial number, including but not limited to products with missing, damaged, altered, or otherwise unreadable serial number;
									</p>
								</li> -->
								<li>
									<label>REFUNDS</label>
									<p>
										No credit refunds will be provided by maaschoice.in for any reason. Product replacements will happen according to the return criteria
									</p>
								</li>
								<li>
									<label>CANCELLATION SHIPPING</label>
									<p>
										You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable.
										If you receive a refund, the cost of return shipping will be deducted from your refund.
										Please call our support team at +91-1234567 or email us at info@masschoice.in mentioning your Order ID
									</p>
								</li>
								<li>
									<label>CONTACT US</label>
									<p>
										Our support is available at +91-1234567 or email us at info@masschoice.in mentioning your Order ID
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

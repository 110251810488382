<section>
	<div class="home_slider">
		<div id="main-slider">
			<owl-carousel-o [options]="mainSliderConfig">
				<ng-container *ngFor="let slide of mainSlider">
					<ng-template carouselSlide [id]="slide._id">
						<div class="item slider" >
							<!-- [style]="'background-image: url('+httpService.generateImagePreview(slide.image)+')'" -->
							<img [src]="httpService.generateImagePreview(slide.image)"/>
							<div class="item-caption">
								<div class="item-caption-inner">
									<div class="item-caption-wrap">
										<div class="container">
											<div class="row">
												<div class="col-12 ">
													<div class="home-content">
														<h1>{{slide.title}} </h1>
														<h2>{{slide.small_description}}</h2>
														<p>{{slide.description}}</p>
														<a
															*ngIf="slide.redirect_to && slide.redirect_to._id"
															[routerLink]="'/product'"
															class="btn-theme"
														>Shop Now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</ng-container>
			</owl-carousel-o>
		</div>
		<div class="post-slide-container">
			<div class="top"></div>
			<div class="bottom"></div>
		</div>
	</div>
	<div class="clear clearfix"></div>
	<div class="boosts">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="box-bar text-center">
					<!-- <div class="boosts-box">
						<img src="assets/img/boost/WeGrow.svg" alt="" class="img-fluid">
						<h6>WE GROW</h6>
					</div>
					<div class="boosts-box">
						<img src="assets/img/boost/WeSelect.svg" alt="" class="img-fluid">
						<h6>WE SELECT</h6>
					</div>
					<div class="boosts-box">
						<img src="assets/img/boost/WeProcess.svg" alt="" class="img-fluid">
						<h6>WE MANUFACTURE</h6>
					</div>
					<div class="boosts-box">
						<img src="assets/img/boost/WePack.svg" alt="" class="img-fluid">
						<h6>WE PACK</h6>
					</div>
					<div class="boosts-box">
						<img src="assets/img/boost/WeDeliver.svg" alt="" class="img-fluid">
						<h6>WE DELIVER</h6>
					</div> -->
					<div class="boosts-box">
						<video src="assets/video/Video1.mp4"  [muted]="true" [autoplay]="true" [loop]="true" #video1 (canplay)="video1.play()" webkit-playsinline playsinline></video>
						<!-- <h6>WE GROW</h6> -->
					</div>
					<div class="boosts-box">
						<video src="assets/video/Video2.mp4"  [muted]="true" [autoplay]="true" [loop]="true" #video2 (canplay)="video2.play()" webkit-playsinline playsinline></video>
						<!-- <h6>WE SELECT</h6> -->
					</div>
					<div class="boosts-box">
						<video src="assets/video/Video3.mp4"  [muted]="true" [autoplay]="true" [loop]="true" #video3 (canplay)="video3.play()" webkit-playsinline playsinline></video>
						<!-- <h6>WE MANUFACTURE</h6> -->
					</div>
					<div class="boosts-box">
						<video src="assets/video/Video4.mp4"  [muted]="true" [autoplay]="true" [loop]="true" #video4 (canplay)="video4.play()" webkit-playsinline playsinline></video>
						<!-- <h6>WE PACK</h6> -->
					</div>
					<div class="boosts-box">
						<video src="assets/video/Video5.mp4"  [muted]="true" [autoplay]="true" [loop]="true" #video5 (canplay)="video5.play()" webkit-playsinline playsinline></video>
						<!-- <h6>WE DELIVER</h6> -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="shop-health" *ngIf="banners && banners[0] && banners[0]._id">
		<section data-section-id="1602299409613" data-section-type="image-with-text" data-animation-enabled="false" class="section image-with-text section--contrast image-with-text--image-width-two-third image-with-text--image-position-left image-with-text--text-alignment-left image-with-text--background-transparent in-view">
			<div class="special-background-wrapper">
				<div class="degrade"></div>
				<div class="diagonal-wrapper">
					<div class="top"></div>
					<div class="bottom"></div>
				</div>
			</div>
			<div class="image-with-text__inner">
				<div class="image-with-text__image db">
					<div class="image image--506018299998 ">
						<img class="image__img lazyautosizes lazyloaded" alt="" [src]="httpService.generateImagePreview(banners[0].image)">
					</div>
				</div>
				<div class="image-with-text__text-container">
					<div class="image-with-text__text">
						<div class="image-with-text__text-block image-with-text__text-block--left animation__staggered-children-fade-in">
							<div class="text-block__overline overline mb3" data-theme-editor-setting="section.1602299409613.overline">
								{{banners[0].title}}
							</div>
							<h2 class="text-block__heading type-heading-1 ma0 mb3" data-theme-editor-setting="section.1602299409613.heading">
								{{banners[0].sort_description}}
							</h2>
							<div class="text-block__subheading" data-theme-editor-setting="section.1602299409613.subheading">
								<p class="text-justify">{{banners[0].description}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- <div class="container">
			<div class="row justify-content-center">
				<div class="col-sm-12 col-md-9 col-lg-6 text-center">
					<div class="shop-health-box">
						<div class="shop-health-content">
							<img src="assets/img/home/logo-white.svg" alt="" class="img-fluid">
							<h3>{{banners[0].description}}</h3>
							<a routerLink="/about-us">Read Our Story </a>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	</div>
	<div class="widget-media widget-media--primary">
		<video src="assets/video/home_center_fs.mp4"
			class="widget__video"
			autoplay
			oncanplay="this.muted=true"
			playsinline
			loop
			muted
			data-object-fit=""
		></video>
		<div class="widget__inner">
			<div class="shell">
				<div class="widget__content-alt">
					<h6>Healthy &amp; Tasty</h6>
					<!-- <h4>It's time for popsicles to grow up</h4> -->
				</div>
			</div>
		</div>
		<div class="widget__foot">
			<div class="shell">
				<ul class="list-features">
					<li>
						<img src="assets/img/video-section/VideoIcon1.svg">
						<h6>HYGIENE PACK</h6>
					</li>
					<li>
						<img src="assets/img/video-section/VideoIcon2.svg">
						<h6>PLANT BASED SUPERFOOD</h6>
					</li>
					<li>
						<img src="assets/img/video-section/VideoIcon3.svg">
						<h6>100% NATURAL</h6>
					</li>
					<li id="fimg"> 
						<img src="assets/img/video-section/VideoIcon4.svg">
						<!-- <h6>DUST FREE</h6> -->
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="products" *ngIf="products.length > 0 || bulk_products.length > 0">
		<section class="content__product-grid">
			<div class="grid-container">
				<div class="row text-center product-grid-list" *ngIf="products.length > 0 && !isLoading">
					<ng-container *ngFor="let item of products; let i=index">
						<div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4" [ngClass]="'color'+(i+1)">
							<div class="inner product-6306979348665">
								<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)" class="product-card__title">
									<div class="icon_title_wrapper">

									<div class="icon">
										<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140">
									</div>
									<h1 class="h3 product-title">{{item.name}}</h1>
									</div>
									<div class="description show-for-large">
										<p class="small">{{item.category_name}}</p>
									</div>
								</a>
								<a class="product-card__image aos-init aos-animate" [routerLink]="'/product/' + httpService.compressSLUG(item.slug)" data-aos="">
									<div class="responsive-image__wrapper">
										<img class="lazyautosizes lazyloaded" [attr.data-src]="httpService.generateImagePreview(item.image)" [alt]="item.name" [attr.src]="httpService.generateImagePreview(item.image)">
									</div>
								</a>
								<div class="product-card__add">
									<ng-container *ngIf="item.attributes && item.attributes.length > 0">
										<div class="product__variant-wrapper">
											<ul class="product__radio">
												<ng-container *ngFor="let attr of item.attributes; let j=index">
													<ng-container *ngIf="validateAttribute(attr)">
														<li class="radio_6306979348665"  >
															<input type="radio" [attr.id]="attr._id" [attr.name]="attr._id" [attr.value]="attr._id" [attr.data-price]="getPrice(attr)" [checked]="getChecked(attr, 1)" (click)="changeAttribute(i, attr, 1)">
															<label [attr.for]="attr._id"  (click)="changeAttribute(i, attr, 1)" >
																{{getPackageName(attr)}}
															</label>
														</li>
													</ng-container>
												</ng-container>
													<!-- <li class="radio_6306979348665">
														<input type="radio" id="radio_6306979348665_2x" name="quantity" value="2" data-price="$30.00">
														<label for="radio_6306979348665_2x">
														12 CANS
														</label>
													</li>
													<li class="radio_6306979348665">
														<input type="radio" id="radio_6306979348665_4x" name="quantity" value="4" data-price="$60.00">
														<label for="radio_6306979348665_4x">
														24 CANS
														</label>
													</li> -->
											</ul>
										</div>
									</ng-container>
									<div class="buttons">
										<button class="button add-to-cart small primary" (click)="addItemToCart(item, 1)" data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
											<p class="button-price display-price" *ngIf="item.offer_price && item.offer_price > 0; else noOffer;">
												<del>₹{{item.price}}</del><br>₹{{math.trunc(item.offer_price)}}
											</p>
											<ng-template #noOffer>
												<p class="button-price display-price">₹{{item.price}}</p>
											</ng-template>
											<div class="button-text" >
												Add to Cart
											</div>
										</button>
									</div>
									<div class="bottom">
										<!-- <div class="price"><span class="display-price">₹{{item.price}}</span> - <span>₹{{item.price/5}} Per KG</span></div> -->
										<p class="footnote">
											<!-- Minimum of {{activeAttribute.package_value}}{{activeAttribute.package_type}} per order - you can mix-and-match! -->
											Free Shipping On Orders Over ₹499 Rupees!
										</p>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<!-- <div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4">
						<div class="inner product-6306979348665">
							<a href="/products/grapefruit-with-rosemary" class="product-card__title">
								<div class="icon">
									<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140">
								</div>
								<h1 class="h3 product-title">Grapefruit with rosemary</h1>
								<div class="description show-for-large">
									<p class="small">Fruity grapefruit and herbaceous rosemary for easy drinking.</p>
								</div>
							</a>
							<a class="product-card__image aos-init aos-animate" href="/products/grapefruit-with-rosemary" data-aos="">
								<div class="responsive-image__wrapper">
									<img class="lazyautosizes lazyloaded" data-srcset="https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_200 200w, https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_600 600w, https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_1200 1200w" data-sizes="auto" data-src="https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_200" alt="" sizes="202px" srcset="https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_200 200w, https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_600 600w, https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_1200 1200w" src="https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_200">
								</div>
							</a>
							<div class="product-card__add" action="/cart/add" method="post" data-cart-submit="">
								<input name="id" type="hidden" value="39006183784633">
								<input name="properties[_base_quantity]" type="hidden" value="6">
								<div class="product__variant-wrapper">
									<ul class="product__radio">
										<li class="radio_6306979348665">
											<input type="radio" id="radio_6306979348665_1x" name="quantity" value="1" data-price="$15.00" checked="">
											<label for="radio_6306979348665_1x">
											6 CANS
											</label>
										</li>
										<li class="radio_6306979348665">
											<input type="radio" id="radio_6306979348665_2x" name="quantity" value="2" data-price="$30.00">
											<label for="radio_6306979348665_2x">
											12 CANS
											</label>
										</li>
										<li class="radio_6306979348665">
											<input type="radio" id="radio_6306979348665_4x" name="quantity" value="4" data-price="$60.00">
											<label for="radio_6306979348665_4x">
											24 CANS
											</label>
										</li>
									</ul>
								</div>
								<div class="buttons">
									<button class="button add-to-cart small primary " data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
										<p class="button-price display-price">$15.00</p>
										<div class="button-text">
											Add to Cart
										</div>
									</button>
								</div>
								<div class="bottom">
									<div class="price"><span class="display-price">$15.00</span>- <span>$2.50/CAN</span></div>
									<p class="footnote">
										Minimum of 24 cans per order - you can mix-and-match!
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4">
						<div class="inner product-6306980462777">
							<a href="/products/ginger-with-turmeric" class="product-card__title">
								<div class="icon">
									<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306980462777%2F1613435838009%2FIcon-Ginger-Tumeric-Yellow.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306980462777%2F1613435838009%2FIcon-Ginger-Tumeric-Yellow.png%3Fv%3D0&amp;c_options=h_140">
								</div>
								<h1 class="h3 product-title">Ginger with Turmeric</h1>
								<div class="description show-for-large">
									<p class="small">Real ginger, turmeric, and black pepper provide a comfort that defies seasonality.</p>
								</div>
							</a>
							<a class="product-card__image aos-init aos-animate" href="/products/ginger-with-turmeric" data-aos="">
								<div class="responsive-image__wrapper">
									<img class="lazyautosizes lazyloaded" data-srcset="https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_200 200w, https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_600 600w, https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_1200 1200w" data-sizes="auto" data-src="https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_200" alt="" sizes="202px" srcset="https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_200 200w, https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_600 600w, https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_1200 1200w" src="https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_200">
								</div>
							</a>
							<div class="product-card__add" action="/cart/add" method="post" data-cart-submit="">
								<input name="id" type="hidden" value="39006182899897">
								<input name="properties[_base_quantity]" type="hidden" value="6">
								<div class="product__variant-wrapper">
									<ul class="product__radio">
										<li class="radio_6306980462777">
											<input type="radio" id="radio_6306980462777_1x" name="quantity" value="1" data-price="$15.00" checked="">
											<label for="radio_6306980462777_1x">
											6 CANS
											</label>
										</li>
										<li class="radio_6306980462777">
											<input type="radio" id="radio_6306980462777_2x" name="quantity" value="2" data-price="$30.00">
											<label for="radio_6306980462777_2x">
											12 CANS
											</label>
										</li>
										<li class="radio_6306980462777">
											<input type="radio" id="radio_6306980462777_4x" name="quantity" value="4" data-price="$60.00">
											<label for="radio_6306980462777_4x">
											24 CANS
											</label>
										</li>
									</ul>
								</div>
								<div class="buttons">
									<button class="button add-to-cart small primary " data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
										<p class="button-price display-price">$15.00</p>
										<div class="button-text">
											Add to Cart
										</div>
									</button>
								</div>
								<div class="bottom">
									<div class="price"><span class="display-price">$15.00</span>- <span>$2.50/CAN</span></div>
									<p class="footnote">
										Minimum of 24 cans per order - you can mix-and-match!
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4">
						<div class="inner product-6306979578041">
							<a href="/products/spicy-lime" class="product-card__title">
								<div class="icon">
									<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979578041%2F1613436015473%2FIcon-Spicy-Lime-Green.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979578041%2F1613436015473%2FIcon-Spicy-Lime-Green.png%3Fv%3D0&amp;c_options=h_140">
								</div>
								<h1 class="h3 product-title">Spicy Lime</h1>
								<div class="description show-for-large">
									<p class="small">Lime, Cayenne, and Sweet Basil -- for a bite and a kick.</p>
								</div>
							</a>
							<a class="product-card__image aos-init aos-animate" href="/products/spicy-lime" data-aos="">
								<div class="responsive-image__wrapper">
									<img class="lazyautosizes lazyloaded" data-srcset="https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_200 200w, https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_600 600w, https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_1200 1200w" data-sizes="auto" data-src="https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_200" alt="" sizes="202px" srcset="https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_200 200w, https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_600 600w, https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_1200 1200w" src="https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_200">
								</div>
							</a>
							<div class="product-card__add" action="/cart/add" method="post" data-cart-submit="">
								<input name="id" type="hidden" value="39006187028665">
								<input name="properties[_base_quantity]" type="hidden" value="6">
								<div class="product__variant-wrapper">
									<ul class="product__radio">
										<li class="radio_6306979578041">
											<input type="radio" id="radio_6306979578041_1x" name="quantity" value="1" data-price="$15.00" checked="">
											<label for="radio_6306979578041_1x">
											6 CANS
											</label>
										</li>
										<li class="radio_6306979578041">
											<input type="radio" id="radio_6306979578041_2x" name="quantity" value="2" data-price="$30.00">
											<label for="radio_6306979578041_2x">
											12 CANS
											</label>
										</li>
										<li class="radio_6306979578041">
											<input type="radio" id="radio_6306979578041_4x" name="quantity" value="4" data-price="$60.00">
											<label for="radio_6306979578041_4x">
											24 CANS
											</label>
										</li>
									</ul>
								</div>
								<div class="buttons">
									<button class="button add-to-cart small primary " data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
										<p class="button-price display-price">$15.00</p>
										<div class="button-text">
											Add to Cart
										</div>
									</button>
								</div>
								<div class="bottom">
									<div class="price"><span class="display-price">$15.00</span>- <span>$2.50/CAN</span></div>
									<p class="footnote">
										Minimum of 24 cans per order - you can mix-and-match!
									</p>
								</div>
							</div>
						</div>
					</div> -->
				</div>
				<div class="row text-center buy-bulk" *ngIf="bulk_products.length > 0 && !isLoading">
					<h1>BUY BULK <br />& <br /> SAVE MORE</h1>
				</div>
				<div class="row text-center product-grid-list" *ngIf="bulk_products.length > 0 && !isLoading">
					<ng-container *ngFor="let item of bulk_products; let i=index">
						<div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4" [ngClass]="'color'+(i+1)">
							<div class="inner product-6306979348665">
								<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)" class="product-card__title">
									<div class="icon">
										<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140">
									</div>
									<h1 class="h3 product-title">{{item.name}}</h1>
									<div class="description show-for-large">
										<p class="small">{{item.category_name}}</p>
									</div>
								</a>
								<a class="product-card__image aos-init aos-animate" [routerLink]="'/product/' + httpService.compressSLUG(item.slug)" data-aos="">
									<div class="responsive-image__wrapper">
										<img class="lazyautosizes lazyloaded" [attr.data-src]="httpService.generateImagePreview(item.image)" [alt]="item.name" [attr.src]="httpService.generateImagePreview(item.image)">
									</div>
								</a>
								<div class="product-card__add">
									<ng-container *ngIf="item.attributes && item.attributes.length > 0">
										<div class="product__variant-wrapper">
											<ul class="product__radio">
												<ng-container *ngFor="let attr of item.attributes; let j=index">
													<li class="radio_6306979348665" >
														<input type="radio" [attr.id]="attr._id" [attr.name]="attr._id" [attr.value]="attr._id" [attr.data-price]="getPrice(attr)" [checked]="getChecked(attr, 2)" (click)="changeAttribute(i, attr, 2)">
														<label [attr.for]="attr._id"  (click)="changeAttribute(i, attr, 2)" >
															{{getPackageName(attr)}}
														</label>
													</li>
												</ng-container>
													<!-- <li class="radio_6306979348665">
														<input type="radio" id="radio_6306979348665_2x" name="quantity" value="2" data-price="$30.00">
														<label for="radio_6306979348665_2x">
														12 CANS
														</label>
													</li>
													<li class="radio_6306979348665">
														<input type="radio" id="radio_6306979348665_4x" name="quantity" value="4" data-price="$60.00">
														<label for="radio_6306979348665_4x">
														24 CANS
														</label>
													</li> -->
											</ul>
										</div>
									</ng-container>
									<div class="buttons">
										<button class="button add-to-cart small primary" (click)="addItemToCart(item, 2)" data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
											<p class="button-price display-price" *ngIf="item.offer_price && item.offer_price > 0; else noOffer;">
												<del>₹{{item.price}}</del><br> ₹{{math.trunc(item.offer_price)}}
											</p>
											<ng-template #noOffer>
												<p class="button-price display-price">₹{{item.price}}</p>
											</ng-template>
											<div class="button-text" >
												Add to Cart
											</div>
										</button>
									</div>
									<div class="bottom">
										<!-- <div class="price"><span class="display-price">₹{{item.price}}</span> - <span>₹{{item.price/5}} Per KG</span></div> -->
										<p class="footnote">
											<!-- Minimum of {{activeBulkAttribute.package_value}}{{activeBulkAttribute.package_type}} per order - you can mix-and-match! -->
											Free Shipping On Orders Over ₹499 Rupees!
										</p>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<!-- <div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4">
						<div class="inner product-6306979348665">
							<a href="/products/grapefruit-with-rosemary" class="product-card__title">
								<div class="icon">
									<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140">
								</div>
								<h1 class="h3 product-title">Grapefruit with rosemary</h1>
								<div class="description show-for-large">
									<p class="small">Fruity grapefruit and herbaceous rosemary for easy drinking.</p>
								</div>
							</a>
							<a class="product-card__image aos-init aos-animate" href="/products/grapefruit-with-rosemary" data-aos="">
								<div class="responsive-image__wrapper">
									<img class="lazyautosizes lazyloaded" data-srcset="https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_200 200w, https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_600 600w, https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_1200 1200w" data-sizes="auto" data-src="https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_200" alt="" sizes="202px" srcset="https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_200 200w, https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_600 600w, https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_1200 1200w" src="https://cld.accentuate.io/6306979348665/1615478305514/GrapefruitRender.png?v=1636069545237&amp;options=w_200">
								</div>
							</a>
							<div class="product-card__add" action="/cart/add" method="post" data-cart-submit="">
								<input name="id" type="hidden" value="39006183784633">
								<input name="properties[_base_quantity]" type="hidden" value="6">
								<div class="product__variant-wrapper">
									<ul class="product__radio">
										<li class="radio_6306979348665">
											<input type="radio" id="radio_6306979348665_1x" name="quantity" value="1" data-price="$15.00" checked="">
											<label for="radio_6306979348665_1x">
											6 CANS
											</label>
										</li>
										<li class="radio_6306979348665">
											<input type="radio" id="radio_6306979348665_2x" name="quantity" value="2" data-price="$30.00">
											<label for="radio_6306979348665_2x">
											12 CANS
											</label>
										</li>
										<li class="radio_6306979348665">
											<input type="radio" id="radio_6306979348665_4x" name="quantity" value="4" data-price="$60.00">
											<label for="radio_6306979348665_4x">
											24 CANS
											</label>
										</li>
									</ul>
								</div>
								<div class="buttons">
									<button class="button add-to-cart small primary " data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
										<p class="button-price display-price">$15.00</p>
										<div class="button-text">
											Add to Cart
										</div>
									</button>
								</div>
								<div class="bottom">
									<div class="price"><span class="display-price">$15.00</span>- <span>$2.50/CAN</span></div>
									<p class="footnote">
										Minimum of 24 cans per order - you can mix-and-match!
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4">
						<div class="inner product-6306980462777">
							<a href="/products/ginger-with-turmeric" class="product-card__title">
								<div class="icon">
									<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306980462777%2F1613435838009%2FIcon-Ginger-Tumeric-Yellow.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306980462777%2F1613435838009%2FIcon-Ginger-Tumeric-Yellow.png%3Fv%3D0&amp;c_options=h_140">
								</div>
								<h1 class="h3 product-title">Ginger with Turmeric</h1>
								<div class="description show-for-large">
									<p class="small">Real ginger, turmeric, and black pepper provide a comfort that defies seasonality.</p>
								</div>
							</a>
							<a class="product-card__image aos-init aos-animate" href="/products/ginger-with-turmeric" data-aos="">
								<div class="responsive-image__wrapper">
									<img class="lazyautosizes lazyloaded" data-srcset="https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_200 200w, https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_600 600w, https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_1200 1200w" data-sizes="auto" data-src="https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_200" alt="" sizes="202px" srcset="https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_200 200w, https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_600 600w, https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_1200 1200w" src="https://cld.accentuate.io/6306980462777/1615478201216/GingerRender.png?v=1636069612671&amp;options=w_200">
								</div>
							</a>
							<div class="product-card__add" action="/cart/add" method="post" data-cart-submit="">
								<input name="id" type="hidden" value="39006182899897">
								<input name="properties[_base_quantity]" type="hidden" value="6">
								<div class="product__variant-wrapper">
									<ul class="product__radio">
										<li class="radio_6306980462777">
											<input type="radio" id="radio_6306980462777_1x" name="quantity" value="1" data-price="$15.00" checked="">
											<label for="radio_6306980462777_1x">
											6 CANS
											</label>
										</li>
										<li class="radio_6306980462777">
											<input type="radio" id="radio_6306980462777_2x" name="quantity" value="2" data-price="$30.00">
											<label for="radio_6306980462777_2x">
											12 CANS
											</label>
										</li>
										<li class="radio_6306980462777">
											<input type="radio" id="radio_6306980462777_4x" name="quantity" value="4" data-price="$60.00">
											<label for="radio_6306980462777_4x">
											24 CANS
											</label>
										</li>
									</ul>
								</div>
								<div class="buttons">
									<button class="button add-to-cart small primary " data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
										<p class="button-price display-price">$15.00</p>
										<div class="button-text">
											Add to Cart
										</div>
									</button>
								</div>
								<div class="bottom">
									<div class="price"><span class="display-price">$15.00</span>- <span>$2.50/CAN</span></div>
									<p class="footnote">
										Minimum of 24 cans per order - you can mix-and-match!
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4">
						<div class="inner product-6306979578041">
							<a href="/products/spicy-lime" class="product-card__title">
								<div class="icon">
									<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979578041%2F1613436015473%2FIcon-Spicy-Lime-Green.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979578041%2F1613436015473%2FIcon-Spicy-Lime-Green.png%3Fv%3D0&amp;c_options=h_140">
								</div>
								<h1 class="h3 product-title">Spicy Lime</h1>
								<div class="description show-for-large">
									<p class="small">Lime, Cayenne, and Sweet Basil -- for a bite and a kick.</p>
								</div>
							</a>
							<a class="product-card__image aos-init aos-animate" href="/products/spicy-lime" data-aos="">
								<div class="responsive-image__wrapper">
									<img class="lazyautosizes lazyloaded" data-srcset="https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_200 200w, https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_600 600w, https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_1200 1200w" data-sizes="auto" data-src="https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_200" alt="" sizes="202px" srcset="https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_200 200w, https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_600 600w, https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_1200 1200w" src="https://cld.accentuate.io/6306979578041/1615478536671/LimeRender.png?v=1636069700580&amp;options=w_200">
								</div>
							</a>
							<div class="product-card__add" action="/cart/add" method="post" data-cart-submit="">
								<input name="id" type="hidden" value="39006187028665">
								<input name="properties[_base_quantity]" type="hidden" value="6">
								<div class="product__variant-wrapper">
									<ul class="product__radio">
										<li class="radio_6306979578041">
											<input type="radio" id="radio_6306979578041_1x" name="quantity" value="1" data-price="$15.00" checked="">
											<label for="radio_6306979578041_1x">
											6 CANS
											</label>
										</li>
										<li class="radio_6306979578041">
											<input type="radio" id="radio_6306979578041_2x" name="quantity" value="2" data-price="$30.00">
											<label for="radio_6306979578041_2x">
											12 CANS
											</label>
										</li>
										<li class="radio_6306979578041">
											<input type="radio" id="radio_6306979578041_4x" name="quantity" value="4" data-price="$60.00">
											<label for="radio_6306979578041_4x">
											24 CANS
											</label>
										</li>
									</ul>
								</div>
								<div class="buttons">
									<button class="button add-to-cart small primary " data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
										<p class="button-price display-price">$15.00</p>
										<div class="button-text">
											Add to Cart
										</div>
									</button>
								</div>
								<div class="bottom">
									<div class="price"><span class="display-price">$15.00</span>- <span>$2.50/CAN</span></div>
									<p class="footnote">
										Minimum of 24 cans per order - you can mix-and-match!
									</p>
								</div>
							</div>
						</div>
					</div> -->
				</div>
				<div class="row" *ngIf="!isLoading">
					<div class="view-all">
						<a class="btn-theme" routerLink="/product">View All</a>
					</div>
				</div>
			</div>
		</section>
		<!-- <div class="container">
			<div class="row">
				<div class="col-lg-12 text-center">
					<div class="section-head">
						<h2>Products</h2>
						<p>
							An excellent and healthy substitute for daily snacks, consumption of dry fruits enhances energy and stamina.
						</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div id="products-list">
						<div class="row">
							<ng-container *ngFor="let item of featured_products; let i=index">
								<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
									<div class="products-box">
										<a
											[routerLink]="'/product/' + httpService.compressSLUG(item.slug)"
										>
											<img [attr.src]="httpService.generateImagePreview(item.image)" [attr.alt]="item.name" class="img-fluid">
										</a>
										<h5>{{item.name}}</h5>
										<p>
											{{item.category_name}}
										</p>
										<div class="product-price">
											<h6 *ngIf="item.offer_price && item.offer_price > 0; else noOffer;">
												<del>₹{{item.price}}</del> ₹{{item.offer_price}}
											</h6>
											<ng-template #noOffer>
												<h6>
													₹{{item.price}} / pc
												</h6>
											</ng-template>
											<a class="btn-theme" [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">
												Explore
											</a>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="view-all">
						<a class="btn btn-theme" routerLink="/product">View All </a>
					</div>
				</div>
			</div>
		</div> -->
	</div>
	<!-- <div class="blog" *ngIf="banners && banners[1] && banners[1]._id && banners[2] && banners[2]._id">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="image-section">
					<div class="image" [ngStyle]="{backgroundImage: 'url(' + httpService.generateImagePreview(banners[1].image)+')'}">
						<div class="content">
							<p>{{banners[1].description}}</p>
						</div>
					</div>
					<div class="image" [ngStyle]="{backgroundImage: 'url(' + httpService.generateImagePreview(banners[2].image)+')'}">
						<div class="content">
							<p>{{banners[2].description}}</p>
						</div>
					</div>
				</div>
				<div class="video-section">
					<iframe
						width="100%"
						height="450"
						src="https://www.youtube.com/embed/588vIeynWFw"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay="true" loop="true"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
					>
					</iframe>
				</div>
				<div class="col-sm-9 col-md-6 col-lg-4 text-center">
					<div class="blog-box">
						<img src="assets/img/home/blog-1.jpg" alt="" class="img-fluid">
						<h3>Maa's Love at Every Step!</h3>
					</div>
				</div>
				<div class="col-sm-9 col-md-6 col-lg-4 text-center">
					<div class="blog-box">
						<img src="assets/img/home/blog-2.jpg" alt="" class="img-fluid">
						<h3>Maa's Hygiene at Our Core</h3>
					</div>
				</div>
				<div class="col-sm-9 col-md-6 col-lg-4 text-center">
					<div class="blog-box">
						<img src="assets/img/home/blog-3.jpg" alt="" class="img-fluid">
						<h3>Maa's Hygiene at Our Core</h3>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<div
		class="most-trusted"
		*ngIf="banners && banners[1] && banners[1]._id"
		
	>
	<!-- [ngStyle]="{backgroundImage: 'url(' + httpService.generateImagePreview(banners[1].image)+')'}" -->
		
		<img [src]="httpService.generateImagePreview(banners[1].image)">

		<!-- <div class="container">
			<div class="row align-items-center justify-content-center">
				<div class="col-md-10 col-lg-12">
					<div class="most-trusted-box">
						<img [src]="httpService.generateImagePreview(banners[3].image)" [alt]="banners[3].title" class="img-fluid">
					</div>
				</div>
				<div class="col-md-10 col-lg-6">
					<div class="most-trusted-content">
						<h3><span>Maa's Choice</span> {{banners[3].sort_description}}</h3>
						<p>
							{{banners[3].description}}
						</p>
						<a
							*ngIf="banners[3].redirect_to && banners[3].redirect_to._id"
							[routerLink]="'/product'"
							[queryParams]="{'category': banners[3].redirect_to._id}"
						>Know More </a>
					</div>
				</div>
			</div>
		</div> -->
	</div>
	<!-- <div class="news">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 text-center">
					<div class="section-head">
						<h2>News & Insights</h2>
						<p>
							Best product quality, optimal service and know-how in product development.
						</p>
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-sm-9 col-md-6 col-lg-4 mbtm-30">
					<div class="news-box">
						<img src="assets/img/home/news-1.png" alt="" class="img-fluid">
						<h6>Jan 6, 2022</h6>
						<h4>Calm yourself with this 5-Ingredient Vegan Cashew Yogurt for Breakfast</h4>
						<a href="#">Read More </a>
					</div>
				</div>
				<div class="col-sm-9 col-md-6 col-lg-4 mbtm-30">
					<div class="news-box">
						<img src="assets/img/home/news-2.png" alt="" class="img-fluid">
						<h6>Jan 5, 2022</h6>
						<h4>Coconut - Almond Bites Recipe</h4>
						<a href="#">Read More </a>
					</div>
				</div>
				<div class="col-sm-9 col-md-6 col-lg-4 mbtm-30">
					<div class="news-box">
						<img src="assets/img/home/news-3.png" alt="" class="img-fluid">
						<h6>Jan 4, 2022</h6>
						<h4>Raisin Bread Recipe for the Baker in You</h4>
						<a href="#">Read More </a>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12 text-center">
					<div class="news-bottom">
						<a href="#">Read All </a>
					</div>
				</div>
			</div>
		</div>
	</div> -->
</section>

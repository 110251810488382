import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-about-us',
	templateUrl: './about-us.component.html',
	styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
	teamSlideConfig = {
		dots: false,
		arrows: false,
		fade: false,
		speed: 1500,
		autoplay: true,
		autoplaySpeed: 1500,
		slidesToShow: 4,
		infinite: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-arrow-left"></i>Prev</button>',
		nextArrow: '<button type="button" class="slick-next">Next<i class="fal fa-arrow-right"></i></button>',
		responsive: [{
			breakpoint: 1600,
			settings: {
				slidesToShow: 4,
			}
		}, {
			breakpoint: 1400,
			settings: {
				slidesToShow: 4,
			}
		}, {
			breakpoint: 1200,
			settings: {
				slidesToShow: 3,
			}
		}, {
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
			}
		}, {
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
			}
		}, {
			breakpoint: 576,
			settings: {
				slidesToShow: 1,
			}
		}]
	};

	healthySlideConfig = {
		dots: false,
		arrows: false,
		fade: false,
		speed: 1500,
		autoplay: true,
		autoplaySpeed: 1500,
		slidesToShow: 3,
		infinite: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-arrow-left"></i>Prev</button>',
		nextArrow: '<button type="button" class="slick-next">Next<i class="fal fa-arrow-right"></i></button>',
		responsive: [{
			breakpoint: 1600,
			settings: {
				slidesToShow: 3,
			}
		}, {
			breakpoint: 1400,
			settings: {
				slidesToShow: 3,
			}
		}, {
			breakpoint: 1200,
			settings: {
				slidesToShow: 3,
			}
		}, {
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
			}
		}, {
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
			}
		}, {
			breakpoint: 576,
			settings: {
				slidesToShow: 1,
			}
		}]
	};
	constructor() { }

	ngOnInit(): void {
	}

}

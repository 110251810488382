import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import {
	OwlOptions,
	SlidesOutputData,
	CarouselComponent,
} from 'ngx-owl-carousel-o';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import { Store } from '@ngrx/store';
import { SET_CART, Auth } from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.scss'],
	providers: [ToastService],
})
export class ProductDetailComponent implements OnInit, OnDestroy {
	sizeGuideShow: boolean = false;

	@ViewChild('mainCarousel', { static: false })
	mainSlider!: CarouselComponent;
	activeSlide = 0;
	public mainSliderConfig: OwlOptions = {
		items: 1,
		autoplay: true,
		slideBy: 1,
		autoplaySpeed: 1000,
		nav: true,
		dots: false,
		center: true,
		loop: true,
		margin: 10,
		navText: [
			'<i class="fas fa-chevron-left"></i>',
			'<i class="fas fa-chevron-right"></i>',
		],
		responsiveRefreshRate: 200,
		responsive: {
			0: {
				items: 1,
			},
			479: {
				items: 1,
			},
			768: {
				items: 1,
			},
			979: {
				items: 1,
			},
			1199: {
				items: 1,
			},
		},
	};
	public cart: any = [];
	public images: any = [];
	public similar: any = [];
	public reviews: any = [];
	public review_images: any = [];
	public attributes: any = [];
	public currentAttribute: any = {};
	public product: any = {};
	public slug: any = '';
	public user: any;
	public qty: any = 1;
	public ratings = Array(5)
		.fill(0)
		.map((x, i) => i + 1);
	public session: any;
	private sub!: Subscription;
	public pincode!: any;
	public isDeliverable: boolean = false;
	public activeAttribute: any = {};
	currentAccordin: number = 0;
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public http: HttpClient,
		public emitter: EmiterService,
		public cd: ChangeDetectorRef,
		private store: Store<reducers.AppState>
	) {
		this.router.events.subscribe((event: any) => {
			this.user = this.httpService.auth;
			this.session = this.httpService.getSession();
			const query: any = this.route.snapshot.params;

			if (event instanceof NavigationStart) {
				if (query.slug) {
					this.slug = this.httpService.decmpressSLUG(query.slug);
				}
			}
			if (event instanceof NavigationEnd) {
				if (query.slug) {
					this.slug = this.httpService.decmpressSLUG(query.slug);
				}
				this.emitter.emit('isloading', {
					type: 'isloading',
					data: true,
				});
				this.getFullProductDetails(this.slug);
			}
		});

		this.sub = this.store.subscribe((data: any) => {
			this.cart =
				data.main &&
				data.main.cart != undefined &&
				Array.isArray(data.main.cart)
					? data.main.cart
					: [];
			this.user =
				data.main && data.main.auth != undefined && data.main.auth._id
					? data.main.auth
					: Auth;
			this.user = this.httpService.auth;
			this.session =
				data.main && data.main.session != undefined && data.main.session
					? data.main.session
					: '';
			this.session = this.httpService.getSession();
		});
	}

	ngOnInit(): void {
		this.emitter.emit('isloading', {
			type: 'isloading',
			data: true,
			autoClose: false,
		});
	}

	reloadSlider() {
		this.mainSliderConfig = { ...this.mainSliderConfig, loop: true }; // this will make the carousel refresh
	}

	thumbSlideChanged(index: any) {
		const id = index.toString();
		this.mainSlider.to(`slide_no_${id}`);
		this.mainSlider.initialized;
	}

	mainSlideChanged(data: SlidesOutputData) {
		this.activeSlide = data.startPosition || 0;
	}

	getFullProductDetails(slug: any) {
		let params = {
			slug: slug ? slug : this.slug,
		};
		this.http
			.post(this.httpService.mainUrl + 'getFullProductDetails', params)
			.subscribe((result: any) => {
				if (result.status == 1) {
					this.product =
						result.data && result.data._id ? result.data : {};
					this.attributes =
						this.product.attributes &&
						Array.isArray(this.product.attributes)
							? this.product.attributes
							: [];
					this.currentAttribute =
						this.product.attributes &&
						Array.isArray(this.product.attributes)
							? this.product.attributes[0]
							: {};
					this.similar =
						this.product.similar &&
						Array.isArray(this.product.similar)
							? this.product.similar.slice(0, 3)
							: [];
					this.images =
						this.product.images &&
						Array.isArray(this.product.images)
							? this.product.images
							: [];
					this.reloadSlider();
					this.reviews =
						this.product.reviews &&
						Array.isArray(this.product.reviews)
							? this.product.reviews
							: [];
					this.review_images =
						this.product.review_images &&
						Array.isArray(this.product.review_images)
							? this.product.review_images
							: [];
					// this.cd.detectChanges();
					this.packageChnage(this.currentAttribute._id);
					this.emitter.emit('isloading', {
						type: 'isloading',
						data: false,
					});
				} else {
					this.product = {};
					this.currentAttribute = {};
					this.attributes = [];
					this.similar = [];
					this.images = [];
					this.reviews = [];
					this.review_images = [];
					this.emitter.emit('isloading', {
						type: 'isloading',
						data: false,
					});
				}
			});
	}

	pincodeChange(event: any) {
		this.isDeliverable = false;
		let pincode: any = parseInt(event.target.value);
		if (event.target.value && event.target.value.length === 6) {
			this.pincode = pincode;
			this.checkDeliveryService();
		} else {
			this.notify.generateToast(
				'warning',
				'Opps, Validation Error!',
				'Please enter valid 6 digit pincode!'
			);
		}
	}

	addToCart(product: any, action: any) {
		if (this.currentAttribute.stock >= this.qty) {
			const cart: any = JSON.parse(JSON.stringify(this.cart.valueOf()));
			const item: any = JSON.parse(
				JSON.stringify({ ...product.valueOf() })
			);
			delete item.similar;
			delete item.reviews;
			delete item.review_images;
			delete item.images;
			delete item.attributes;
			delete item.smallest;
			delete item.largest;
			item.qty = this.qty;
			item.product_id = item._id;
			item.offer = this.currentAttribute.offer;
			item.price = this.currentAttribute.package_price;
			item.selected = this.currentAttribute._id;
			item.selected_attribute = this.currentAttribute;
			item.return_status = false;
			item.return_reason = '';
			item.current_stock = this.currentAttribute.stock;
			item.last_stock = this.currentAttribute.stock;
			item.stock = this.currentAttribute.stock;
			if (item.offer) {
				item.offer.amount = item.offer._id
					? parseFloat(item.offer.amount)
					: 0;
			}
			if (item.offer_price) {
				item.total = this.qty * item.offer_price;
			} else {
				item.total = this.qty * item.price;
			}
			let ind = cart.findIndex(
				(cartItem: any) =>
					cartItem._id == item._id &&
					cartItem.selected === item.selected
			);
			if (ind > -1) {
				cart[ind] = item;
			} else {
				cart.push(item);
			}
			this.cart = [...JSON.parse(JSON.stringify(cart.valueOf()))];

			if (this.user && this.user._id) {
				this.store.dispatch(SET_CART({ cart: this.cart }));
				this.addToUserList(1, this.cart);
				this.emitter.emit('update_cart', { updateCart: true });
				this.emitter.emit('cart_change', true, false, false);
			} else {
				if (this.session) {
					this.store.dispatch(SET_CART({ cart: this.cart }));
					this.emitter.emit('update_cart', { updateCart: true });
					this.emitter.emit('cart_change', true, false, false);
					// this.notify.generateToast('success', "Item Added!", "Your Item Added To Cart Successfully!");
				} else {
					// this.notify.generateToast('warning', 'Warning!', "Please Login First, To Add Items In Your Cart");
				}
			}
			if (action && action == 1) {
				this.router.navigate(['/cart']);
			}
		} else {
			this.notify.generateToast(
				'warning',
				'Opps!',
				this.currentAttribute.stock !== 0
					? `Only available stock is ${this.currentAttribute.stock}`
					: 'This Product is Out of Stock.'
			);
		}
	}

	addToUserList(type: number, items: any) {
		let user = this.httpService.auth;
		let params = {
			user_id: user && user._id ? user._id : '',
			items: items,
			type: type,
		};
		this.http
			.post(this.httpService.mainUrl + 'addToUserList', params)
			.subscribe((result: any) => {
				if (result.status == 1) {
					let action = 'Removed From';
					if (items.length > 0) {
						action = 'Added To';
					}
					let msg =
						type == 1
							? `Item ${action} Cart Successfully!`
							: `Item ${action} Wishlist Successfully!`;
					// this.notify.generateToast('success', 'Success!', msg);
				} else {
					// this.notify.generateToast('error', 'Error!', result.message);
				}
			});
	}

	async packageChnage(attribute_id: string) {
		let selected = attribute_id || '';
		const item = this.attributes.filter(
			(item: any) => item._id === selected
		)[0];
		if (item.stock !== 0) {
			this.qty = 1;
			if (item && item._id) {
				this.currentAttribute = JSON.parse(JSON.stringify({ ...item }));
				this.product.price = parseInt(item.package_price);
				this.product.offer = item.offer;
				this.product.offer_price = 0;
				this.product.offer_type = null;
				this.product.offer_amount = 0;
				let price: any = parseInt(item.package_price);
				if (
					this.currentAttribute &&
					this.currentAttribute.offer &&
					this.currentAttribute.offer._id
				) {
					// offer start
					if (this.product.offer) {
						this.product.offer_amount = parseFloat(
							this.product.offer.amount
						);
						let discount: any = 0;
						if (this.product.offer.type == 'true') {
							discount =
								(this.product.price *
									this.product.offer.amount) /
								100;
						} else {
							discount = this.product.offer.amount;
						}
						this.product.offer_type = this.product.offer.type;
						this.product.offer_price = Math.round(
							parseInt(price) - parseInt(discount)
						);
					}
				}
			}
		} else {
			this.notify.generateToast(
				'warning',
				'Error!',
				'This Product is out of Stock'
			);
		}
	}

	getPackageName(item: any) {
		let packageType = item?.package_type?.toUpperCase();
		if (packageType === 'GM') {
			packageType = 'g';
		}
		if (packageType === 'KG') {
			packageType = 'Kg';
		}
		if (packageType === 'LTR') {
			packageType = 'L';
		}
		let name = `${item.package_value}${packageType}`;
		return name;
		// GM -> g		// example: 250g
		// KG -> KG	// example: 1Kg
		// ML -> ML	// exmaple: 10ml
		// LTR -> l	// example: 1l
		// Ton -> Ton	// example: 1Ton
	}

	checkDeliveryService() {
		const weight = `${this.currentAttribute.package_value}${this.currentAttribute.package_type}`;
		this.http
			.post(this.httpService.mainUrl + 'checkDeliveryService', {
				pincode: this.pincode,
				weight: weight,
			})
			.subscribe((res: any) => {
				if (res.status == 1) {
					// this.pincode = "";
					this.isDeliverable = true;
					this.notify.generateToast(
						'success',
						'Delivery Available!',
						res.message
					);
				} else {
					this.isDeliverable = false;
					this.notify.generateToast(
						'warning',
						'Opps, Delivery Not Available!',
						res.message
					);
				}
			});
	}

	getRatingsTitle(item: any) {
		return 'Rated ' + parseFloat(item.rating).toFixed(2) + ' out of 5';
	}

	getRating(item: any) {
		return parseFloat(item.rating).toFixed(2);
	}

	showSizeGuides(event: any) {
		this.sizeGuideShow = !this.sizeGuideShow;
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	getPrice(attribute: any) {
		let price: any = '0';
		if (attribute.offer && parseInt(attribute.offer.amount)) {
			if (attribute.offer.type == false) {
				price = attribute.package_price - attribute.offer.amount;
			} else {
				price =
					parseInt(attribute.package_price) -
					(parseInt(attribute.offer.amount) *
						parseInt(attribute.package_price)) /
						100;
			}
		}
		return price;
	}

	getChecked(attribute: any) {
		let isChecked: boolean = false;
		isChecked = this.activeAttribute?._id === attribute._id || false;
		return isChecked;
	}

	changeAttribute(itemIndex: any, attribute: any) {
		let price: any = '0';
		if (attribute.offer && parseInt(attribute.offer.amount)) {
			if (attribute.offer.type == false) {
				price = attribute.package_price - attribute.offer.amount;
			} else {
				price =
					parseInt(attribute.package_price) -
					(parseInt(attribute.offer.amount) *
						parseInt(attribute.package_price)) /
						100;
			}
		}
		this.activeAttribute = attribute;
		this.product.similar[itemIndex].price = attribute.package_price;
		this.product.similar[itemIndex].offer_price = price;
		this.product.similar[itemIndex].offer_amount =
			attribute.offer && attribute.offer.amount
				? attribute.offer.amount
				: 0;
		this.product.similar[itemIndex].offer_type =
			attribute.offer && attribute.offer.type
				? attribute.offer.type
				: null;
		this.product.similar[itemIndex].offer = attribute.offer
			? attribute.offer
			: null;
		this.cd.detectChanges();
	}

	randomIntFromInterval(min: number, max: number) {
		// min and max included
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	getColor(index: number) {
		const colors: any = {
			1: '#5da205',
			2: '#f78888',
			3: '#ececec',
			4: '#90ccf4',
			5: '#ececec',
			6: '#f3d250',
			7: '#f78888',
			8: '#f3d250',
			9: '#ececec',
			10: '#5da205',
			11: '#f78888',
			12: '#ececec',
		};
		var color1 = '#5da205';
		var color2 = '#f78888';
		var color3 = '#ececec';
		var color4 = '#90ccf4';
		var color5 = '#ececec';
		var colorb1 = '#f78888';
		var colorb2 = '#f3d250';
		var colorb3 = '#ececec';
		let colorSelected = colors[index + 1];
		let style = { backgroundColor: `transparentize(${colorSelected}, .3)` };
		return style;
		// return `color${this.randomIntFromInterval(1, index)}`;
	}

	getBGColor(index: number) {
		const colors: any = {
			1: '#5da205',
			2: '#f78888',
			3: '#ececec',
			4: '#90ccf4',
			5: '#ececec',
			6: '#f3d250',
			7: '#f78888',
			8: '#f3d250',
			9: '#ececec',
			10: '#5da205',
			11: '#f78888',
			12: '#ececec',
		};
		var color1 = '#5da205';
		var color2 = '#f78888';
		var color3 = '#ececec';
		var color4 = '#90ccf4';
		var color5 = '#ececec';
		var colorb1 = '#f78888';
		var colorb2 = '#f3d250';
		var colorb3 = '#ececec';
		let colorSelected = colors[index + 1];
		let style = { backgroundColor: `transparentize(${colorSelected}, .3)` };
		return style;
	}
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
	faq:any = [];
	constructor(
		public httpService: HttpService,
		public http: HttpClient,
	) { }

	ngOnInit(): void {
		this.getFAQ();
	}

	getFAQ(){
		this.http.post(this.httpService.mainUrl + 'getFAQ',{}).subscribe((res:any) => {
			if(res.status == 1){
				this.faq = res.data;
			}
		})
	}

	getQuestionNumber(number: number){
		return number < 10 ? '0'+number : number;
	}

}

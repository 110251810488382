<section>
	<div class="product-list-banner">
	</div>
	<div class="clear clearfix"></div>
	<div class="product-list">
		<div class="container-fluid">
			<div class="row">
				<div class="col-xl-12">

					<div class="product-list-filter">
						<div class="row">
							<div class="col-md-6 col-6 onlyDesktop">
								<div class="result">
									<label>Viewing</label>
									<p>{{result}}</p>
								</div>
							</div>
							<div class="col-md-6 col-6 onlyDesktop">
								<div class="shop-by shop-by-label">
									<label
									>Shop By</label>
									<p 
										class="dropdown-toggle"
										data-bs-toggle="dropdown"
										aria-expanded="false"
										id="shop-by"
										role="button"
										*ngIf="activeCategory">{{activeCategory}} <span class="icon"></span></p>
									<ul class="dropdown-menu cats" aria-labelledby="shop-by" *ngIf="category.length > 0">
										<li class="cat-item" [ngClass]="{'active': category_id === ''}">
											<a href="javascript:void(0);" (click)="activeCategory = 'All Item'; categoryChange('')">All Item</a>
										</li>
										<ng-container *ngFor="let item of category; let i=index">
											<li class="cat-item" [ngClass]="{'active': category_id == item._id}">
												<a href="javascript:void(0);" (click)="activeCategory = item.name; categoryChange(item._id)">{{item.name}}</a>
											</li>
										</ng-container>
									</ul>
								</div>
							</div>
							<div class="col-md-12 onlyMobile">
								<div class="result">
									<label>Viewing</label>
									<p>{{result}}</p>
								</div>
								<div class="shop-by shop-by-label">
									<label 
									>Shop By</label>
									<p 
										class="dropdown-toggle"
										data-bs-toggle="dropdown"
										aria-expanded="false"
										id="shop-by"
										role="button"
										*ngIf="activeCategory">{{activeCategory}} <span class="icon"></span></p>
									<ul class="dropdown-menu cats" aria-labelledby="shop-by" *ngIf="category.length > 0">
										<li class="cat-item">
											<a href="javascript:void(0);" (click)="activeCategory = 'All Item'; categoryChange('')">All Item</a>
										</li>
										<ng-container *ngFor="let item of category; let i=index">
											<li class="cat-item">
												<a href="javascript:void(0);" (click)="activeCategory = item.name; categoryChange(item._id)">{{item.name}}</a>
											</li>
										</ng-container>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="clear clearfix"></div>
					<div class="product-list-items">
						<section class="content__product-grid">
							<div class="grid-container">
								<div class="row text-center product-grid-list" *ngIf="products.length > 0">
									<ng-container *ngFor="let item of products; let i=index">
										<div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-4" [ngClass]="'color'+(i+1)">
											<div class="inner product-6306979348665">
												<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)" class="product-card__title">
													<div class="icon">
														<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140">
													</div>
													<h1 class="h3 product-title">{{item.name}}</h1>
													<div class="description show-for-large">
														<p class="small">{{item.category_name}}</p>
													</div>
												</a>
												<a class="product-card__image aos-init aos-animate" [routerLink]="'/product/' + httpService.compressSLUG(item.slug)" data-aos="">
													<div class="responsive-image__wrapper">
														<img class="lazyautosizes lazyloaded" [attr.data-src]="httpService.generateImagePreview(item.image)" [alt]="item.name" [attr.src]="httpService.generateImagePreview(item.image)">
													</div>
												</a>
												<div class="product-card__add">
													<ng-container *ngIf="item.attributes && item.attributes.length > 0">
														<div class="product__variant-wrapper">
															<ul class="product__radio">
																<ng-container *ngFor="let attr of item.attributes; let j=index">
																	<li class="radio_6306979348665" >
																		<input type="radio" [attr.name]="attr._id" [attr.value]="attr._id" [attr.data-price]="getPrice(attr)" [checked]="getChecked(attr)" (click)="changeAttribute(i, attr)">
																		<label [attr.for]="attr._id"  (click)="changeAttribute(i, attr)" >
																			{{getPackageName(attr)}}
																		</label>
																	</li>
																</ng-container>
															</ul>
														</div>
													</ng-container>
													<div class="buttons">
														<button class="button add-to-cart small primary" (click)="addItemToCart(item, 2)" data-open="offCanvasCart" type="submit" aria-expanded="false" aria-controls="offCanvasCart">
															<p class="button-price display-price" *ngIf="item.offer_price && item.offer_price > 0; else noOffer;">
																<del>₹{{item.price}}</del><br> ₹{{math.trunc(item.offer_price)}}
															</p>
															<ng-template #noOffer>
																<p class="button-price display-price">₹{{item.price}}</p>
															</ng-template>
															<div class="button-text" >
																Add to Cart
															</div>
														</button>
													</div>
													<div class="bottom">
														<!-- <div class="price"><span class="display-price">₹{{item.price}}</span> - <span>₹{{item.price/5}} Per KG</span></div> -->
														<p class="footnote">
															MINIMUM OF 500GM PER ORDER - YOU CAN MIX-AND-MATCH!
														</p>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
								<div class="row" *ngIf="resultBottom !== ''" >
									<div class="col-md-12">
										<label class="resultBottom">{{resultBottom}}</label>
									</div>
								</div>
								<div class="row" *ngIf="hasPage && products.length > 0" >
									<div class="view-all">
										<button class="btn btn-md btn-theme" type="button" (click)="loadMore()">View More</button>
									</div>
								</div>
							</div>
						</section>
					</div>
					<div class="clear clearfix"></div>
				</div>
			</div>
		</div>
	</div>
</section>

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
	ActivatedRoute,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	RoutesRecognized,
} from '@angular/router';
import { HttpService } from 'src/app/services';
const product = {
	path: '/product',
	name: 'PRODUCT',
	isLink: true,
};

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
	title: any = 'Home';
	links: any = [
		{
			path: '/',
			name: 'HOME',
			isLink: true,
		},
	];
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public cd: ChangeDetectorRef,
		private httpService: HttpService
	) {
		this.router.events.forEach((event) => {
			if (event instanceof NavigationStart) {
				// console.log("Breadcrumb Router NavigationStart event", event)
			}
			if (event instanceof NavigationEnd) {
				let uri: string = event.url ? event.url : '';
				this.title = uri.split('/')[1].toLocaleUpperCase();
				// console.log("Breadcrumb uri", uri)
				//
				let component: any = '';
				if (this.route.snapshot) {
					component = this.route.snapshot.component;
					// console.log("is Product Details ? ", (component.name === 'ProductDetailComponent'))
				}
				let params: any = '';
				let query: any = '';
				if (this.route.snapshot) {
					params = this.route.snapshot.params;
				}
				if (this.route.snapshot) {
					query = this.route.snapshot.queryParams;
				}
				if (component.name === 'ProductDetailComponent') {
					this.links.push(product);
					if (this.route.snapshot.params) {
						// console.log("Breadcrumb if this.route.snapshot", this.route.snapshot.params)
						const params: any = this.route.snapshot.params;
						if (params.slug) {
							this.title = decodeURIComponent(
								this.httpService.decmpressSLUG(params.slug)
							).toLocaleUpperCase();
							// console.log("Breadcrumb if before this.title", this.title)
							this.title =
								this.title.indexOf('?') > -1
									? this.title.split('?')[0]
									: this.title;
							this.links.push({
								path: '',
								name: this.title,
								isLink: false,
							});
						}
					}
				} else if (
					component.name === 'MyProfileComponent' ||
					uri.indexOf('profile') > -1
				) {
					this.title = '';
					// console.log("Breadcrumb if before this.title", this.title)
					// this.title = this.title.indexOf('?') > -1 ? this.title.split('?')[0] : this.title;
					let slug = params.slug ? params.slug : 'profile';
					this.title = slug.toLocaleUpperCase();
					this.links = [
						{
							path: '/',
							name: 'HOME',
							isLink: true,
						},
						{
							path: '/profile/personal',
							name: 'Profile',
							isLink: true,
						},
						{
							path: '',
							name: this.title,
							isLink: false,
						},
					];
				} else if (component.name === 'OrderDetailsComponent') {
					this.title = '';
					// console.log("Breadcrumb if before this.title", this.title)
					// this.title = this.title.indexOf('?') > -1 ? this.title.split('?')[0] : this.title;
					let slug = params.slug ? params.slug : 'Order';
					this.title = slug.toLocaleUpperCase();
					this.links = [
						{
							path: '/',
							name: 'HOME',
							isLink: true,
						},
						{
							path: '/profile/orders',
							name: 'My Order',
							isLink: true,
						},
						{
							path: '',
							name: 'Order Details',
							isLink: false,
						},
					];
				} else if (component.name === 'OrdersComponent') {
					this.title = '';
					// console.log("Breadcrumb if before this.title", this.title)
					// this.title = this.title.indexOf('?') > -1 ? this.title.split('?')[0] : this.title;
					let slug = params.slug ? params.slug : 'Order';
					this.title = slug.toLocaleUpperCase();
					this.links = [
						{
							path: '/',
							name: 'HOME',
							isLink: true,
						},
						{
							path: '/profile/personal',
							name: 'Profile',
							isLink: true,
						},
						{
							path: '/profile/orders',
							name: 'My Order',
							isLink: true,
						},
					];
				} else if (component.name === 'WriteProductReviewComponent') {
					this.title = '';
					let slug = params.slug ? params.slug : '';
					this.title = decodeURIComponent(
						this.httpService.decmpressSLUG(slug)
					).toLocaleUpperCase();
					const order_id = params.order_id;
					const attribute_id = params.selected;
					const product_slug = params.slug;
					const product_id = params.product_id;
					this.links = [
						{
							path: '/',
							name: 'HOME',
							isLink: true,
						},
						{
							path: '/product',
							name: 'Product',
							isLink: true,
						},
						{
							path:
								'/product/' +
								this.httpService.decmpressSLUG(params.slug),
							name: decodeURIComponent(
								this.httpService.decmpressSLUG(params.slug)
							).toLocaleUpperCase(),
							isLink: true,
						},
						{
							path: ``,
							name: 'Write Product Review',
							isLink: false,
						},
					];
				} else if (component.name === 'ProductReviewDetailsComponent') {
					this.title = '';
					// console.log("Breadcrumb is write product review")
					let slug = params.slug ? params.slug : '';
					this.title = decodeURIComponent(
						this.httpService.decmpressSLUG(slug)
					).toLocaleUpperCase();
					const order_id = params.order_id;
					const attribute_id = params._id;
					const product_slug = params.slug;
					const product_id = params.product_id;
					this.links = [
						{
							path: '/',
							name: 'HOME',
							isLink: true,
						},
						{
							path: '/product',
							name: 'Product',
							isLink: true,
						},
						{
							path:
								'/product/' +
								this.httpService.decmpressSLUG(params.slug),
							name: decodeURIComponent(
								this.httpService.decmpressSLUG(params.slug)
							).toLocaleUpperCase(),
							isLink: true,
						},
						{
							path: ``,
							name: 'Product Review Details',
							isLink: false,
						},
					];
				} else if (
					component.name === 'OrderSuccessComponent' ||
					uri.indexOf('order-success') > -1
				) {
					// console.log("Breadcrumb else before this.title", this.title)
					this.title =
						this.title.indexOf('?') > -1
							? this.title.split('?')[0]
							: this.title;
					let slug =
						this.title.indexOf('?') > -1
							? this.title.split('?')[0]
							: this.title;
					if (query && query.data) {
						let data = JSON.parse(query.data);
						if (data.STATUS === 'TXN_FAILURE') {
							this.title = 'ORDER-FAILED';
							slug = 'ORDER-FAILED';
						}
					}
					this.links.push({
						path: '',
						name: slug,
						isLink: false,
					});
				} else {
					// console.log('else ')
					// console.log("Breadcrumb else before this.title", this.title)
					this.title =
						this.title.indexOf('?') > -1
							? this.title.split('?')[0]
							: this.title;
					// console.log("Breadcrumb else after this.title", this.title)
					this.links.push({
						path: '',
						name: this.title,
						isLink: false,
					});
				}
			}
			// this.links.reverse();
		});
	}

	ngOnInit(): void {}
}

<app-breadcrumb></app-breadcrumb>
<section>
	<div class="faq">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="faq-title">
						<h1>FAQ</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="faq-box">
						<div class="faq-content row">
							<div class="col-xl-12" *ngIf="faq && faq.length > 0;">
								<ng-container *ngFor="let item of faq; let i=index">
									<div class="accordion-item">
										<div class="accordion-header">
											<h3 class="accordion-title">{{getQuestionNumber(i+1)}}. {{item.question}}</h3>
										</div>
										<div class="accordion-body">
											<p class="accordion-text"><span class="fa fa-arrow-right"></span> {{item.answer}}</p>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import 'src/assets/js/jquery-ui.min.js';
import { ToastService, HttpService, EmiterService } from 'src/app/services';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	providers: [ToastService],
})
export class SearchComponent implements OnInit {
	math = Math;
	public activeView = 'grid';
	public products: any = [];
	public ratings = Array(5)
		.fill(0)
		.map((x, i) => i + 1);
	public result = '';
	public searchText = '';
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public $http: HttpClient,
		private _location: Location,
		public emitter: EmiterService
	) {
		this.router.events.subscribe((event: any) => {
			const params: any = this.route.snapshot.params;
			if (params && params.string) {
				this.searchText = params.string;
			}
		});
	}
	ngOnInit() {
		this.emitter.emit('isloading', {
			type: 'isloading',
			data: true,
			autoClose: false,
		});
		this.router.events.subscribe((event: any) => {
			const params: any = this.route.snapshot.params;
			if (params && params.string) {
				this.searchText = params.string;
			}
		});
		if (this.searchText && this.searchText.length > 0) {
			this.searchInAllProduct(this.searchText);
		}
	}

	addActiveClass(element: any) {
		$('a.lav-link').removeClass('active');
		$(element.target).toggleClass('active');
	}

	changeActiveView(view: any) {
		this.activeView = view;
		this.searchInAllProduct('');
	}

	searchInAllProduct(search: string) {
		let params = {
			search: search ? search : '',
		};
		if (search) {
			this.$http
				.post(this.httpService.mainUrl + 'searchInAllProduct', params)
				.subscribe((result: any) => {
					this.emitter.emit('isloading', {
						type: 'isloading',
						data: false,
					});
					if (result.status == 1) {
						this.products =
							result.data && Array.isArray(result.data)
								? result.data
								: [];
						if (this.products.length > 0) {
							this.result = `<span>${this.products.length}</span> matching records found`;
						}
					} else {
						this.products = [];
					}
				});
		}
	}
}

<app-breadcrumb></app-breadcrumb>
<section class="checkout-result-page">
	<div class="container">
		<div class="row text-lg-start justify-content-center">
			<div class="col-lg-12 col-sm-12 mt-lg-0">
				<section class="o_detail">
					<div id="order_detail_page" *ngIf="order && order.order_number != ''; else noOrderError;">
						<div class="container-fluid">
							<div class="row justify-content-center">
								<div class="col-md-12">
									<h5 class="page-title">Order Placed Successfully!</h5>
								</div>
								<div class="col-sm-5 ">
									<div id="order_details">
										<div class="">
											</div>
											<div class="col-sm-3 col-md-12 col-lg-6" *ngIf="order.file">
												<div class="invoice">
													<a
														class="btn invoice_btn"
														target="_blank"
														[attr.download]="order.order_number"
														[attr.href]="httpService.imgURL + order.file"
													>Download Invoice</a>
												</div>
											</div>
										</div>
										<div class="order_box mt-3">
												<div class="">
													<div class="order_summary">
														<h4>Order Summary</h4>
														
																<div class="order_product_bg mt-3" *ngIf="order.items && order.items.length > 0">
																	<ng-container *ngFor="let data of order.items; let index=index">
																		<div class="order_product_list">
																			<div class="media ">
																				<a href="javascript:void(0)" [routerLink]="'/product/' + httpService.compressSLUG(data.slug)">
																					<img [attr.src]="httpService.imgURL + data.image" [attr.alt]="data.name">
																				</a>
																				<div class="media-body mt-2 ms-3">
																					<a href="javascript:void(0)" [routerLink]="'/product/' + httpService.compressSLUG(data.slug)">
																						<h4>{{data.name}}</h4>
																						<p>Quantity: <span>{{data.qty}}</span></p>
																						<p>Weight: <span>{{data.selected_attribute.package_value + data.selected_attribute.package_type}}</span></p>
																						<p>Price: <span>₹ {{data.offer_price}}</span></p>
																					</a>
																				</div>
																			</div>
																		</div>
																	</ng-container>
																</div>
														<p>Item(s) Subtotal:<span>₹ {{order.sub_total}}</span></p>
														<p>COD Charges:<span>₹ {{order.shipping}}</span></p>
														<p>Shipping:<span>₹ 0</span></p>
														<p>Tax GST (5%):<span>Included</span></p>
														<!-- <hr /> -->
														<h6 class="mt-2">Grand Total:<span>₹ {{order.final_total}}</span></h6>
													</div>
													
												</div>
													<div class="order_summary mt-2">
														<p>Order Number:<span>{{order.order_number}}</span></p>
														<p>Ordered on<span>{{order.order_date}}</span></p>
													
													</div>
													<div class="mt-2">
														<div class="order_summary">
														<h6>Payment Method: <span>{{order.payment_method}}</span></h6>
														</div>
													</div>
												<div class="col-sm-12 col-md-12 col-lg-10">
													<!-- <hr /> -->
												<div class=" mt-3">
													<div class="shipping_address">
														<h5>Shipping Address</h5>
														<p>{{order.address.person_name}}</p>
														<p>{{order.address.mobile}}</p>
														<p>{{order.address.address1}}</p>
														<p>{{order.address.address2}},</p>
														<p>{{order.address.city}}, {{order.address.state}} - {{order.address.pincode}}</p>
													</div>
												</div>
												
											</div>
									
										<!-- <div class="order_product">
											<div class="">
												<div class="col-md-12 col-lg-8">
											
												</div>
												<div class="col-md-12 col-lg-4">
													<div class="action_buttons">
														<div class="request_support" *ngIf="order.status === 'Delivered'">
															<a [routerLink]="'/product/' + selectedItem.slug + '/pid/' + selectedItem._id +'/review/' + attribute_id + '/order/' + order_id" class="btn request_btn">Write Review</a>
														</div>
													</div>
												</div>
											</div>
										</div> -->
									</div>
								</div>
							</div>
						</div>
					</div>
					<ng-template #noOrderError>
						<div id="order_detail_page">
							<div class="container-fluid">
								<div class="row justify-content-center">
									<div class="col-md-12">
										<h5 class="page-title">{{order_error}}</h5>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</section>
			</div>
		</div>
	</div>
</section>

<app-breadcrumb></app-breadcrumb>
<section>
	<div class="search product-list">
		<div class="container">
			<div class="row">
				<div class="col-xl-12">
					<div class="product-list-box">
						<div class="row">
							<div class="col-md-12">
								<div class="product-top-pager">
									<div class="border-top"></div>
									<div class="pagination-container">
										<div class="nav" role="tablist">
											<a (click)="changeActiveView('grid')" href="javascript:void(0);" class="icon-btn" id="tab-shop-grid" data-bs-toggle="tab" data-bs-target="#tab-grid" role="tab" aria-controls="tab-grid" aria-selected="false"><i class="fa fa-th"></i></a>
											<a (click)="changeActiveView('list')" href="javascript:void(0);" class="icon-btn active" id="tab-shop-list" data-bs-toggle="tab" data-bs-target="#tab-list" role="tab" aria-controls="tab-grid" aria-selected="true"><i class="fa fa-bars"></i></a>
										</div>
										<div class="results" [innerHTML]="this.result"></div>
									</div>
									<div class="border-top"></div>
								</div>
								<div class="product-container">
									<div class="tab-content" id="nav-tabContent">
										<div class="tab-pane fade" [ngClass]="activeView === 'grid' ? 'show active' : ''" id="tab-grid" role="tabpanel" aria-labelledby="tab-shop-grid">
											<div class="row">
												<ng-container *ngFor="let item of products; let i=index">
													<div class="col-sm-12 col-md-4 col-lg-4 col-xl-3">
														<div class="product-box">
															<div class="product-img">
																<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">
																	<img [attr.src]="httpService.generateImagePreview(item.image)" [attr.alt]="item.name">
																</a>
															</div>
															<div class="product-content">
																<p class="product-category mb-1">
																	{{item.category_name}}
																</p>
																<h6 class="product-title">
																	<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">{{item.name}}</a>
																</h6>
																<span class="product-price">
																	<strong *ngIf="item.offer_price && item.offer_price > 0; else noOffer;">
																		<del>₹{{item.price}}</del> ₹{{math.trunc(item.offer_price)}}
																	</strong>
																	<ng-template #noOffer>
																		<strong>₹{{item.price}}</strong>
																	</ng-template>
																</span>
																<p class="m-0 product-rating">
																	<ng-container *ngFor="let n of ratings; let ri=index">
																		<i class="fas fa-star" *ngIf=" n <= item.average_ratings; else noFillStar"></i>
																		<ng-template #noFillStar>
																			<i class="far fa-star"></i>
																		</ng-template>
																	</ng-container>
																	<span *ngIf="item.total_ratings > 0">
																		({{item.total_ratings}})
																	</span>
																</p>
															</div>
														</div>
													</div>
												</ng-container>
											</div>
										</div>
										<div class="tab-pane fade" [ngClass]="activeView === 'list' ? 'show active' : ''" id="tab-list" role="tabpanel" aria-labelledby="tab-shop-list">
											<div class="row ">
												<ng-container *ngFor="let item of products; let i=index">
													<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
														<div class="product-box2">
															<div class="product-img">
																<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">
																	<img [attr.src]="httpService.imgURL + item.image" [attr.alt]="item.name">
																</a>
															</div>
															<div class="product-content align-items-center">
																<div>
																	<p class="product-category mb-1">
																		{{item.category_name}}
																	</p>
																	<h6 class="product-title mb-1">
																		<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">{{item.name}}</a>
																	</h6>
																	<span class="price">
																		<strong *ngIf="item.offer_price && item.offer_price > 0; else noOffer;">
																			<del>₹{{item.price}}</del> ₹{{math.trunc(item.offer_price)}}
																		</strong>
																		<ng-template #noOffer>
																			<strong>₹{{item.price}}</strong>
																		</ng-template>
																	</span>
																	<p class="m-0 rating">
																		<ng-container *ngFor="let n of ratings; let ri=index">
																			<i class="fas fa-star" *ngIf=" n <= item.average_ratings; else noFillStar"></i>
																			<ng-template #noFillStar>
																				<i class="far fa-star"></i>
																			</ng-template>
																		</ng-container>
																		<span *ngIf="item.total_ratings > 0">
																			({{item.total_ratings}})
																		</span>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="clear clearfix"></div>
				</div>
			</div>
		</div>
	</div>
</section>

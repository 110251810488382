<!--==============================
	Preloader
==============================-->
<app-loader></app-loader>
<!--========================
Sticky Header
========================-->
<header
	class="header header-sticky"
	(click)="checkClose($event)"
	(window:resize)="onResize($event)"
	(window:scroll)="onScroll($event)"
	[ngClass]="{'scrolled': scrolled}"
>
	<div class="web-menu">
		<nav class="navbar navbar-expand-lg">
			<div class="container pe-0 ps-0">
				<!-- <a class="me-auto logo-mobile" routerLink="/home" routerLinkActive="active" *ngIf="isMobile">
					<img src="assets/img/logo.png" alt="" class="img-fluid">
				</a> -->
				<button class="navbar-toggler"
					#navbarToggler
					(click)="toggleMenu($event)"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation">
					<i class="fas fa-bars"></i>
				</button>
				

				<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'collapse-view': isMobile}"
				[style.background-color]="isMobile ? 'white' : 'transparent'">
					<div data-bs-toggle="collapse" *ngIf="isMobile" data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						(click)="toggleMenuButton">
						<div class="logo-section-header" *ngIf="isMobile" (click)="toggleMenu($event)">
							<a>
								<img src="assets/img/logo.png" alt="Mass Choice" class="img-fluid">
							</a>
						</div>
					</div>
				
					<button data-bs-toggle="collapse" *ngIf="isMobile"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent" class="closeButton border-theme text-theme bg-theme-hover sideMenuCls" (click)="toggleMenuButton">
						<i class="fas fa-times"></i>
					</button>
					<ul class="navbar-nav align-items-center mobile-view"  [ngClass]="{'mobile-view': isMobile}">
						<li class="nav-item" >
							<a class="nav-link" (click)="collapseNav()" routerLink="/" routerLinkActive="active">Home</a>
						</li>
						<div [ngClass]="{'mobile-view-border': isMobile}"></div>
						<li class="nav-item" >
							<a class="nav-link" (click)="collapseNav()" routerLink="/about-us" routerLinkActive="active">Company</a>
						</li>
							<div [ngClass]="{'mobile-view-border': isMobile}"></div>
						<li class="nav-item" >
							<a class="nav-link" (click)="collapseNav()" routerLink="/product" routerLinkActive="active">Shop</a>
						</li>
							<div [ngClass]="{'mobile-view-border': isMobile}"></div>
						<!-- <li class="nav-item"><a (click)="collapseNav()" routerLink="/login">Login</a></li>
						<li class="nav-item"><a (click)="collapseNav()" routerLink="/register">Register</a></li> -->
						<!-- <ng-container *ngIf="category && category.length > 0; else noCategory">
							<ng-container *ngIf="isMobile === false; else mobileView">
								<li class="nav-item product" (click)="toggleMegaMenu($event)">
									<a
										class="nav-link product"
										href="javascript:void(0);"
										role="button"
									>
										Shop
										<span  class="fa fa-caret-down product" aria-hidden="true"></span>
									</a>
									<div class="mega-menu-wrapper" [ngClass]="openMegaMenu ? 'show' : ''">
										<div class="megamenu-panel fade" aria-labelledby="category" [ngClass]="openMegaMenu ? 'show' : ''">
											<div class="megamenu-lists row">
												<ng-container *ngFor="let cat of category; let i=index">
													<div class="col-md-2">
														<a href="javascript:void(0);" [id]="cat._id">
															{{cat.name}}
															<span class="fa fa-caret-down" aria-hidden="true"></span>
														</a>
														<ul class="megamenu-list">
															<ng-container *ngFor="let item of cat.children; let i=index">
																<li>
																	<a
																		(click)="collapseNav()"
																		[routerLink]="'/product'"
																		[queryParams]="{'category': item._id}"
																	>
																		{{item.name}}
																	</a>
																</li>
															</ng-container>
														</ul>
													</div>
												</ng-container>
											</div>
										</div>
									</div>
								</li>
							</ng-container>
							<ng-template #mobileView>
								<li class="nav-item product">
									<a
										class="dropdown-toggle nav-link product"
										href="javascript:void(0);"
										role="button"
										id="category"
										data-bs-toggle="dropdown"
										aria-expanded="false">
										Shop
									</a>
									<ul class="dropdown-menu menu1" aria-labelledby="category">
										<ng-container *ngFor="let item1 of category; let i=index">
											<ng-container *ngIf="item1.children else noChild1;">
												<li class="dropdown">
													<a href="javascript:void(0);" (click)="changeActiveCategory(item1._id, 1)" [attr.id]="item1._id" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">
														{{item1.name}}
													</a>
													<ul class="dropdown-menu menu2" [attr.aria-labelledby]="item1._id" [ngClass]="openSub1 === item1._id ? 'open' : ''">
														<ng-container *ngFor="let item2 of item1.children; let i=index">
															<ng-container *ngIf="item2.children else noChild2;">
																<li class="dropdown-submenu">
																	<a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
																		{{item2.name}}
																	</a>
																	<ul class="dropdown-menu menu3">
																		<ng-container *ngFor="let item3 of item2.children; let i=index">
																			<ng-container *ngIf="item3.children else noChild3;">
																				<li class="dropdown-submenu-2">
																					<ng-container *ngFor="let item4 of item3.children; let i=index">
																						<a
																							[routerLink]="'/product'"
																							[queryParams]="{'category': item4._id}"
																							(click)="collapseNav()"
																						>
																							{{item4.name}}
																						</a>
																					</ng-container>
																				</li>
																			</ng-container>
																			<ng-template #noChild3>
																				<li>
																					<a
																						[routerLink]="'/product'"
																						[queryParams]="{'category': item3._id}"
																						(click)="collapseNav()"
																					>
																						{{item3.name}}
																					</a>
																				</li>
																			</ng-template>
																		</ng-container>
																	</ul>
																</li>
															</ng-container>
															<ng-template #noChild2>
																<li>
																	<a
																		[routerLink]="'/product'"
																		[queryParams]="{'category': item2._id}"
																		(click)="collapseNav()"
																	>
																		{{item2.name}}
																	</a>
																</li>
															</ng-template>
														</ng-container>
													</ul>
												</li>
											</ng-container>
											<ng-template #noChild1>
												<li>
													<a
														[routerLink]="'/product'"
														[queryParams]="{'category': item1._id}"
														(click)="collapseNav()"
													>
														{{item1.name}}
													</a>
												</li>
											</ng-template>
										</ng-container>
									</ul>
								</li>
							</ng-template>
						</ng-container>
						<ng-template #noCategory>
							<li class="nav-item">
								<a class="nav-link" (click)="collapseNav()" routerLink="/product" routerLinkActive="active">Products</a>
							</li>
						</ng-template> -->

						<li class="nav-item" *ngIf="isMobile && auth && auth._id" >
							<a class="nav-link" (click)="collapseNav()" routerLink="/profile/personal">My Profile</a>
						</li>
						<div [ngClass]="{'mobile-view-border': isMobile && auth && auth._id}"></div>
						<li class="nav-item" *ngIf="isMobile && auth && auth._id">
							<a class="nav-link" (click)="collapseNav()" routerLink="/profile/address">My Address</a>
						</li>
							<div [ngClass]="{'mobile-view-border': isMobile && auth && auth._id}"></div>
						<li class="nav-item" *ngIf="isMobile && auth && auth._id" >
							<a class="nav-link" (click)="collapseNav()" routerLink="/profile/orders">My Order</a>
						</li>
							<div [ngClass]="{'mobile-view-border': isMobile && auth && auth._id}"></div>
						<li class="nav-item" *ngIf="isMobile && auth && auth._id" >
							<a class="nav-link" (click)="collapseNav()" routerLink="/logout">Logout</a>
						</li>

						<li class="nav-item" *ngIf="isMobile && auth._id === ''" >
							<a class="nav-link" (click)="collapseNav()" routerLink="/login">Login</a>
						</li>
							<div [ngClass]="{'mobile-view-border': isMobile}"></div>
						<li class="nav-item" *ngIf="isMobile && auth._id === ''" >
							<a class="nav-link" (click)="collapseNav()" routerLink="/register">Register</a>
						</li>


						<li class="nav-item user" *ngIf="isMobile">
							<a
								class="dropdown-toggle nav-link" *ngIf="auth && auth._id !== ''"
								href="javascript:void(0);"
								role="button"
								id="my-profile"
								data-bs-toggle="dropdown"
								aria-expanded="false">
								<i class="user fa fa-user"></i>
								{{auth.first_name}}
							</a>
							<a
								class="dropdown-toggle nologin nav-link" *ngIf="!auth._id;"
								href="javascript:void(0);"
								role="button"
								id="my-profile"
								data-bs-toggle="dropdown"
								aria-expanded="false">
								<!-- <i class="user fa fa-user"></i> -->
							</a>
							<ul class="dropdown-menu" aria-labelledby="my-profile" *ngIf="auth && auth._id; else noLogin2;">
								<li class="nav-item"><a (click)="collapseNav()" routerLink="/profile/personal">My Profile</a></li>
								<li class="nav-item"><a (click)="collapseNav()" routerLink="/profile/address">My Address</a></li>
								<li class="nav-item"><a (click)="collapseNav()" routerLink="/profile/orders">My Order</a></li>
								<li class="nav-item"><a (click)="collapseNav()" routerLink="/logout">Logout</a></li>
							</ul>
							<ng-template #noLogin2>
								<ul class="dropdown-menu" aria-labelledby="my-profile"  >
									<li class="nav-item"><a (click)="collapseNav()" routerLink="/login">Login</a></li>
									<li class="nav-item"><a (click)="collapseNav()" routerLink="/register">Register</a></li>
								</ul>
							</ng-template>
						</li>
							<div [ngClass]="{'mobile-view-border': isMobile}"></div>
						<!-- <li class="nav-item badge" *ngIf="isMobile">
							<a class="nav-link" (click)="collapseNav()" routerLink="/cart">
								<span class="fa fa-shopping-cart">
								</span>
								<span class="badge badge-theme">{{cart.items.length}}</span>
							</a>
						</li> -->
					</ul>
				</div>
			</div>
		</nav>
		<div class="logo-section">
			<a routerLink="/">
				<img src="assets/img/logo.png" alt="Mass Choice" class="img-fluid">
			</a>
		</div>
		<div class="right-section">
			<ul class="navbar-nav user">
				<li class="nav-item" *ngIf="!isMobile">
					<a
						class="dropdown-toggle nav-link" *ngIf="auth && auth._id !== ''"
						href="javascript:void(0);"
						role="button"
						id="my-profile"
						data-bs-toggle="dropdown"
						aria-expanded="false">
						<i class="user fa fa-user"></i>
						{{auth.first_name}}
					</a>
					<a
						class="dropdown-toggle nologin nav-link" *ngIf="!auth._id;"
						href="javascript:void(0);"
						role="button"
						id="my-profile"
						data-bs-toggle="dropdown"
						aria-expanded="false">
						<i class="user fa fa-user"></i>
					</a>
					<ul class="dropdown-menu" aria-labelledby="my-profile" *ngIf="auth && auth._id; else noLogin2;">
						<li class="nav-item"><a routerLink="/profile/personal">My Profile</a></li>
						<li class="nav-item"><a routerLink="/profile/address">My Address</a></li>
						<li class="nav-item"><a routerLink="/profile/orders">My Order</a></li>
						<li class="nav-item"><a routerLink="/logout">Logout</a></li>
					</ul>
					<ng-template #noLogin2>
						<ul class="dropdown-menu" aria-labelledby="my-profile"  >
							<li class="nav-item"><a routerLink="/login">Login</a></li>
							<li class="nav-item"><a routerLink="/register">Register</a></li>
						</ul>
					</ng-template>
				</li>
				<li class="nav-item badge">
					<a class="nav-link" (click)="onClickedInSide($event);" href="javascript:void(0);">
						<span class="fa fa-shopping-cart">
						</span>
						<span class="badge badge-theme">{{cart.items.length}}</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="sidemenu-wrapper" [ngClass]="{'show': showCart === true}" >
		<div class="sidemenu-content" (clickOutside)="onClickedOutside($event,false)">
			<div class="cart-header">
				<h3 class="widget_title">Cart <span class="cart-count">{{items.length}}</span></h3>
				<p class="free-shipping">Free Shipping On Orders Over ₹499 Rupees!</p>
				<button class="closeButton border-theme text-theme bg-theme-hover sideMenuCls" (click)="showCart = false">
					<i class="fas fa-times"></i>
				</button>
			</div>
			<div class="cart-section" [ngClass]="{'items-empty': items.length === 0}">
				<div class="widget widget_shopping_cart">
					<div class="widget_shopping_cart_content">
						<div class="items" *ngIf="items && items.length > 0; else noItem;">
							<ng-container *ngFor="let item of items; let index=index">
								<div class="item">
									<div class="image">
										<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">
											<img [attr.src]="httpService.imgURL + item.image" [attr.alt]="item.name">
										</a>
									</div>
									<div class="main">
										<div class="main-top">
											<div class="main-top-left">
												<h3 class="title">
													<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">{{item.name}}</a>
												</h3>
												<ul class="properties-key-value">
													<li data-key="Flavor"><span class="properties-key-value-key">Category</span><span class="properties-key-value-spacer">: </span><span class="properties-key-value-value">{{item.category_name}}</span></li>
													<li data-key="Pack Size"><span class="properties-key-value-key">Pack Size</span><span class="properties-key-value-spacer">: </span><span class="properties-key-value-value">{{getPackageName(item.selected_attribute)}}</span></li>
												</ul>
											</div>
											<button class="remove" aria-label="remove Slate" (click)="removeItem(item, index)">
												<svg width="9" viewBox="0 0 10 10">
													<path d="M9.677 8.118a1.102 1.102 0 11-1.559 1.56L5 6.558 1.882 9.677a1.102 1.102 0 11-1.56-1.559L3.442 5 .323 1.882A1.102 1.102 0 111.882.322L5 3.442 8.118.323a1.102 1.102 0 111.56 1.559L6.558 5l3.118 3.118z" fill="#cacaca" fill-rule="nonzero"></path>
												</svg>
											</button>
										</div>
										<div class="main-bottom">
											<div class="quantity-selector">
												<button aria-label="decrease quantity" (click)="item.qty = item.qty > 1 ? item.qty - 1 : item.qty; calculateTotal();updateCart();">
													<svg width="10" viewBox="0 0 12 2" xmlns="http://www.w3.org/2000/svg">
														<path d="M11.25 0H.75C.3 0 0 .4 0 1s.3 1 .75 1h10.5c.45 0 .75-.4.75-1s-.3-1-.75-1z" fill="#7A7A7A" fill-rule="nonzero"></path>
													</svg>
												</button>
												<input type="text" [(ngModel)]="item.qty" [value]="item.qty">
												<button class="down" aria-label="increase quantity"  (click)="(item.stock>item.qty && item.qty = item.qty + 1); calculateTotal();updateCart();">
													<svg width="10" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
														<path d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 002 0V7h4a1 1 0 000-2z" fill="currentColor" fill-rule="nonzero"></path>
													</svg>
												</button>
											</div>
											<span class="price">₹{{item.offer_price && item.offer_price > 0 ? item.offer_price : item.price}}</span>
										</div>
											<span class="red-color">{{!(item.stock > item.qty)?"Out of Stock":""}}</span>
									</div>
								</div>
							</ng-container>
						</div>
						<ng-template #noItem>
							<div class="items-empty">
								<div class="main">
									<div class="main-top">
										<div class="main-top-left">
											<img src="../../../assets/img/emptyCart.png" width="100" height="100" />
											<h3 class="title">
												
												No Items In Cart
											</h3>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
			<div class="cart-footer">
				<div class="cart-footer-row">
					<span class="flex">Shipping</span>
					<span class="shipping-cost">
						<span>CALCULATED AT CHECKOUT</span>
					</span>
				</div>
				<div class="cart-footer-row" *ngIf="items && items.length > 0">
					<strong class="flex">Subtotal</strong>
					<strong class="slidecart-subtotal">
						{{cart.subtotal | currency:'INR':'symbol-narrow':'2.2-2'}}
					</strong>
				</div>
				<button name="checkout" class="btn button-full" (click)="moveToCart($event); showCart = false;">Checkout</button>
				<a class="footer-continue" routerLink="/product" (click)="showCart = false;">Or continue shopping</a>
			</div>
		</div>
	</div>
</header>

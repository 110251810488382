<div class="preloader" [ngStyle]="{'display': isLoading === true ? 'flex' : 'none'}">
    <div class="preloader-inner">
        <!-- <div class="loader-logo">
            <img src="assets/img/logo.svg" alt="Maas Choice">
        </div> -->
        <p>A mother's perfect choice...</p>
        <!-- <ul>
            <li>A</li>
             <li>M</li>
             <li>o</li>
            <li>t</li>
            <li>h</li>
            <li>e</li>
            <li>r</li>
            <li>'s</li>
            <li>p</li>
            <li>e</li>
            <li>r</li>
            <li>f</li>
            <li>e</li>
            <li>c</li>
            <li>t</li>
            <li>c</li>
            <li>o</li>
            <li>i</li>
            <li>c</li>
            <li>e</li>
            <li>.</li>
            <li>.</li>
            <li>.</li>
        </ul> -->
        <!-- <div class="loader-wrap pt-4">
            <span class="loader">
               
            </span>
        </div> -->
    </div>
</div>

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GET_CART, SET_CART, reducer, SET_AUTH} from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
import { Subscription } from 'rxjs';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import * as dates from 'src/app/helpers';
import * as $ from 'jquery';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	providers: [ToastService]
})
export class RegisterComponent implements OnInit {

	email:any = '';
	password:any = '';
	mobile:any = "";
	first_name:any = "";
	last_name:any = "";
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
		public cd: ChangeDetectorRef,
		private store: Store<reducers.AppState>,
	) { }

	ngOnInit(): void {
	}

	submitRegister(event:any){

		const register = {
			first_name: this.first_name ? this.first_name : null,
			last_name: this.last_name ? this.last_name : null,
			mobile: this.mobile ? this.mobile : null,
			email: this.email ? this.email : null,
			password: this.password ? this.password : null,
		}

		this.http.post(this.httpService.mainUrl + 'registerUser', register).subscribe((res:any) => {
			if(res.status == 1){
				const login = {
					username: register.email,
					password: register.password,
				}
				this.http.post(this.httpService.mainUrl + 'loginUser', login).subscribe((res:any) => {
					if(res.status == 1){
						this.notify.generateToast('success', "Login Success!", res.message);
						this.store.dispatch(SET_AUTH({auth: res.login}));
						this.router.navigate(['/profile/personal']);
					}else {
						this.notify.generateToast('error', "", res.message)
					}
				})
			}else {
				this.notify.generateToast('error', "", res.message)
			}
		})
	}

}

<app-breadcrumb></app-breadcrumb>
<section class="vs-contact-wrapper vs-contact-layout1">
	<div class="container">
		<div class="row text-center text-lg-start justify-content-center">
			<div class="col-lg-12 col-sm-11 col-11 mt-30 mt -lg-0">
				<section class="review_page" id="review_page">
					<div class="container-fluid">
						<div class="review_container">
							<div class="row">
								<div class="col-sm-11 col-11">
									<div  class="review_header">
										<div class="NWW_bH"><span>Ratings &amp; Reviews</span></div>
										<div class="_2tw077">
											<a
												href="javascript:void(0)"
												[routerLink]="'/product/'+item.category_id+'/product_id/'+item.product_id"
											>
												<div class="_3wBtPi">
													<div class="Sw6kZ2"><span>{{item.name}} ({{item.attribute_name}})</span></div>
													<div class="mBjPf6">
														<div class="niH0FQ _36Fcw_" *ngIf="average_count > 0">
															<span id="productRating_undefined_undefined_" class="_2_KrJI">
																<div class="hGSR34">{{average_count}}
																	<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTYuNSA5LjQzOWwtMy42NzQgMi4yMy45NC00LjI2LTMuMjEtMi44ODMgNC4yNTQtLjQwNEw2LjUuMTEybDEuNjkgNC4wMSA0LjI1NC40MDQtMy4yMSAyLjg4Mi45NCA0LjI2eiIvPjwvc3ZnPg==" class="_2lQ_WZ">
																</div>
															</span>
															<span class="_38sUEc">({{count}})</span>
														</div>
													</div>
												</div>
												<div class="_2sxWXr ">
													<img [attr.src]="httpService.imgURL + item.image" class="hoZMHD">
												</div>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-11 col-11 col-md-4 ">
									<div class="left_content">
										<div class="_3SyFhG"><span>What makes a good review</span></div>
										<div class="_1gZBWY">
											<div class="_13cTJo">
												<div class="_1RrlUn"><span>Have you used this product?</span></div>
												<div class="_20QwAZ"><span>Your review should be about your experience with the product.</span></div>
											</div>
											<div class="_13cTJo">
												<div class="_1RrlUn"><span>Why review a product?</span></div>
												<div class="_20QwAZ"><span>Your valuable feedback will help fellow shoppers decide!</span></div>
											</div>
											<div class="_13cTJo">
												<div class="_1RrlUn"><span>How to review a product?</span></div>
												<div class="_20QwAZ">
													<span>Your review should include facts. An honest opinion is always appreciated. If you have an issue with the product or service please contact us from the given contact details.</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-11 col-11 col-md-8 ">
									<div class="right_content">
										<div class="_3AwHsn">
											<div class="_1O_Sj9"><span>Rate this product</span></div>
											<div class="Kj7FZF">
												<div class="_3lM63h _1tudS1 animate__animated animate__bounce animate__delay-2s animate__repeat-2" *ngIf="is_saved == true">Your rating has been saved</div>
												<div>
													<div class="kcuK2o">
														<span
															id="1"
															(click)="setRating($event.target, 1)"
															[class]="review.ratings >= 1 ? '_2tZufY wvjgRW' : '_2tZufY _3Hc5hn'"
															[ngClass]="review.ratings >= 1 ? getCurrentStar() : ''"
														>★</span>
														<span
															id="2"
															(click)="setRating($event.target, 2)"
															[class]="review.ratings >= 2 ? '_2tZufY wvjgRW' : '_2tZufY _3Hc5hn'"
															[ngClass]="review.ratings >= 2 ? getCurrentStar() : ''"
														>★</span>
														<span
															id="3"
															(click)="setRating($event.target, 3)"
															[class]="review.ratings >= 3 ? '_2tZufY wvjgRW' : '_2tZufY _3Hc5hn'"
															[ngClass]="review.ratings >= 3 ? getCurrentStar() : ''"
														>★</span>
														<span
															id="4"
															(click)="setRating($event.target, 4)"
															[class]="review.ratings >= 4 ? '_2tZufY wvjgRW' : '_2tZufY _3Hc5hn'"
															[ngClass]="review.ratings >= 4 ? getCurrentStar() : ''"
														>★</span>
														<span
															id="5"
															(click)="setRating($event.target, 5)"
															[class]="review.ratings >= 5 ? '_2tZufY wvjgRW' : '_2tZufY _3Hc5hn'"
															[ngClass]="review.ratings >= 5 ? getCurrentStar() : ''"
														>★</span>
													</div>
													<span class="_1Dk1bd" [ngClass]="getStar()">{{getRatings()}}</span>
												</div>
											</div>
										</div>
										<hr class="_2qU1N7">
										<div class="_3zS_0H">
											<div class="_1O_Sj9"><span>Review this product</span></div>
											<div class="_2ynvot">
												<div class="_32o8JY">
													<div class="_1AyhZ5"><span>Description</span></div>
													<textarea rows="8" placeholder="Description..." class="_16nh-W" [attr.value]="review.details" (change)="onDescriptionChange($event)"></textarea>
												</div>
												<hr class="_2qU1N7">
												<div class="_32o8JY">
													<div class="_1AyhZ5"><span>Title (optional)</span></div>
													<input [attr.value]="review.title" (change)="onTitleChange($event)" placeholder="Review title..." class="_16nh-W">
												</div>
											</div>
										</div>
										<div class="_3xysL5">
											<ng-container *ngFor="let data of images; let index = index;">
												<div class="_131lrD">
													<span class="_2qgwUj" (click)="removeImage(data, index)">×</span>
													<img class="gCwxfy" [attr.src]="httpService.imgURL + data.image">
												</div>
											</ng-container>
											<div class="_2rByF9">
												<input type="file" #reviewImage class="_1MRgKp" id="fk-ggougg0h8mb" accept="image/*" multiple="true" (change)="onUpload($event)">
												<label class="_30GZnn" for="fk-ggougg0h8mb"></label>
											</div>
										</div>
										<div class="d-flex text-center mx-3">
											<button class="btn-theme _2AkmmA _3oe6G6 _7UHT_c justify-content-center " (click)="submitReview()">{{review._id ? 'Update Review' : 'Submit Review'}}</button>
											<button class="btn-theme _2AkmmA _3oe6G6 _7UHT_c justify-content-center "  (click)="goBack()">Go Back</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

			</div>
		</div>
	</div>
</section>

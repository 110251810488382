import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EmiterService } from 'src/app/services';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
	isLoading:boolean = true;
	sub!:Subscription;
	constructor(
		private emiter: EmiterService
	) {

	}

	ngOnInit(): void {
		if(this.isLoading === true){
			this.setLoadingFalse();
		}
		this.sub = this.emiter.on('isloading').subscribe((res:any)=> {
			if(res.type === "isloading"){
				this.isLoading = res.data;
				if(res.data.autoClose === undefined){
					this.setLoadingFalse();
				}
			}
		})
	}

	setLoadingFalse(){
		setTimeout(() => {
			this.isLoading = false;
		}, 2500);
	}

	setLoadingTrue(){
		this.isLoading = true;
	}

}

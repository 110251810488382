import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { HttpService, ToastService } from 'src/app/services';
import { Company } from 'src/app/store';
import * as reducers from 'src/app/store/reducers';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
	sub!:Subscription;
	company!:typeof Company;
	name:any = "";
	email:any = "";
	contact:any = "";
	subject:any = "";
	message:any = "";
	constructor(
		private http: HttpClient,
		public httpService: HttpService,
		private store: Store<reducers.AppState>,
		private notfiy: ToastService,
		private sanitizer: DomSanitizer
	) {
		this.sub = this.store.subscribe((data:any) => {
			this.company = data.main && data.main.company != undefined && data.main.company._id != undefined ? data.main.company : data.main;
		})
	}

	ngOnInit(): void {
		this.sub = this.store.subscribe((data:any) => {
			this.company = data.main && data.main.company != undefined && data.main.company._id != undefined ? data.main.company : data.main;
		})
	}


	submitQuery(event:any){
		let data = {
			name: this.name ? this.name : null,
			email: this.email ? this.email : null,
			contact: this.contact ? this.contact : null,
			subject: this.subject ? this.subject : null,
			message: this.message ? this.message : null,
		}
		if(data.name && data.email && data.contact && data.subject && data.message){
			this.http.post(this.httpService.mainUrl + 'submitQuery', {data: data}).subscribe((res:any)=> {
				if(res.status == 1)	{
					this.notfiy.generateToast('success', "Success!", res.message);
					this.name = '';
					this.email = '';
					this.contact = '';
					this.subject = '';
					this.message = '';
				}else {
					this.notfiy.generateToast('error', res.message, "");
				}
			})
		}else {
			this.notfiy.generateToast('warning', "Please fill the form with all the given details!", "");
		}
		event.preventDefault();
	}
}

import { HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SET_CART, Auth } from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
import * as $ from 'jquery';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import { Router } from '@angular/router';
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
	math = Math;
	public mainSliderConfig: any = {
		autoplay: true,
		loop: true,
		margin: 10,
		dots: true,
		nav: false,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		autoHeight: true,
		autoWidth: true,
		mouseDrag: false,
		autoplayTimeout: 6000,
		smartSpeed: 4000,
		responsiveClass: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 1,
			},
			1000: {
				items: 1,
			},
		},
	};
	public videoEl: any;
	public mainSlider: any = [];
	public products: any = [];
	public banners: any = [];
	public bulk_products: any = [];
	public featured_products: any = [];
	public category: any = [];
	public ratings = Array(5)
		.fill(0)
		.map((x, i) => i + 1);
	public activeCategory: any = '*';
	public activeAttribute: any = {};
	public activeBulkAttribute: any = {};
	public isLoading: boolean = true;
	public cart: any = [];
	public user: any;
	public session: any;
	constructor(
		public httpService: HttpService,
		private http: HttpClient,
		private cd: ChangeDetectorRef,
		private store: Store<reducers.AppState>,
		private notify: ToastService,
		public emitter: EmiterService,
		public router: Router
	) {
		this.session = this.httpService.getSession();
		this.store.subscribe((data: any) => {
			this.cart =
				data.main &&
				data.main.cart != undefined &&
				Array.isArray(data.main.cart)
					? data.main.cart
					: [];
			this.user =
				data.main && data.main.auth != undefined && data.main.auth._id
					? data.main.auth
					: Auth;
			this.user = this.httpService.auth;
			this.session =
				data.main && data.main.session != undefined && data.main.session
					? data.main.session
					: '';
			this.session = this.httpService.getSession();
		});
	}

	ngOnInit(): void {
		this.getHomeSlider();
		this.getHomeBanners();
		this.getHomeProducts();
	}

	ngAfterViewInit() {
		this.videoEl = document.querySelector('video');
		if (this.videoEl) {
			this.videoEl.muted = true;
		}
	}

	getHomeSlider() {
		this.emitter.emit('isloading', {
			type: 'isloading',
			data: true,
			autoClose: false,
		});
		this.http
			.post(this.httpService.mainUrl + 'getHomeSlider', {})
			.subscribe((res: any) => {
				this.emitter.emit('isloading', {
					type: 'isloading',
					data: false,
					autoClose: false,
				});
				if (res.status == 1) {
					this.mainSlider = res.data;
				}
			});
	}

	getHomeBanners() {
		this.emitter.emit('isloading', {
			type: 'isloading',
			data: true,
			autoClose: false,
		});
		this.http
			.post(this.httpService.mainUrl + 'getHomeBanners', {})
			.subscribe((res: any) => {
				this.emitter.emit('isloading', {
					type: 'isloading',
					data: false,
					autoClose: false,
				});
				if (res.status == 1) {
					this.banners = res.data;
				}
			});
	}

	getHomeProducts() {
		this.emitter.emit('isloading', {
			type: 'isloading',
			data: true,
			autoClose: false,
		});
		this.http
			.post(this.httpService.mainUrl + 'getHomeProducts', {})
			.subscribe((res: any) => {
				this.emitter.emit('isloading', {
					type: 'isloading',
					data: false,
					autoClose: false,
				});
				if (res.status == 1) {
					const data: any = res.data;
					// this.featured_products = data.all.slice(0, 6);
					data.all.map((item: any) => {
						let attributes = item.attributes || [];
						let i = attributes.findIndex(
							(attr: any) =>
								attr.package_type === 'gm' ||
								(attr.package_value < 5 &&
									attr.package_type === 'kg')
						);
						if (i > -1 && this.products.length !== 6) {
							this.products.push(item);
						}
						let j = attributes.findIndex(
							(attr: any) =>
								attr.package_value >= 5 &&
								attr.package_type === 'kg'
						);
						if (
							j > -1 &&
							this.bulk_products.length !== 3 &&
							this.products.findIndex(
								(bulk: any) => bulk._id == item._id
							) === -1
						) {
							this.bulk_products.push(item);
						}
					});
					this.activeAttribute = this.products[0].attributes[0];
					this.activeBulkAttribute =
						this.bulk_products[0].attributes[0];
					data.category.map((item: any) => {
						item.name = item.name.trimStart().trimEnd();
						let slug = item.name
							.toLowerCase()
							.trimStart()
							.trimEnd();
						slug = slug.replace('&', '');
						slug = slug.toLocaleLowerCase();
						item.slug =
							slug.indexOf(' ') > -1
								? slug.split(' ').join('-')
								: slug;
						item.slug = item.slug;
						this.category.push(item.valueOf());
					});
					this.isLoading = false;
				}
			});
	}

	addItemToCart(item: any, type: number) {
		this.addToCart(item, 0, type);
	}

	addToCart(product: any, action: any, type: number) {
		let currentAttribute =
			type === 1 ? this.activeAttribute : this.activeBulkAttribute;
		const attributes = product.attributes;
		let index = attributes.findIndex(
			(item: any) => item._id === currentAttribute._id
		);
		if (index === -1) {
			currentAttribute = attributes[0];
		}
		if (parseInt(currentAttribute.stock) !== 0) {
			this.emitter.emit('isloading', {
				type: 'isloading',
				data: true,
				autoClose: false,
			});

			const cart: any = JSON.parse(JSON.stringify(this.cart.valueOf()));
			const item: any = JSON.parse(
				JSON.stringify({ ...product.valueOf() })
			);
			delete item.similar;
			delete item.reviews;
			delete item.review_images;
			delete item.images;
			delete item.attributes;
			delete item.smallest;
			delete item.largest;
			item.qty = 1;
			item.total = 0;
			item.product_id = item._id;
			item.offer = currentAttribute.offer;
			item.price = parseInt(currentAttribute.package_price);
			item.selected = currentAttribute._id;
			item.selected_attribute = currentAttribute;
			item.return_status = false;
			item.return_reason = '';
			item.current_stock = parseInt(currentAttribute.stock);
			item.last_stock = parseInt(currentAttribute.stock);
			item.stock = parseInt(currentAttribute.stock);
			if (item.offer) {
				item.offer.amount = item.offer._id
					? parseFloat(item.offer.amount)
					: 0;
			}
			if (parseInt(item.offer_price)) {
				item.total = 1 * parseInt(item.offer_price);
			} else {
				item.total = 1 * parseInt(item.price);
			}
			let ind = cart.findIndex(
				(cartItem: any) =>
					cartItem._id == item._id &&
					cartItem.selected === item.selected
			);
			if (ind > -1) {
				cart[ind] = item;
			} else {
				cart.push(item);
			}
			this.cart = [...JSON.parse(JSON.stringify(cart.valueOf()))];

			if (this.user && this.user._id) {
				this.store.dispatch(SET_CART({ cart: this.cart }));
				this.addToUserList(1, this.cart);
				this.emitter.emit('update_cart', { updateCart: true });
				this.emitter.emit('cart_change', true, false);
			} else {
				if (this.session) {
					this.store.dispatch(SET_CART({ cart: this.cart }));
					this.emitter.emit('update_cart', { updateCart: true });
					this.emitter.emit('cart_change', true, false, false);
					// this.notify.generateToast('success', "Item Added!", "Your Item Added To Cart Successfully!");
				} else {
					// this.notify.generateToast('warning', 'Warning!', "Please Login First, To Add Items In Your Cart");
				}
			}
			this.emitter.emit('isloading', {
				type: 'isloading',
				data: false,
				autoClose: false,
			});
			if (action && action == 1) {
				this.router.navigate(['/cart']);
			}
		} else {
			this.notify.generateToast(
				'warning',
				'Opps!',

				'This Product is Out of Stock.'
			);
		}
	}

	addToUserList(type: number, items: any) {
		let user = this.httpService.auth;
		let params = {
			user_id: user && user._id ? user._id : '',
			items: items,
			type: type,
		};
		this.http
			.post(this.httpService.mainUrl + 'addToUserList', params)
			.subscribe((result: any) => {
				if (result.status == 1) {
					let action = 'Removed From';
					if (items.length > 0) {
						action = 'Added To';
					}
					let msg =
						type == 1
							? `Item ${action} Cart Successfully!`
							: `Item ${action} Wishlist Successfully!`;
					// this.notify.generateToast('success', 'Success!', msg);
				} else {
					// this.notify.generateToast('error', 'Error!', result.message);
				}
			});
	}

	getPackageName(attribute: any) {
		let packageType = attribute?.package_type?.toUpperCase();
		if (packageType === 'GM') {
			packageType = 'g';
		}
		if (packageType === 'KG') {
			packageType = 'Kg';
		}
		if (packageType === 'LTR') {
			packageType = 'L';
		}
		let name = `${attribute.package_value}${packageType}`;
		return name;
	}

	getPrice(attribute: any) {
		let price: any = '0';
		if (attribute.offer && parseInt(attribute.offer.amount)) {
			if (attribute.offer.type == false) {
				price = attribute.package_price - attribute.offer.amount;
			} else {
				price =
					parseInt(attribute.package_price) -
					(parseInt(attribute.offer.amount) *
						parseInt(attribute.package_price)) /
						100;
			}
		}
		return price;
	}

	getChecked(attribute: any, type: number) {
		let isChecked: boolean = false;
		if (type === 1) {
			isChecked = this.activeAttribute?._id === attribute._id || false;
		}
		if (type === 2) {
			isChecked =
				this.activeBulkAttribute?._id === attribute._id || false;
		}
		return isChecked;
	}

	validateAttribute(attribute: any) {
		return true;
	}

	changeAttribute(itemIndex: any, attribute: any, type: number) {
		let price: any = '0';
		if (attribute.offer && parseInt(attribute.offer.amount)) {
			if (attribute.offer.type == false) {
				price = attribute.package_price - attribute.offer.amount;
			} else {
				price =
					parseInt(attribute.package_price) -
					(parseInt(attribute.offer.amount) *
						parseInt(attribute.package_price)) /
						100;
			}
		}
		if (type === 1) {
			this.activeAttribute = attribute;
			this.products[itemIndex].price = attribute.package_price;
			this.products[itemIndex].offer_price = price;
			this.products[itemIndex].offer_amount =
				attribute.offer && attribute.offer.amount
					? attribute.offer.amount
					: 0;
			this.products[itemIndex].offer_type =
				attribute.offer && attribute.offer.type
					? attribute.offer.type
					: null;
			this.products[itemIndex].offer = attribute.offer
				? attribute.offer
				: null;
		}
		if (type === 2) {
			this.activeBulkAttribute = attribute;
			this.bulk_products[itemIndex].price = attribute.package_price;
			this.bulk_products[itemIndex].offer_price = price;
			this.bulk_products[itemIndex].offer_amount =
				attribute.offer && attribute.offer.amount
					? attribute.offer.amount
					: 0;
			this.bulk_products[itemIndex].offer_type =
				attribute.offer && attribute.offer.type
					? attribute.offer.type
					: null;
			this.bulk_products[itemIndex].offer = attribute.offer
				? attribute.offer
				: null;
		}
		this.cd.detectChanges();
	}

	randomIntFromInterval(min: number, max: number) {
		// min and max included
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	getColor(index: number) {
		const colors: any = {
			1: '#5da205',
			2: '#f78888',
			3: '#ececec',
			4: '#90ccf4',
			5: '#ececec',
			6: '#f3d250',
		};
		var color1 = '#5da205';
		var color2 = '#f78888';
		var color3 = '#ececec';
		var color4 = '#90ccf4';
		var color5 = '#ececec';
		var colorb1 = '#f78888';
		var colorb2 = '#f3d250';
		var colorb3 = '#ececec';
		let colorSelected = colors[index + 1];
		let style = { backgroundColor: `transparentize(${colorSelected}, .3)` };
		return style;
		// return `color${this.randomIntFromInterval(1, index)}`;
	}

	getBGColor(index: number) {
		const colors: any = {
			1: '#5da205',
			2: '#f78888',
			3: '#ececec',
			4: '#90ccf4',
			5: '#ececec',
			6: '#f3d250',
		};
		var color1 = '#5da205';
		var color2 = '#f78888';
		var color3 = '#ececec';
		var color4 = '#90ccf4';
		var color5 = '#ececec';
		var colorb1 = '#f78888';
		var colorb2 = '#f3d250';
		var colorb3 = '#ececec';
		let colorSelected = colors[index + 1];
		let style = { backgroundColor: `transparentize(${colorSelected}, .3)` };
		return style;
	}

	errorMessageVideo(event: any) {
		console.log('errorMessageVideo ', event);
	}
}

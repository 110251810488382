import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import * as dates from 'src/app/helpers/date';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
	order_id = '';
	attribute_id = '';
	product_id = '';
	selectedItem:any = {};
	order:any = {
		_id: '',
		order_number: '',
		user_id: '',
		address_id: '',
		address: {
			_id: '',
			user_id: '',
			person_name: '',
			mobile: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			pincode: '',
			type: '',
			status: '',
		},
		file: '',
		payment_method: '',
		sub_total: '',
		shipping: '',
		discount: '',
		tax: '',
		final_total: '',
		order_date: '',
		delivery_date: '',
		items!: <any>[],
		shipping_details: '',
		status: '',
		return_status: 0,
		days: 15,
		return_id: "",
		return_reason: "",
		is_returnable: "",
		return_submit: 0,
	};
	year:any = 2020;
	month:any = '0';
	user:any;
	viewOrder = false;
	return_reason:any = "";
	return_reason_other:any = "";
	isOpenModal = false;
	isReturnPress = false;
	returnItems:any = [];
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public $http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
	) { }

	ngOnInit(): void {
		this.year = dates._getDayMonthYear('','y','')
		this.month = dates._getDayMonthYear('','m','')
		this.month = this.month < '10' ? ('0'+this.month) : this.month;
		this.user = this.httpService.getAuth();
		const params:any = this.route.snapshot.params;
		if(params){
			if(params._id && params.attribute){
				this.order_id = params._id;
				this.attribute_id = params.attribute;
				if(this.user && this.user._id){
					this.getOrder(params._id, params.attribute);
				}
			}
		}
	}

	getOrder(order_id:string, attribute_id:string){
		if(order_id && attribute_id){
			var apiUrl = this.httpService.mainUrl + 'getOrder';
			this.$http.post(apiUrl, {order_id: order_id}).subscribe((response:any) => {
				if(response.status == 1){
					let data = response.data && response.data._id ? response.data : null;
					if(data){
						const items = data.items;
						this.order = data;
						this.order['return_id'] = "";
						this.order['return_status'] = 0;
						this.order['return_submit'] = 0;
						this.order['return_reason'] = "";
						this.order.items = items;
						let ind1 = items.findIndex((x:any) => x.selected == attribute_id);
						if(ind1 > -1){
							this.selectedItem = items[ind1];
							this.product_id = items[ind1]._id;
							this.order['return_id'] = items[ind1].return_id;
							this.order['return_status'] = items[ind1].return_status ? items[ind1].return_status : 0;
							this.order['return_submit'] = items[ind1].return_submit ? items[ind1].return_submit : "";
							this.order['return_reason'] = items[ind1].return_reason ? items[ind1].return_reason : "";
						}
					}
				}
			});
		}
	}

	getColor(status:string){
		let color = 'text-info';
		if(status === 'Confirmed') {
			color = 'text-info';
		}
		if(status === 'Cancelled') {
			color = 'text-danger';
		}
		if(status === 'Rejected') {
			color = 'text-danger';
		}
		if(status === 'Returned') {
			color = 'text-danger';
		}
		if(status === 'Shipped') {
			color = 'text-dark';
		}
		if(status === 'Dispatched') {
			color = 'text-warning';
		}
		if(status === 'Delivered') {
			color = 'text-success';
		}
		return color;
	}

	getOrderDate(date:string, type: number){
		return dates._generateDateFormat(date, type === 1? 'DD MMMM YYYY' : 'DD MM YYYY');
	}

	isValidReturn(order:any, product:any){
		let flag = false;
		if(order.status === 'Delivered'){
			let dt:any = new Date(order.updatedAt);
			let return_date = dates._generateTimes(dt, 'days', product.return_days);
			if(dates._getDifference(dt, return_date) > 1){
				flag = true;
			}
		}
		return flag;
	}

	updateOrderStatus(status:any){
		let order = {
			_id: this.order._id ? this.order._id  : null,
			status: status,
		};
		this.$http.post(this.httpService.mainUrl + 'updateOrderStatus', order).subscribe((response:any) => {
			if(response.status == 1){
				this.viewOrder = false;
				this.getOrder(this.order_id, this.attribute_id);
				this.notify.generateToast('success', 'Success!', response.message)
			}else {
				this.notify.generateToast('error', 'Error!', response.message)
			}
		});
	}

	checkRefundable(order:any){
		let dayFlag = false;
		let orderFlag = false;
		let returnDays = 0;
		let orderDate = dates._generateDateFormat(order.createdAt, 'yyyy-mm-dd');
		let item = order.items.filter((item:any) => item.selected === this.attribute_id)[0];
		if(order.status !== 'Placed' && order.status !== 'Cancelled' && order.status !== 'Rejected'){
			orderFlag = true;
		}
		if(item && item._id){
			returnDays = parseInt(item.return_days)
		}
		let days:any = 0
		if(returnDays > 0){
			days = dates._getDifference(orderDate, dates._generateDate(orderDate, 'day', returnDays, 'yyyy-mm-dd')) ;
		}
		if(days > 0){
			dayFlag = true;
		}
		return orderFlag === true && dayFlag === true && order.return_status == 0 ? true : false ;
	}

	setReturnItem(item:any){
		this.returnItems.push(item._id);
		this.isReturnPress = true;
		this.order.return_submit = 1;
	}

	isChecked(_id:string){
		return (this.returnItems.includes(_id) && this.isReturnPress === true ) || false;
	}

	getReturnStatusClass(data:any, type:any){
		let str = '';
		let cls = '';
		if(data.return_status == 0){
			str = 'Not Applied';
			cls = 'applied';
		}
		if(data.return_status == 1){
			str = 'Pending For Confirmation';
			cls = 'applied';
		}
		if(data.return_status == 2){
			str = 'Confirmed';
			cls = 'pending';
		}
		if(data.return_status == 3){
			str = 'Rejeted';
			cls = 'reject';
		}
		if(data.return_status == 4){
			str = 'Completed';
			cls = 'complete';
		}
		return type == 1 ? cls : str;
	}

	returnOrder(data:any){
		if(this.return_reason && this.return_reason != '' && this.return_reason.length > 0){
			let reason = this.return_reason === 'Other' ? `${this.return_reason} - ${this.return_reason_other}`: this.return_reason;
			let returns = {
				order_number: this.order.order_number,
				order_id: this.order_id,
				product_id: this.product_id,
				attribute_id: this.attribute_id,
				item: this.selectedItem,
				reason: reason,
				status: 1,
			};
			this.$http.post(this.httpService.mainUrl + 'returnOrderItem', returns).subscribe((response:any) => {
				if(response.status == 1){
					let result = response.data;
					let ind = this.order.items.findIndex((x:any) => x.attribute_id == this.attribute_id);
					if(ind > -1){
						this.order.items[ind].return_id = result.return_id;
						this.order.items[ind].return_status = result.return_status;
						this.order.items[ind].return_submit = 0;
						this.order.items[ind].return_reason = result.reason;
					}
					this.isOpenModal = false;
					this.isReturnPress = false;
					this.getOrder(this.order_id, this.attribute_id);
					this.notify.generateToast('success', 'Success!', response.message)
				}else {
					this.notify.generateToast('error', 'Error!', response.message)
				}
			});
		}else{
			this.notify.generateToast('warning', 'Validation Error!', 'please provide the valid reason for returning item')
		}

	}

}

import { AfterViewInit, Injectable, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import * as $ from 'jquery';

@Injectable({
	providedIn: 'root'
})
export class ModifyMetaGuard {
	constructor(private router: Router) {
	}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		this.router.events.subscribe((event: Event) => {
			const url = state.url;
			const activeRoute = url.split('/')[1];
			// console.log('state.url ', state.url);
			// console.log('activeRoute', activeRoute);
		});
		return true;
	}
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmiterService {
	private subject = new Subject<any>();

	emit(type: string, data: any, remove?: any, isCheckout?: any) {
		this.subject.next({
			type: type,
			data: data,
			remove: remove,
			isCheckout: isCheckout,
		});
	}

	on(type: string): Observable<any> {
		return this.subject.asObservable();
	}
}

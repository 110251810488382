import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as reducers from '../store/reducers';
import * as actions from '../store/actions';
import {Auth, imageUrl, mainUrl, chatUrl, Rights, Order, Cart} from '../store/models';
import { EmiterService } from '../services';
import * as $ from 'jquery';
@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private store: Store,
		private router: Router,
		private emitter: EmiterService,
	) {
	}

	canActivate() {
		if (localStorage.getItem('maaschoice_auth')) {
			const data = JSON.parse(localStorage.getItem('maaschoice_auth') || '{}');
			if(data && data._id) {
				this.emitter.emit('auth_updated', {...data, });
				this.store.dispatch(actions.SET_CART({cart: []}));
				this.store.dispatch(actions.SET_ORDER({order: []}));
				this.store.dispatch(actions.GET_CART());
				this.store.dispatch(actions.GET_ORDER());
				return true;
			}
		}
		this.router.navigate(['/login']);
		return false;
	}
}

<app-breadcrumb></app-breadcrumb>
<section class="product_reviews_page" id="product_review_page">
    <div class="container">
        <div class="review_container">
            <div class="row" id="product_review_page">
                <div class="col-xl-12">
                    <div class="_2m08jR ">
                        <div class="_12iFZG _3PG6Wd">
                            <div class="ooJZfD _3FGKd2 col-12-12">
                                <div class="ooJZfD _2oZ8XT col-md-12 col-lg-3">
                                    <div class="_3gijNv col-12-12">
                                        <div class="_3liAhj _2Vsm67 _39VyC0">
                                            <a href="javascript:void(0)" class="Zhf2z-" [attr.title]="review.name+ review.attribute_name" target="_blank" rel="noopener noreferrer">
                                                <div class="_8Vcv_L">
                                                    <div>
                                                        <div class="_3BTv9X" style="height: 246px; max-width: 246px;">
                                                            <img
                                                                class="_1Nyybr  _30XEf0" [attr.alt]="review.name + review.attribute_name"
                                                                [attr.src]=" httpService.imgURL + review.image"
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div>
                                                <div>
                                                    <a
                                                        href="javascript:void(0)"
                                                        [attr.title]="review.name + review.attribute_name"
                                                        class="_2cLu-l _3g-u9b"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        [routerLink]="'/electronics-store/product-details/'+review.category_id+'/product_id/'+review.product_id"
                                                    >{{review.name}} {{review.attribute_name}}</a>
                                                </div>
                                                <div class="niH0FQ">
                                                    <span class="_2_KrJI">
                                                        <div class="hGSR34">
                                                            {{review.average_ratings}}
                                                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTYuNSA5LjQzOWwtMy42NzQgMi4yMy45NC00LjI2LTMuMjEtMi44ODMgNC4yNTQtLjQwNEw2LjUuMTEybDEuNjkgNC4wMSA0LjI1NC40MDQtMy4yMSAyLjg4Mi45NCA0LjI2eiIvPjwvc3ZnPg==" class="_2lQ_WZ">
                                                        </div>
                                                    </span>
                                                    <span class="_38sUEc">
                                                        <span>
                                                            <span>{{review.total_ratings}} Reviews &amp; Ratings&nbsp;</span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="_1Vfi6u Sefc6z">
                                                    <div class="_1uv9Cb">
                                                        <div class="_1vC4OE">₹{{review.offer_price ? review.offer_price : review.price}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="_3gijNv col-12-12" style="border-top: 1px solid rgb(240, 240, 240);">
                                        <div class="_2c7I6L">
                                            <div class="_imHwI"><span>User Images</span></div>
                                            <div class="KjrNZd PNBG8V">
                                                <ng-container *ngIf="images.length > 0">
                                                    <ng-container *ngFor="let item of images; let index=index;">
                                                        <div
                                                            data-lightbox="roadtrip"
                                                            data-title="My caption"
                                                            *ngIf="index < 8"
                                                            class="_3Z21tn MxBEKC"
                                                            [ngClass]="index == images.length - 1 ? '_1pnbvM' : ''"
                                                            (click)="open(index)"
                                                            [ngStyle]="{
                                                                height:'70px',
                                                                width:'70px'
                                                            }">
                                                            <div
                                                                style=" display: block;
                                                                        position: absolute;height: 100%;
                                                                        width: 100%;
                                                                        overflow: hidden;
                                                                        top: 0;
                                                                        left: 0;
                                                                        right: 0;
                                                                        bottom: 0;
                                                                        background-color: #000;
                                                                        opacity: 0.5;"
                                                                *ngIf="index == 7">
                                                            </div>
                                                            <img *ngIf="index < 8" [attr.src]="item.thumb" (click)="open(index)" style="height:70px;width:70px;" />
                                                            <span *ngIf="index == 7" class="_2d-K43">+{{images.length}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <!-- <vue-easy-lightbox
                                                        [attr.id]="'items_main'"
                                                        [attr.visible]="visible === true"
                                                        [imgs]="images"
                                                        [attr.index]="index"
                                                        (hide)="handleHide()"
                                                    ></vue-easy-lightbox> -->
                                                    <!-- <div *ngFor="let image of images; let i=index">
                                                        <img [src]="image.thumb" (click)="open(i)" />
                                                    </div> -->
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ooJZfD _2oZ8XT col-md-12 col-lg-9">
                                    <div class="ooJZfD _2oZ8XT col-12-12" style="border-bottom: 1px solid rgb(240, 240, 240);">
                                        <div class="ooJZfD _3FGKd2 col-12-12">
                                            <div class="_3gijNv col-md-12 col-lg-10">
                                                <div class="o9Xx3p _1_odLJ">{{review.name}} {{review.attribute_name}} Reviews</div>
                                            </div>
                                            <div class="_3gijNv col-md-12 col-lg-2" style="flex-grow: 1;">
                                                <div class="YW_iw_">
                                                    <div class="_2Qqkfm">
                                                        <div class="_15DXNf">
                                                            <div class="_2uWdcR _6wb6nn">
                                                                <select class="_3092M2 _6wb6nn" name="sortFilter" (change)="sortChange($event);">
                                                                    <option value="MOST_RECENT">Most Recent</option>
                                                                    <option value="POSITIVE_FIRST">Positive First</option>
                                                                    <option value="NEGATIVE_FIRST">Negative First</option>
                                                                </select>
                                                                <span class="_1LBnEa _1hhE4x"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="_3gijNv col-12-12">
                                        <div class="row _2sOTDU">
                                            <div class="col-md-12 col-lg-4 _11iLjN">
                                                <div class="col">
                                                    <div class="row">
                                                        <div class="col-12-12 _11EBw0">
                                                            <div class="_1i0wk8">{{review.average_ratings}}</div>
                                                            <div class="_2txNna">★</div>
                                                        </div>
                                                    </div>
                                                    <div class="row _2yc1Qo">
                                                        <div class="col-12-12"><span>{{review.total_ratings}} Reviews &amp; Ratings </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-8">
                                                <div class="_1n1j36 DrZOea ">
                                                    <ul class="_2M5FGu">
                                                        <li class="_58ZIbs">
                                                            <div class="_1atKHO"><span class="_3ApwOG">5</span><span class="_2bhdZh">★</span></div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div class="_1atKHO"><span class="_3ApwOG">4</span><span class="_2bhdZh">★</span></div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div class="_1atKHO"><span class="_3ApwOG">3</span><span class="_2bhdZh">★</span></div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div class="_1atKHO"><span class="_3ApwOG">2</span><span class="_2bhdZh">★</span></div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div class="_1atKHO"><span class="_3ApwOG">1</span><span class="_2bhdZh">★</span></div>
                                                        </li>
                                                    </ul>
                                                    <ul class="_2gN7eD">
                                                        <li class="_58ZIbs">
                                                            <div>
                                                                <div class="_1WmLa3">
                                                                    <span
                                                                        class="_1Yym6V euq4L_"
                                                                        [style.width]="(100/review.total_ratings * review.five_star_rating_count)+'%'"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div>
                                                                <div class="_1WmLa3">
                                                                    <span
                                                                        class="_1Yym6V _2YFTec"
                                                                        [style.width]="(100/review.total_ratings * review.four_star_rating_count)+'%'"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div>
                                                                <div class="_1WmLa3">
                                                                    <span
                                                                        class="_1Yym6V _33mvU5"
                                                                        [style.width]="(100/review.total_ratings * review.three_star_rating_count)+'%'"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div>
                                                                <div class="_1WmLa3">
                                                                    <span
                                                                        class="_1Yym6V _2MYUs6"
                                                                        [style.width]="(100/review.total_ratings * review.two_star_rating_count)+'%'"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div>
                                                                <div class="_1WmLa3">
                                                                    <span
                                                                        class="_1Yym6V _1sq5Tn"
                                                                        [style.width]="(100/review.total_ratings * review.one_star_rating_count) + '%'"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul class="_148m3I">
                                                        <li class="_58ZIbs">
                                                            <div class="CamDho">{{review.five_star_rating_count}}</div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div class="CamDho">{{review.four_star_rating_count}}</div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div class="CamDho">{{review.three_star_rating_count}}</div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div class="CamDho">{{review.two_star_rating_count}}</div>
                                                        </li>
                                                        <li class="_58ZIbs">
                                                            <div class="CamDho">{{review.one_star_rating_count}}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="reviews.length > 0">
                                        <ng-container *ngFor="let item of reviews;let index = index">
                                            <div class="_3gijNv col-12-12">
                                                <div class="_27M-vq _1PBCrt">
                                                    <div class="col-md-12">
                                                        <div class="_390CkK _2wzgFH _1gY8H-">
                                                            <div class="col-md-12">
                                                                <div
                                                                    class="hGSR34 E_uFuv"
                                                                    [ngClass]="getRatingClass(item)"
                                                                >
                                                                    {{item.rating}}
                                                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTYuNSA5LjQzOWwtMy42NzQgMi4yMy45NC00LjI2LTMuMjEtMi44ODMgNC4yNTQtLjQwNEw2LjUuMTEybDEuNjkgNC4wMSA0LjI1NC40MDQtMy4yMSAyLjg4Mi45NCA0LjI2eiIvPjwvc3ZnPg==" class="_2lQ_WZ">
                                                                </div>
                                                                <p class="_2xg6Ul">{{item.title}}</p>
                                                            </div>
                                                            <div class="col">
                                                                <div class="qwjRop">
                                                                    <div>
                                                                        <div class="">
                                                                            {{item.details}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="_2pclJg">
																<p class="_3LYOAd _3sxSiS">{{item.user}}</p>
																<svg width="14" height="14" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" class="_3Zp_NJ">
																	<g>
																		<circle cx="6" cy="6" r="6" fill="#878787"></circle>
																		<path stroke="#FFF" stroke-width="1.5" d="M3 6l2 2 4-4" fill="#878787"></path>
																	</g>
																</svg>
																<p class="_19inI8"><span>Certified Buyer</span><span>, {{item.address}}</span></p>
																<p class="_3LYOAd">{{item.create_date}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="_2qNetV"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

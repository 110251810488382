<app-breadcrumb></app-breadcrumb>
<section class="contact-us">
	<div class="container">
		<div class="row text-xxl-start text-xl-start text-lg-start text-md-start">
			<div class="col-xl-6 col-lg-6 col-md-12">
				<div class="row">
					<div class="col-sm-6 mb-5">
						<h4>Shapar, Rajkot</h4>
						<p class="mb-0 fw-semibold">{{company.office_address}}</p>
						<p class="mb-0 fw-semibold">{{company.contact_email}}</p>
						<p class="mb-0 fw-semibold">{{company.contact_number}}</p>
					</div>
				</div>
				<div class="row" *ngIf="company.mapURL">
					<div class="col-12 my-30">
						<div class="ratio ratio-21x9 mb-5" >
							<iframe
								[attr.src]="httpService.getSafeURL('https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29558.493311396942!2d' + company.long+ '!3d' + company.lat + '!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc010eafe67f38f17!2sRepute%20Foods%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1640250106126!5m2!1sen!2sin', 'RESOURCE')"
								height="500"
								style="border:0; margin: 0;"
								allowfullscreen=""
								aria-hidden="false"
								tabindex="0"
							></iframe>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-6 col-lg-6 col-md-12">
				<div class="section-title mb-25">
					<h2 class="sec-title1">Get In Touch</h2>
					<h3 class="sec-title2">Inform Us</h3>
				</div>
				<form method="POST" class="contact-form contact-form1 mb-30">
					<div class="row g-4">
						<div class="col-12 form-group mb-0">
							<input type="text" name="name" [(ngModel)]="name" class="form-control" placeholder="Your Name">
						</div>
						<div class="col-lg-6 form-group mb-0">
							<input type="email" name="email" [(ngModel)]="email" class="form-control" placeholder="Your Email">
						</div>
						<div class="col-lg-6 form-group mb-0">
							<input type="text" name="contact" [(ngModel)]="contact" class="form-control" placeholder="Your Contact">
						</div>
						<div class="col-lg-12 form-group mb-0">
							<input type="text" name="subject" [(ngModel)]="subject" class="form-control" placeholder="Your Subject">
						</div>
						<div class="col-12 form-group mb-0">
							<textarea class="form-control" name="message" [(ngModel)]="message" placeholder="Your Message"></textarea>
						</div>
						<div class="col-12 form-group mb-0">
							<button (click)="submitQuery($event)" type="button" class="btn btn-theme">Send Message</button>
						</div>
					</div>
				</form>
				<p class="form-messages mt-20 mb-0"></p>
			</div>
		</div>
	</div>
</section>

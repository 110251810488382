import * as actions from 'src/app/store/actions';
import { Auth, chatUrl, imageUrl, mainUrl } from 'src/app/store/models';
import { Action, createReducer, on } from '@ngrx/store';
import { IS_LOGGEDIN } from '.';

export interface State {
	auth: any;
	session:any;
	company:any;
	order: any,
	category: any,
	cart: any,
	imgURL: string;
	mainUrl: string;
	chatUrl: string;
	isLoggedIn: boolean;
	activePage: any;
	previousURL:any;
}

export interface AppState {
	main: State;
}

const initialState: State = {
	auth: {
		_id: "",
		email: "",
		user_type: false,
		social_id: null,
		pass_string: "",
		mobile: "",
		first_name: null,
		last_name: null,
		gender: true,
		image: null,
		status: true,
	},
	company:{
		_id: "",
		name : "",
		logo : "",
		office_address : "",
		lat : "",
		long : "",
		mapURL: "",
		contact_number : "",
		contact_email : "",
		support_email : "",
		sales_email : "",
		facebook_link : "",
		instagram_link : "",
		youtube_link : "",
		twitter_link : "",
		pinterest_link : "",
		linkedin_link : "",
		google_plus_link : "",
	},
	activePage: "Home",
	cart: [],
	category: [],
	order: [],
	isLoggedIn: false,
	imgURL : imageUrl,
	mainUrl : mainUrl,
	chatUrl : chatUrl,
	previousURL: "",
	session: "",
};

const isJSON = (str:string) => {
	try {
		JSON.parse(str);
	}catch(e) {
		return false;
	}
	return true;
}
// get local storage
const getStorage = (type: string) => JSON.parse(localStorage.getItem(type) || '{}');
// get session storage
const getSessionStorage = (type: string) => JSON.parse(sessionStorage.getItem(type) || '{}');
// set local storage
const setStorage = async (data:any, type: string) => await localStorage.setItem(type, JSON.stringify(data));
// set session storage
const setSessionStorage = async (data:any, type: string) => await sessionStorage.setItem(type, JSON.stringify(data));

const authClone = getStorage('maaschoice_auth');
if(authClone && authClone._id){
	initialState.auth = JSON.parse(JSON.stringify(authClone));
}
const sessionClone = getStorage('maaschoice_session');
if(sessionClone){
	initialState.session = JSON.parse(JSON.stringify(sessionClone.valueOf()));
}
const companyClone = getStorage('maaschoice_company');
if(companyClone && companyClone._id){
	initialState.company = JSON.parse(JSON.stringify(companyClone.valueOf()));
}
const catsClone = getStorage('maaschoice_category');
if(catsClone && catsClone.length > 0){
	initialState.category = JSON.parse(JSON.stringify(catsClone.valueOf()));
}
const cartClone = getSessionStorage('maaschoice_carts');
if(cartClone && Array.isArray(cartClone) && cartClone.length > 0){
	initialState.cart = JSON.parse(JSON.stringify(cartClone.valueOf()));
}
const ordersClone = getSessionStorage('maaschoice_orders');
if(ordersClone && Array.isArray(ordersClone) && ordersClone.length > 0){
	initialState.order = JSON.parse(JSON.stringify(ordersClone.valueOf()));
}

const _appReducer = createReducer(
	initialState,
	on(actions.GET_COMPANY, (state) => {
		if (localStorage.getItem('maaschoice_company')) {
			const data = JSON.parse(localStorage.getItem('maaschoice_company') || '{}');
			if(data._id) {
				actions.SET_COMPANY({company: data});
			}
		}
		return state.company;
	}),
	on(actions.SET_COMPANY, (state, {company}) => {
		setStorage(company, 'maaschoice_company');
		return ({ ...state, company: company });
	}),
	on(actions.GET_SESSION, (state) => {
		if (sessionStorage.getItem('maaschoice_session')) {
			const data = JSON.parse(sessionStorage.getItem('maaschoice_session') || '{}');
			if(data._id) {
				actions.SET_SESSION({session: data});
			}
		}
		return state.session;
	}),
	on(actions.SET_SESSION, (state, {session}) => {
		setSessionStorage(session, 'maaschoice_session');
		return ({ ...state, session: session });
	}),
	on(actions.GET_AUTH, (state) => {
		if (localStorage.getItem('maaschoice_auth')) {
			const data = JSON.parse(localStorage.getItem('maaschoice_auth') || '{}');
			if(data._id) {
				actions.SET_AUTH({auth: data});
			}
		}
		return state.auth;
	}),
	on(actions.SET_AUTH, (state, {auth}) => {
		setStorage(auth, 'maaschoice_auth');
		return ({ ...state});
	}),
	on(actions.GET_CART, (state) => {
		if (sessionStorage.getItem('maaschoice_carts')) {
			let cart_data = sessionStorage.getItem('maaschoice_carts');
			const data = cart_data && isJSON(cart_data) ? JSON.parse(cart_data) : [];
			if(data && data.length > 0) {
				actions.SET_CART({cart: data});
			}
		}
		return state && state.cart ? state.cart : [];
	}),
	on(actions.SET_CART, (state, {cart}) => {
		setSessionStorage(cart, 'maaschoice_carts');
		return ({ ...state, cart: cart });
	}),
	on(actions.GET_CATEGORY, (state) => {
		if (getStorage('maaschoice_category')) {
			let category_data = getStorage('maaschoice_category');
			const data = category_data && isJSON(category_data) ? JSON.parse(category_data) : [];
			if(data && data.length > 0) {
				actions.SET_CATEGORY({category: data});
			}
		}
		return state && state.cart ? state.cart : [];
	}),
	on(actions.SET_CATEGORY, (state, {category}) => {
		setStorage(category, 'maaschoice_category');
		return ({ ...state, category: category });
	}),
	on(actions.GET_ORDER, (state) => {
		if (getStorage('maaschoice_orders')) {
			let order_data = getStorage('maaschoice_orders');
			const data = order_data && isJSON(order_data) ? JSON.parse(order_data) : [];
			if(data && data.length > 0) {
				actions.SET_ORDER({order: data});
			}
		}
		return state && state.order ? state.order : [];
	}),
	on(actions.SET_ORDER, (state, {order}) => {
		setStorage(order, 'maaschoice_orders');
		return ({ ...state, order: order });
	}),
	on(actions.IS_LOGGEDIN, (state) => {
		let login = false;
		if(state.auth && state.auth._id){
			login = true;
		}
		const isLogin = {
			...state,
			isLoggedIn: login
		};
		return isLogin;
	}),
	on(actions.LOG_OUT, (state) => {
		localStorage.clear();
		sessionStorage.clear();
		return {
			auth: {
				_id: "",
				email: "",
				user_type: false,
				social_id: null,
				pass_string: "",
				mobile: "",
				first_name: null,
				last_name: null,
				gender: true,
				image: null,
				status: true,
			},
			session: null,
			company: {
				_id: "",
				name : "",
				logo : "",
				office_address : "",
				lat : "",
				long : "",
				mapURL: "",
				contact_number : "",
				contact_email : "",
				support_email : "",
				sales_email : "",
				facebook_link : "",
				instagram_link : "",
				youtube_link : "",
				twitter_link : "",
				pinterest_link : "",
				linkedin_link : "",
				google_plus_link : "",
			},
			activePage: "Home",
			category: [],
			cart: [],
			order: [],
			isLoggedIn: false,
			imgURL : imageUrl,
			mainUrl : mainUrl,
			chatUrl : chatUrl,
			previousURL: "",
		};
	}),
	on(actions.GET_PAGE, (state) => state.activePage),
	on(actions.SET_PAGE, (state, {name}) => {
		return {
			...state,
			activePage: name ? name : ""
		};
	}),
	on(actions.GET_ROUTE, (state) => state.previousURL),
	on(actions.SET_ROUTE, (state, {url}) => {
		return {
			...state,
			previousURL: url ? url : ""
		};
	}),
);

export function reducer(state: State | undefined, action: Action) {
	return _appReducer(state, action);
}

import {
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
	GET_CART,
	SET_CART,
	reducer,
	Auth,
	SET_AUTH,
	GET_AUTH,
} from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
import { animationFrameScheduler, Subscription } from 'rxjs';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import {
	IModalDialogButton,
	ModalDialogService,
	IModalDialogSettings,
	SimpleModalComponent,
} from 'ngx-modal-dialog';
import * as dates from 'src/app/helpers';
import * as $ from 'jquery';

@Component({
	selector: 'app-my-profile',
	templateUrl: './my-profile.component.html',
	styleUrls: ['./my-profile.component.scss'],
	providers: [ToastService],
})
export class MyProfileComponent implements OnInit {
	private buttons!: IModalDialogButton;
	private dialogConfig!: IModalDialogSettings;
	public user: any = Auth;
	public order: any;
	public address: any;
	public isAddAddress: boolean = false;
	private isDeleteAddress: boolean = false;
	private modelRef: any;
	public newAddress: any = {
		_id: '',
		user_id: '',
		person_name: '',
		mobile: '',
		address1: '',
		address2: '',
		city: '',
		pincode: '',
		type: 1,
		status: '',
	};
	public review: any;
	public activePage: any = 'personal';
	private sub!: Subscription;
	private isApiCalled: boolean = false;
	user_id: any = '';
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
		public cd: ChangeDetectorRef,
		private store: Store<reducers.AppState>,
		private modalService: ModalDialogService,
		private viewRef: ViewContainerRef
	) {
		// this.user = this.httpService.auth;
		// this.store.dispatch(GET_AUTH());
		this.router.events.subscribe((event: any) => {
			const query: any = this.route.snapshot.queryParams;
			const page: any = this.route.snapshot.params;

			if (event instanceof NavigationStart) {
				if (query.user_id) {
					this.user_id = query.user_id;
				}
			}
			if (event instanceof NavigationEnd) {
				if (query.user_id) {
					this.user_id = query.user_id;
				}
			}

			if (page && page.slug) {
				this.changeActivePage(page.slug);
			}
		});
	}

	ngOnInit(): void {
		this.sub = this.store.select('main').subscribe((data: any) => {
			this.user = this.httpService.getAuth();
			if (this.user && this.user._id && this.isApiCalled === false) {
				this.getUsersDetailsById(this.user._id);
			}
		});
	}

	changeActivePage(page: string) {
		this.activePage = page || 'personal';
		if (this.activePage !== page) {
			this.user = this.httpService.getAuth();
			this.getUsersDetailsById(this.user._id);
		}
	}

	getUsersDetailsById(_id: any) {
		this.isApiCalled = true;
		this.http
			.post(this.httpService.mainUrl + 'getUsersDetailsById', {
				_id: _id,
			})
			.subscribe((res: any) => {
				if (res.status == 1) {
					this.store.dispatch(SET_AUTH({ auth: res.data }));
					const cart = JSON.parse(
						JSON.stringify([...res.data.cart.valueOf()])
					);
					this.store.dispatch(SET_CART({ cart: cart }));
					this.user = { ...res.data.valueOf() };
					this.review = JSON.parse(
						JSON.stringify([...res.data.reviews.valueOf()])
					);
					this.review = JSON.parse(
						JSON.stringify([...this.review.valueOf().reverse()])
					);
					this.address = JSON.parse(
						JSON.stringify([...res.data.address.valueOf()])
					);
					this.address = JSON.parse(
						JSON.stringify([...this.address.valueOf().reverse()])
					);
					this.order = JSON.parse(
						JSON.stringify([...res.data.orders.valueOf()])
					);
					this.order = JSON.parse(
						JSON.stringify([...this.order.valueOf().reverse()])
					);
				} else {
					this.notify.generateToast('error', '', res.message);
				}
			});
	}

	updateProfile() {
		const profile = {
			...this.user.valueOf(),
		};
		this.http
			.post(this.httpService.mainUrl + 'updateUsersDetailsById', profile)
			.subscribe((res: any) => {
				if (res.status == 1) {
					this.notify.generateToast(
						'success',
						'Update Success!',
						res.message
					);
					this.store.dispatch(SET_AUTH({ auth: profile }));
				} else {
					this.notify.generateToast('error', '', res.message);
				}
			});
	}

	getOrderDate(date: string) {
		return dates._generateDateFormat(date, 'DDDD, MM D, YYYY, hh:ii, AP');
	}

	isValidReturn(order: any, product: any) {
		let flag = false;
		if (order.status === 'Delivered') {
			let dt: any = new Date(order.updatedAt);
			let return_date = dates._generateTimes(
				dt,
				'days',
				product.return_days
			);
			if (dates._getDifference(dt, return_date) > 1) {
				flag = true;
			}
		}
		return flag;
	}

	saveAddress() {
		const address = {
			...this.newAddress.valueOf(),
		};
		address.user_id = this.user._id;
		const apiURL =
			this.httpService.mainUrl +
			(address._id ? 'updateUserAddress' : 'insertUserAddress');
		this.http.post(apiURL, address).subscribe((res: any) => {
			if (res.status == 1) {
				this.isAddAddress = false;
				// this.notify.generateToast('success', "Success!", res.message);
				this.getUsersDetailsById(this.user._id);
				this.modalService.openDialog(this.viewRef, {
					title: res.message,
					childComponent: SimpleModalComponent,
					data: {},
					actionButtons: [
						// { text: 'Close' }, // no special processing here
						// { text: 'Close', buttonClass: 'btn btn-success', onAction: () => {this.confirmRemoveAddress(item); return true;} },
						{
							text: 'Close',
							buttonClass:
								'ms-4 w-50 me-4 font-weight-bold pb-2 pt-2 text-light bg-dark border-dark',
							onAction: () => true,
						},
					],
					placeOnTop: true,
					settings: {
						footerClass: 'justify-content-center d-flex mb-4',
						headerTitleClass: 'fw-bold',
						modalDialogClass:
							'align-items-center d-flex flex-fill me-4 ms-4',
					},
				});
			} else {
				this.notify.generateToast('error', 'Warning!', res.message);
			}
		});
	}

	editAddress(item: any, index: any) {
		this.newAddress = JSON.parse(JSON.stringify({ ...item }));
		this.isAddAddress = true;
	}

	closeAddress() {
		this.isAddAddress = false;
		this.newAddress = {
			_id: '',
			user_id: '',
			person_name: '',
			mobile: '',
			address1: '',
			address2: '',
			city: '',
			pincode: '',
			type: '1',
			status: '',
		};
	}

	async removeAddress(item: any, index: any) {
		const self = this;
		this.modalService.openDialog(this.viewRef, {
			title: 'Are you sure you want to remove this address?',
			childComponent: SimpleModalComponent,
			data: {},
			actionButtons: [
				// { text: 'Close' }, // no special processing here
				{
					text: 'Yes',
					buttonClass:
						'text-light bg-dark w-100 ms-4 font-weight-bold pb-2 pt-2 border-dark',
					onAction: () => {
						this.confirmRemoveAddress(item);
						return true;
					},
				},
				{
					text: 'No',
					buttonClass:
						'ms-4 w-100 me-4 font-weight-bold pb-2 pt-2 text-light bg-dark border-dark',
					onAction: () => true,
				},
			],
			placeOnTop: true,
			settings: {
				headerTitleClass: 'fw-bold text-center',
				modalDialogClass:
					'align-items-center d-flex flex-fill me-4 ms-4',
				footerClass: 'justify-content-center d-flex mb-4',
			},
		});
	}

	confirmRemoveAddress(item: any) {
		const address = {
			address_id: item._id.valueOf(),
		};
		this.http
			.post(this.httpService.mainUrl + 'deleteUserAddress', address)
			.subscribe((res: any) => {
				if (res.status == 1) {
					this.notify.generateToast(
						'success',
						'Success!',
						res.message
					);
					this.getUsersDetailsById(this.user._id);
					true;
				} else {
					this.notify.generateToast('error', 'Warning!', res.message);
					true;
				}
			});
	}

	popUpModal() {
		this.modalService.openDialog(this.viewRef, {
			title: 'Address Added Successfully!',
			childComponent: SimpleModalComponent,
			// data: {text: "Address Added Successfully!"},
			data: {},
			actionButtons: [
				// { text: 'Close' }, // no special processing here
				// { text: 'Close', buttonClass: 'btn btn-success', onAction: () => {this.confirmRemoveAddress(item); return true;} },
				{
					text: 'Close',
					buttonClass:
						'ms-4 w-100 me-4 font-weight-bold pb-2 pt-2 text-light bg-dark border-dark',
					onAction: () => true,
				},
			],
			placeOnTop: true,
			settings: {
				footerClass: 'justify-content-center d-flex mb-4',
				headerTitleClass: 'fw-bold',
				// bodyClass: "modal-body text-success fw-bold",
				modalClass:
					'modal ngx-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable',
			},
		});
	}

	getRatingClass(data: any) {
		let str = '';
		if (data.rating == 1) {
			str = '_1nLEql';
		}
		if (data.rating == 2) {
			str = '_1x2VEC';
		}
		return str;
	}

	getQueryItem(item: any, index: any) {
		let data = {
			_id: item._id ? item._id : null,
			order_id: item.order_id ? item.order_id : null,
			category_id: item.category_id ? item.category_id : null,
			product_id: item.product_id ? item.product_id : null,
			variant_id: item.variant_id ? item.variant_id : null,
			attribute_id: item.attribute_id ? item.attribute_id : null,
			attribute_name: item.attribute_value ? item.attribute_value : null,
			attribute_value: item.attribute_value ? item.attribute_value : null,
			attribute_price: item.attribute_price ? item.attribute_price : null,
			image: item.product_image ? item.product_image : null,
			name: item.variant_name ? item.variant_name : null,
			product_name: item.product_name ? item.product_name : null,
			price: item.price ? item.price : null,
		};
		return JSON.stringify(data);
	}

	async deleteReview(data: any, index: any) {
		this.http
			.post(this.httpService.mainUrl + 'removeUserReview', {
				_id: data._id,
			})
			.subscribe(async (response: any) => {
				if (response.status == 1) {
					this.review.splice(index, 1);
					this.notify.generateToast(
						'success',
						'Success!',
						response.message
					);
				} else {
					this.notify.generateToast(
						'error',
						'Error!',
						response.message
					);
				}
			});
	}
}

<div class="order-list">
	<div class="row">
		<div class="col-md-12">
			<div class="order-title">
				<h1>My Orders</h1>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<ng-container *ngIf="orders && orders.length > 0; else thenOrderElseTemplate">
				<ng-container *ngFor="let item of orders; let index=index">
					<ng-container *ngFor="let product of item.items; let index1=index">
						<div class="order-box">
							<div class="card">
								<div class="card-header">
									<div class="card-header-title">
										<p>
											Order <span>{{item.order_number}}</span>
										</p>
										<p>
											Order Placed <br><span>{{getOrderDate(item.createdAt)}}</span>
										</p>
									</div>
									<div class="card-subtitle ">
										<p>
											Order: <span *ngIf="item.status === 'Placed'" class="badge badge-primary bg-primary">{{item.status}}</span>
											<span *ngIf="item.status === 'Confirmed'" class="badge badge-info bg-info">{{item.status}}</span>
											<span *ngIf="item.status === 'Cancelled'" class="badge badge-danger bg-danger" >{{item.status}}</span>
											<span *ngIf="item.status === 'Rejected'" class="badge badge-danger bg-danger">{{item.status}}</span>
											<span *ngIf="item.status === 'Returned'" class="badge badge-dark bg-dark">{{item.status}}</span>

											<span *ngIf="item.status === 'Shipped'" class="badge badge-warning bg-warning">{{item.status}}</span>
											<span *ngIf="item.status === 'Dispatched'" class="badge badge-info bg-info">{{item.status}}</span>
											<span *ngIf="item.status === 'Delivered'" class="badge badge-success bg-success">{{item.status}}</span>
										</p>
										<p>
											Total: <br><span>₹ {{item.final_total}}</span>
										</p>
									</div>
								</div>
								<div class="card-body">
									<div class="catalog">
										<div class="media">
											<img [attr.src]="httpService.generateImagePreview(product.image)" [attr.alt]="product.name"/>
										</div>
										<div class="item-title">
											<h4>
												<a [routerLink]="'/product/' + httpService.compressSLUG(product.slug)">{{product.name}}</a>
											</h4>
											<div *ngIf="!isMobile">
												<small>{{product.category_name}}</small><br/>
												<small>Weight: {{product.selected_attribute.package_value+product.selected_attribute.package_type}}</small>
										</div>
											</div>
										<div class="dotted-line" *ngIf="isMobile">
												<small>{{product.category_name}}</small><br />
												<small>Weight: {{product.selected_attribute.package_value+product.selected_attribute.package_type}}</small>
											</div>
									</div>

									<div class="qty">
										Qty: <span>{{product.qty}}</span>
									</div>
									<div class="actions">
										<a class="btn btn-md btn-theme" [routerLink]="'/order-detail/' + item._id + '/'  + product.selected" >Order Details <i class="fa fa-chevron-right"></i></a>
									</div>
									<!-- <div class="right">
									</div> -->
								</div>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-template #thenOrderElseTemplate>
				<div class="order-box">
					<div class="card">
						<div class="card-header">
							<div class="card-subtitle">
								<ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader>
							</div>
						</div>
						<div class="card-body">
							<div class="catalog">
								<div class="media">
									<ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader>
								</div>
								<div class="item-title">
									<p><ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader></p>
									<small><ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader></small>
								</div>
							</div>
							<div class="qty">
								<ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader>
							</div>
							<div class="actions">
								<ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader>
								<button>
									<ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader>
								</button>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>

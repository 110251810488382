import { Component, OnInit } from '@angular/core';
import { HttpService, ToastService } from 'src/app/services';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [ToastService]
})
export class ForgotPasswordComponent implements OnInit {
	username:any = '';
	constructor(
		private notify: ToastService,
		public router: Router,
		public httpService: HttpService,
		public http: HttpClient,
	) { }

	ngOnInit(): void {
	}

	submitForgot(event:any){

		const forgot = {
			username: this.username ? this.username : null,
		}

		this.http.post(this.httpService.mainUrl + 'forgotPassword', forgot).subscribe((res:any) => {
			if(res.status == 1){
				this.router.navigate(['/login']);
			}else {
				this.notify.generateToast('error', "", res.message)
			}
		})
	}

}

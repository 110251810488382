import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { GET_CART, SET_CART, reducer, SET_AUTH } from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
import { Subscription } from 'rxjs';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location, SlicePipe } from '@angular/common';
import * as dates from 'src/app/helpers';
import * as $ from 'jquery';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
// import 'https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/UbNqUk64849722632801.js';

@Component({
	selector: 'app-new-cart',
	templateUrl: './new-cart.component.html',
	styleUrls: ['./new-cart.component.scss'],
	providers: [ToastService],
})
export class NewCartComponent implements OnInit {
	@ViewChild('paytmForm', { static: false }) paytmForm!: ElementRef;
	public iscart: any = true;
	public iscartLoaded: any = false;
	public iscartCalculated: any = false;
	public checkout: any = false;
	public isCOD: any = false;
	public completed: any = false;
	public isAddressSelected: any = false;
	public isValidAddress: any = false;
	public isConfirmed: any = false;
	public coupon_code: any = '';
	public shipping_type: any = 'Free Shipping';
	public shipping_time: any = '4 - 5';
	public confirm_code: any = '';
	public verify_confirm_code: any = '';
	public currentAddress: any = {
		email: '',
		_id: '',
		user_id: '',
		person_name: '',
		mobile: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		pincode: '',
		type: '',
		status: '',
	};
	public guest_address: any = {
		person_name: '',
		email: '',
		mobile: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		country: 'india',
		pincode: '',
		type: 'Home',
	};
	public order: any = {
		_id: '',
		order_number: '',
		user_id: '',
		address_id: '',
		payment_method: 'Cash On Delivery',
		sub_total: 0,
		shipping: 0,
		discount: 0,
		tax: 0,
		final_total: 0,
		order_date: '',
		delivery_date: '',
		items: [],
		shipping_details: 0,
		status: 'Placed',
		session_id: '',
		type: '1', //1 = guest, 2 = user
		guestInfo: {},
	};
	email = '';
	public orders?: any = [];
	public address?: any = [];
	private sub?: Subscription;
	public items!: any[];
	public cart: any = {
		items: [],
		user_id: '',
		session_id: '',
		shipping_type: 1,
		subtotal: 0,
		total: 0,
		tax_type: 0,
		tax: 0,
		discount: 0,
		discount_type: 0,
		shipping_charge: 0,
	};
	public user: any;
	public session: any;
	public paytmOrder: any = {
		MID: '', // paytm provide
		WEBSITE: 'WEBSTAGING', // paytm provide
		INDUSTRY_TYPE_ID: 'Retail', // paytm provide
		CHANNEL_ID: 'WEB', // paytm provide
		ORDER_ID: '', // unique id
		CUST_ID: '', // customer id
		MOBILE_NO: '', // customer mobile number
		EMAIL: '', // customer email
		TXN_AMOUNT: '0', // transaction amount
		CALLBACK_URL: 'https://maaschoice.in:3011/Development/api/orderSuccess', // Call back URL that i want to redirect after payment fail or success
		CHECKSUMHASH: '',
	};
	guestForm!: FormGroup;
	submited: boolean = false;
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
		public cd: ChangeDetectorRef,
		private store: Store<reducers.AppState>,
		private formBuilder: FormBuilder
	) {
		const data = JSON.parse(
			localStorage.getItem('maaschoice_auth') || '{}'
		);
		if (data && data._id) {
			this.email = data.email;
		}
		this.router.events.forEach((event) => {
			let params: any;
			if (event instanceof NavigationStart) {
				params = this.route.snapshot.queryParams;
			}
			if (event instanceof NavigationEnd) {
				params = this.route.snapshot.queryParams;
			}
			if (params && params.data) {
				let data = JSON.parse(params.data);
				if (data && data.end_user_id) {
					this.updateUserList({ type: 1, items: [] });
				}
				if (data && data.ORDERID) {
					this.updateUserList({ type: 1, items: [] });
					this.verifyOrderPurchase(data);
					// data.USERID = this.user._id;
					// data.USEREMAIL = this.user.email;
					// data.USEREMOBILE = this.user.mobile;
					// let format = {
					// 	USERID: this.user._id,
					// 	USEREMAIL: this.user.email,
					// 	USEREMOBILE: this.user.mobile,
					// 	ORDERID: 'ORDER-876BkpI0ZQwuPkK',
					// 	MID: 'irKyYg85884074653055',
					// 	TXNID: '20210513111212800110168193602628439',
					// 	TXNAMOUNT: '224.00',
					// 	PAYMENTMODE: 'DC',
					// 	CURRENCY: 'INR',
					// 	TXNDATE: '2021-05-13 13:10:09.0',
					// 	STATUS: 'TXN_SUCCESS',
					// 	RESPCODE: '01',
					// 	RESPMSG: 'Txn Success',
					// 	GATEWAYNAME: 'HDFC',
					// 	BANKTXNID: '777001769443141',
					// 	BANKNAME: 'Bank of Bahrain and Kuwait',
					// 	CHECKSUMHASH: 'oFx1fA9+JI4zD7lGtwKHvZybDttxOsX/Kf9fYLSQwoApdNvD7B4Hu0NTm1x7bXfYv2aOnVrmGgjtW1w7WXLJMsj8FxV1YR91DemICm8FtNg='
					// }
					// setTimeout(() => {

					// }, 1700)
				}
			}
		});
	}

	ngOnInit(): void {
		this.sub = this.store.select('main').subscribe((data: any) => {
			this.session =
				data && data.session != undefined && data.session
					? data.session
					: data;
			this.user = this.httpService.auth;
			if (this.user && this.user._id) {
				if (this.iscartLoaded === false) {
					this.getUsersDetailsById(this.user._id);
				}
			} else {
				const cart =
					data.cart && Array.isArray(data.cart) ? data.cart : [];
				this.session =
					data && data.session != undefined && data.session
						? data.session
						: '';
				if (this.cart.items.length !== cart.length) {
					this.store.dispatch(SET_CART({ cart: cart }));
				}
				this.cart.items = cart;
				this.items = this.cart.items;
				if (
					this.items &&
					Array.isArray(this.items) &&
					this.items.length > 0 &&
					this.iscartCalculated === false
				) {
					this.calculateTotal();
				}
			}
		});
		this.guestForm = new FormGroup({
			email: new FormControl(null),
			person_name: new FormControl(null),
			mobile: new FormControl(null),
			address1: new FormControl(null),
			address2: new FormControl(null),
			city: new FormControl(null),
			state: new FormControl(null),
			country: new FormControl(null),
			pincode: new FormControl(null),
			type: new FormControl(null),
		});
		this.guestForm = this.formBuilder.group({
			email: ['', []],
			person_name: ['', [Validators.required, Validators.minLength(4)]],
			mobile: [
				'',
				[
					Validators.required,
					Validators.minLength(10),
					Validators.maxLength(10),
				],
			],
			address1: ['', [Validators.required, Validators.minLength(4)]],
			address2: ['', [Validators.required, Validators.minLength(4)]],
			city: ['', [Validators.required, Validators.minLength(4)]],
			state: ['', [Validators.required]],
			country: ['', [Validators.required]],
			pincode: [
				'',
				[
					Validators.required,
					Validators.minLength(6),
					Validators.maxLength(6),
				],
			],
			type: ['', [Validators.required]],
		});
		this.checkout = false;
	}

	get f() {
		return this.guestForm.controls;
	}

	finalSubmitOrder(
		event: any,
		user_id: any,
		currentAddress_id: any,
		isValidAddress: any,
		isCOD: any,
		guest_address: any,
		session: any
	) {
		let type = 2;
		console.log(currentAddress_id, isValidAddress, user_id);
		if (!user_id) {
			if (
				guest_address.pincode &&
				session &&
				isValidAddress === true &&
				guest_address.email
			) {
				if (isCOD) {
					this.generateCODOrder();
				} else {
					if (type === 1) {
						this.addDataToOrder();
					} else {
						this.verifyCartType(event);
					}
				}
			} else {
				if (!guest_address.email) {
					this.notify.generateToast(
						'warning',
						'',
						'Please Enter Email'
					);
				} else {
					this.notify.generateToast(
						'warning',
						'',
						'Please Enter address'
					);
				}
			}
		} else {
			if (
				(!guest_address.pincode && !currentAddress_id) ||
				!isValidAddress ||
				!user_id
			) {
				this.notify.generateToast(
					'warning',
					'',
					'Please select address'
				);
			} else {
				if (user_id && currentAddress_id && isValidAddress === true) {
					type = 1;
				}
				if (guest_address.pincode) {
					type = 1;
				}
				if (isCOD) {
					this.generateCODOrder();
				} else {
					if (type === 1) {
						this.addDataToOrder();
					} else {
						this.verifyCartType(event);
					}
				}
			}
		}
	}

	generateCODOrder() {
		this.isConfirmed = true;
		this.order = {
			_id: '',
			order_number: '',
			user_id: null,
			address_id: null,
			payment_method: 'Cash On Delivery',
			sub_total: this.cart.subtotal,
			shipping: this.cart.shipping_charge + 50,
			discount: this.cart.discount,
			tax: this.cart.tax,
			final_total: this.cart.total + 50,
			order_date: dates._generateDateFormat('', 'yyyy-mm-dd'),
			delivery_date: dates._generateDate(
				dates._generateDateFormat('', 'yyyy-mm-dd'),
				'day',
				'+4',
				'yyyy-mm-dd'
			),
			items: JSON.parse(JSON.stringify(this.items)),
			shipping_details: this.cart.shipping_type,
			status: 'Placed',
			guestInfo: null,
			session_id: null,
		};
		if (this.user && this.user._id) {
			this.order.user_id = this.user._id;
			this.order.address_id = this.currentAddress._id;
		} else {
			this.order.session_id = this.httpService.getSession();
			this.order.guestInfo = { address: this.guest_address };
		}

		const unique: any =
			this.user && this.user._id ? this.user._id : this.session;

		var order_data: any = {
			amount: this.order.final_total,
			currency: 'INR',
			mid: '',
			mkey: '',
			callback: `https://maaschoice.in:3010/Development/api/orderSuccess`,
			payment_type: `Order At MaasChoice.`,
			receipt: `#user_id_${this.user._id}`,
			status: 'INTIATED',
			order_id: `ORDER-${dates.generate_token(
				10
			)}-${new Date().getTime()}`,
			txnToken: null /* update token value */,
			user_id: this.user._id,
			type: 'place_order',
			tokenType: 'TXN_TOKEN',
			custId: `#user_id_${this.user._id}`,
			mobileNumber:
				this.user && this.user.mobile
					? this.user.mobile
					: this.guest_address.mobile,
			email: this.user && this.user.email ? this.user.email : '',
			customerName:
				this.user && this.user.first_name
					? `${this.user.first_name} ${this.user.last_name}`
					: this.guest_address.person_name,
			uri: '',
			order: JSON.parse(JSON.stringify(this.order)),
		};
		this.http
			.post(
				'https://maaschoice.in:3011/Development/api/' +
					'createCodOrderHash',
				order_data
			)
			.subscribe((response: any) => {
				this.store.dispatch(SET_CART({ cart: [] }));
				if (response.status == 1) {
					// this.resetEverything();
					this.order = response.data;
					this.notify.generateToast(
						'success',
						'Success!',
						response.message
					);
					this.router.navigate(['order-success'], {
						queryParams: {
							order_id: this.order._id,
						},
					});
				} else {
					this.notify.generateToast(
						'warning',
						'Payment Failed!',
						response.message
					);
				}
			});
	}

	getUsersDetailsById(_id: any) {
		this.iscartLoaded = true;
		this.http
			.post(this.httpService.mainUrl + 'getUsersDetailsById', {
				_id: _id,
			})
			.subscribe((res: any) => {
				if (res.status == 1) {
					const data = res.data;
					this.user = res.data;
					this.store.dispatch(SET_AUTH({ auth: res.data }));
					this.store.dispatch(SET_CART({ cart: data.cart }));
					this.user = { ...res.data.valueOf() };
					this.address = JSON.parse(
						JSON.stringify([...res.data.address.valueOf()])
					);
					this.address = JSON.parse(
						JSON.stringify([...this.address.valueOf().reverse()])
					);
					this.order = JSON.parse(
						JSON.stringify([...res.data.orders.valueOf()])
					);
					this.order = JSON.parse(
						JSON.stringify([...this.order.valueOf().reverse()])
					);
					this.cart.items =
						data.cart &&
						data.cart != undefined &&
						Array.isArray(data.cart)
							? data.cart
							: [];
					this.items = this.cart.items;

					if (
						this.items &&
						Array.isArray(this.items) &&
						this.items.length > 0 &&
						this.iscartCalculated === false
					) {
						this.calculateTotal();
					}
				} else {
					this.notify.generateToast('error', '', res.message);
				}
			});
	}

	addQTY(item: any, index: any) {
		const data: any = JSON.parse(JSON.stringify(item.valueOf()));
		const items: any = JSON.parse(JSON.stringify(this.items.valueOf()));
		// if(parseInt(data.qty) >= 10){
		// 	this.notify.generateToast('warning', 'Warning!', 'Max Quantity Is 10 Per Item');
		// 	return;
		// }
		data.qty = parseInt(data.qty) + 1;
		items[index] = data;
		this.cart.items = JSON.parse(JSON.stringify(items));
		this.items = JSON.parse(JSON.stringify(items));
		const cart = items;
		this.iscartCalculated = false;
		this.calculateTotal();
		if (this.user && this.user._id) {
			this.updateUserList({ type: 1, items: cart });
		}
	}

	removeQTY(item: any, index: any) {
		const data: any = JSON.parse(JSON.stringify(item.valueOf()));
		const items: any = JSON.parse(JSON.stringify(this.items.valueOf()));
		if (data.qty == 1) {
			this.notify.generateToast(
				'warning',
				'Warning!',
				'Min Quantity Should 1'
			);
			return;
		}
		data.qty = parseInt(data.qty) - 1;
		items[index] = data;
		this.cart.items = JSON.parse(JSON.stringify(items));
		this.items = JSON.parse(JSON.stringify(items));
		const cart = this.items;
		this.iscartCalculated = false;
		this.calculateTotal();
		if (this.user && this.user._id) {
			this.updateUserList({ type: 1, items: cart });
		}
	}

	async removeItem(data: any, index: any) {
		let items: any = JSON.parse(JSON.stringify(this.items.valueOf()));
		items.splice(index, 1);
		const cart = items;

		this.emitter.emit('update_cart', { updateCart: true });
		this.cart.items = JSON.parse(JSON.stringify(items));
		this.items = JSON.parse(JSON.stringify(items));
		this.store.dispatch(SET_CART({ cart: this.cart.items }));
		this.iscartCalculated = false;
		this.calculateTotal();
		// this.cd.detectChanges();
		// this.notify.generateToast('danger', "Item Removed!", "Item Removed From Cart Successfully!");
		this.emitter.emit('cart_change', true, true, true);
		if (this.user && this.user._id) {
			await this.updateUserList({ type: 1, items: cart });
		}
	}

	shippingChange(element: any, val: any) {
		if (val == 1) {
			this.cart.shipping_charge = 0;
			this.shipping_type = 'Free Shipping';
			this.shipping_time = ' 4 To 5 ';
		}
		if (val == 2) {
			this.cart.shipping_charge = 10;
			this.shipping_type = 'Standard Shipping';
			this.shipping_time = ' 3 ';
		}
		if (val == 3) {
			this.cart.shipping_charge = 20;
			this.shipping_type = 'Express Fast Shipping';
			this.shipping_time = ' 1 To 2 ';
		}
		this.iscartCalculated = false;
		this.calculateTotal();
	}

	getSelected(item: any) {
		let type = item.selected_attribute.package_type;
		type =
			type === 'gm' ? 'g' : type.charAt(0).toUpperCase() + type.slice(1);
		return `${item.selected_attribute.package_value} ${type}`;
	}

	async calculateTotal() {
		this.iscartCalculated = true;
		let subtotal: any = 0;
		let total: any = 0;
		let tax: any = 0;
		let shipping: any = 0;
		let discount: any = 0;
		let discount_price: any = 0;
		const items: any = JSON.parse(JSON.stringify(this.items.valueOf()));
		if (items.length) {
			items.map((item: any, index: any) => {
				items[index].offer_price = parseInt(items[index].offer_price);
				items[index].price = parseInt(items[index].price);
				items[index].qty = items[index].qty ? items[index].qty : 1;
				console.log('items[index].qty', items[index].qty);
				if (parseInt(items[index].offer_price) > 0) {
					items[index].total =
						parseInt(items[index].offer_price) *
						parseInt(items[index].qty);
				} else {
					items[index].total =
						parseInt(items[index].price) *
						parseInt(items[index].qty);
				}
				subtotal =
					subtotal +
					(parseInt(items[index].total)
						? parseInt(items[index].total)
						: 0);
				console.log('items[index].qty', subtotal);
			});
		}
		tax = (parseInt(subtotal) * parseInt(this.cart.tax_type)) / 100;
		discount =
			(parseInt(subtotal) * parseInt(this.cart.discount_type)) / 100;
		this.cart.tax = !isNaN(tax) ? parseInt(tax) : 0;
		this.cart.discount = !isNaN(discount) ? parseInt(discount) : 0;
		discount_price = parseInt(subtotal) - parseInt(discount);
		subtotal = parseInt(discount_price + tax);
		this.cart.subtotal = parseInt(subtotal) > 0 ? parseInt(subtotal) : 0.0;
		this.cart.total = parseInt(subtotal + this.cart.shipping_charge);
		this.items = JSON.parse(JSON.stringify(items));
		this.cart.items = JSON.parse(JSON.stringify(this.items));
		// this.store.dispatch(SET_CART({cart: this.cart.items}));
		console.info('cart', JSON.parse(JSON.stringify(this.cart)));
		this.cd.detectChanges();
	}

	getPackageName(attribute: any) {
		let packageType = attribute?.package_type?.toUpperCase();
		if (packageType === 'GM') {
			packageType = 'g';
		}
		if (packageType === 'KG') {
			packageType = 'Kg';
		}
		if (packageType === 'LTR') {
			packageType = 'L';
		}
		let name = `${attribute.package_value}${packageType}`;
		return name;
	}

	updateCart() {
		this.store.dispatch(SET_CART({ cart: this.cart.items }));
	}

	toogleAddress(e: any) {
		e.preventDefault();
		$('.shipping-calculator-form').slideToggle();
	}

	onAddressSelect(element: any, type: number) {
		let pincode = '';
		const weight = 2;
		if (type === 1) {
			const selected = element.target.value;
			this.currentAddress =
				this.address.filter((add: any) => add._id == selected)[0] || {};
			pincode = this.currentAddress.pincode;
		} else {
			pincode = element.target.value;
		}
		this.http
			.post(this.httpService.mainUrl + 'checkDeliveryService', {
				pincode: pincode,
				weight: weight,
			})
			.subscribe((res: any) => {
				if (res.status == 1) {
					this.isValidAddress = true;
					this.notify.generateToast(
						'success',
						'Delivery Available!',
						res.message
					);
				} else {
					this.currentAddress = {
						email: '',
						_id: '',
						user_id: '',
						person_name: '',
						mobile: '',
						address1: '',
						address2: '',
						city: '',
						state: '',
						pincode: '',
						type: '',
						status: '',
					};
					this.notify.generateToast(
						'warning',
						'Opps, Delivery Not Available!',
						res.message
					);
				}
			});
	}

	async updateUserList(data: any) {
		const items = data.items && data.items.length > 0 ? data.items : [];
		this.http
			.post(this.httpService.mainUrl + 'addToUserList', {
				type: data.type,
				items: items,
				user_id: this.user._id,
			})
			.subscribe(async (response: any) => {
				if (response.status != 1) {
					this.notify.generateToast(
						'warning',
						'Warning!',
						response.message
					);
				} else {
					// this.iscartLoaded = false;
					// this.getUsersDetailsById(this.user._id);
					// this.store.dispatch(SET_CART({cart: items}));
					// this.cart.items = items;
					// this.items = this.cart.items;
				}
			});
	}

	async addDataToOrder() {
		this.isConfirmed = true;
		this.order = {
			_id: '',
			order_number: '',
			user_id: null,
			address_id: null,
			payment_method: 'Cash On Delivery',
			sub_total: this.cart.subtotal,
			shipping: this.cart.shipping_charge,
			discount: this.cart.discount,
			tax: this.cart.tax,
			final_total: this.cart.total,
			order_date: dates._generateDateFormat('', 'yyyy-mm-dd'),
			delivery_date: dates._generateDate(
				dates._generateDateFormat('', 'yyyy-mm-dd'),
				'day',
				'+4',
				'yyyy-mm-dd'
			),
			items: JSON.parse(JSON.stringify(this.items)),
			shipping_details: this.cart.shipping_type,
			status: 'Placed',
			guestInfo: null,
			session_id: null,
		};
		if (this.user && this.user._id) {
			this.order.user_id = this.user._id;
			this.order.address_id = this.currentAddress._id;
		} else {
			this.order.session_id = this.httpService.getSession();
			this.order.guestInfo = { address: this.guest_address };
		}

		const unique: any =
			this.user && this.user._id ? this.user._id : this.session;

		var order_data: any = {
			amount: this.order.final_total,
			currency: 'INR',
			mid: '',
			mkey: '',
			callback: `https://maaschoice.in:3011/Development/api/orderSuccess`,
			payment_type: `Order At MaasChoice.`,
			receipt: `#user_id_${this.user._id}`,
			status: 'INTIATED',
			order_id: `ORDER-${dates.generate_token(
				10
			)}-${new Date().getTime()}`,
			txnToken: null /* update token value */,
			user_id: this.user._id,
			type: 'place_order',
			tokenType: 'TXN_TOKEN',
			custId: `#user_id_${this.user._id}`,
			mobileNumber:
				this.user && this.user.mobile
					? this.user.mobile
					: this.guest_address.mobile,
			email: this.user && this.user.email ? this.user.email : '',
			customerName:
				this.user && this.user.first_name
					? `${this.user.first_name} ${this.user.last_name}`
					: this.guest_address.person_name,
			uri: '',
			order: JSON.parse(JSON.stringify(this.order)),
		};
		this.generatePaytmOrder(order_data);
	}

	generatePaytmOrder(order_data: any) {
		this.http
			.post(this.httpService.mainUrl + 'createPayTmOrderHash', order_data)
			.subscribe((response: any) => {
				if (response.status == 1) {
					const data = response.data;
					this.checkout = true;
					this.paytmOrder = {
						MID: data.MID, // paytm provide
						MKEY: data.MKEY,
						// WEBSITE: "WEBSTAGING", // paytm provide
						WEBSITE: 'DEFAULT', // paytm provide
						INDUSTRY_TYPE_ID: 'Retail', // paytm provide
						CHANNEL_ID: 'WEB', // paytm provide
						ORDER_ID: data.orderId, // unique id
						CUST_ID: `#user_id_${this.user._id}`, // customer id
						MOBILE_NO:
							this.user && this.user.mobile
								? this.user.mobile
								: this.guest_address.mobile, // customer mobile number
						EMAIL:
							this.user && this.user.email ? this.user.email : '', // customer email
						TXN_AMOUNT: this.order.final_total, // transaction amount
						// CALLBACK_URL: "http://localhost:4200/cart", // Call back URL that i want to redirect after payment fail or success
						CALLBACK_URL:
							'https://maaschoice.in:3011/Development/api/orderSuccess',
						TXN_TOKEN: data.TOKEN,
						CHECKSUMHASH: data.CHECKSUM,
					};
					this.createPaytmForm(this.paytmOrder);
				} else {
					this.notify.generateToast(
						'warning',
						'Payment Failed!',
						response.message
					);
				}
			});
	}

	createPaytmForm(order_data: any) {
		// return;
		const my_form: any = document.createElement('form');
		my_form.name = 'paytm_order_form';
		my_form.id = 'paytm_order_form';
		my_form.method = 'post';
		// my_form.action = 'https://securegw-stage.paytm.in/order/process';
		// my_form.action = `https://securegw-stage.paytm.in/theia/api/v1/showPaymentPage?mid=${this.paytmOrder.MID}&orderId=${this.paytmOrder.ORDER_ID}`;
		my_form.action = `https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=${this.paytmOrder.MID}&orderId=${this.paytmOrder.ORDER_ID}`;

		// const myParams = Object.keys(this.paytmOrder);
		// for (let i = 0; i < myParams.length; i++) {
		// 	const key = myParams[i];
		// 	let my_tb: any = document.createElement('input');
		// 	my_tb.type = 'hidden';
		// 	my_tb.name = key;
		// 	my_tb.value = this.paytmOrder[key];
		// 	my_form.appendChild(my_tb);
		// };
		let my_tb: any = document.createElement('input');
		my_tb.type = 'hidden';
		my_tb.name = 'mid';
		my_tb.value = this.paytmOrder.MID;
		my_form.appendChild(my_tb);
		my_tb = document.createElement('input');
		my_tb.type = 'hidden';
		my_tb.name = 'orderId';
		my_tb.value = this.paytmOrder.ORDER_ID;
		my_form.appendChild(my_tb);
		my_tb = document.createElement('input');
		my_tb.type = 'hidden';
		my_tb.name = 'txnToken';
		my_tb.value = this.paytmOrder.TXN_TOKEN;
		my_form.appendChild(my_tb);

		document.body.appendChild(my_form);
		const self = this;
		var config = {
			root: '',
			flow: 'DEFAULT',
			themeBackgroundColor: '#fff',
			themeColor: '#bdd239',
			headerBackgroundColor: '#bdd239',
			headerColor: '#fff',
			bodyColor: '#000',
			data: {
				orderId: this.paytmOrder.ORDER_ID /* update order id */,
				amount: this.paytmOrder.TXN_AMOUNT /* update amount */,
				token: this.paytmOrder.TXN_TOKEN /* update token value */,
				tokenType: 'TXN_TOKEN',
			},
			userDetail: {
				custId: this.paytmOrder.CUST_ID,
				mobileNumber: this.user.mobile,
				name: `${this.user.first_name} ${this.user.last_name}`,
			},
			payMode: {
				order: ['UPI', 'NB', 'CARD'],
			},
			merchant: {
				redirect: true,
				name: `Maa's Choice`,
			},
			handler: {
				notifyMerchant(eventName: any, data: any) {
					self.verifyOrderPurchase(data);
				},
				transactionStatus(data: any) {
					self.verifyOrderPurchase(data);
				},
			},
		};
		if (window.Paytm && window.Paytm.CheckoutJS) {
			window.Paytm.CheckoutJS.onLoad(
				function excecuteAfterCompleteLoad() {
					// initialze configuration using init method
					window.Paytm.CheckoutJS.init(config)
						.then(function onSuccess() {
							// after successfully updating configuration, invoke JS Checkout
							window.Paytm.CheckoutJS.invoke();
						})
						.catch(function onError(error: any) {
							console.log('error => ', error);
						});
				}
			);
		}

		setTimeout(async () => {
			// await $("#paytmForm").submit();
			// await $("#paytmForm").trigger('submit');
			await my_form.submit();
		}, 120);
	}

	resetEverything() {
		this.currentAddress = {
			_id: '',
			user_id: '',
			person_name: '',
			mobile: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			pincode: '',
			type: '',
			status: '',
		};
		this.order = {
			_id: '',
			order_number: '',
			user_id: '',
			address_id: '',
			payment_method: 'Cash On Delivery',
			sub_total: 0,
			shipping: 0,
			discount: 0,
			tax: 0,
			final_total: 0,
			order_date: '',
			delivery_date: '',
			items: [],
			shipping_details: 0,
			status: 'Placed',
			session_id: '',
			type: '1', //1 = guest, 2 = user
			guestInfo: {},
		};
		this.cart = {
			items: [],
			user_id: '',
			session_id: '',
			shipping_type: 1,
			subtotal: 0,
			total: 0,
			tax_type: 0,
			tax: 0,
			discount: 0,
			discount_type: 0,
			shipping_charge: 0,
		};
		this.items = [];
		this.paytmOrder = {
			MID: '', // paytm provide
			WEBSITE: 'WEBSTAGING', // paytm provide
			INDUSTRY_TYPE_ID: 'Retail', // paytm provide
			CHANNEL_ID: 'WEB', // paytm provide
			ORDER_ID: '', // unique id
			CUST_ID: '', // customer id
			MOBILE_NO: '', // customer mobile number
			EMAIL: '', // customer email
			TXN_AMOUNT: '0', // transaction amount
			CALLBACK_URL:
				'https://maaschoice.in:3011/Development/api/orderSuccess', // Call back URL that i want to redirect after payment fail or success
			CHECKSUMHASH: '',
		};
	}

	verifyOrderPurchase(data: any) {
		this.http
			.post(this.httpService.mainUrl + 'verifyOrderPurchase', data)
			.subscribe((response: any) => {
				if (response.status == 1) {
					const data = response.data;
					// this.resetEverything();
					this.order = data;
					this.notify.generateToast(
						'success',
						'Success!',
						response.message
					);
				} else {
					this.notify.generateToast(
						'warning',
						'Payment Failed!',
						response.message
					);
				}
			});
	}

	processToCheckout() {
		if (this.items.length > 0) {
			if (this.user._id) {
				this.iscart = false;
				this.checkout = true;
				this.completed = false;
				this.isConfirmed = false;
			} else {
				this.emitter.emit('auth_updated', { login: true });
			}
		}
	}

	async confirmOrder() {
		await this.processToCheckout();
		this.confirm_code = dates.generate_confirmation_code(9);
		const confirmation = {
			name: this.guest_address.person_name,
			shop: 'Maas Choice Fruts & Nuts',
			email: this.user.email,
			phone: this.guest_address.mobile,
			code: this.confirm_code,
			subject: 'Order Confirmation Code',
			message: 'Your Order Confirmation Code is \t' + this.confirm_code,
		};
		if (this.user._id) {
			confirmation.name = this.currentAddress.person_name;
			confirmation.phone = this.currentAddress.mobile;
		}
		var apiUrl = this.httpService.mainUrl + 'confirmOrder';
		this.http
			.post(apiUrl, confirmation)
			.subscribe(async (response: any) => {
				if (response.status == 1) {
					this.isConfirmed = true;
				}
			});
	}

	confirmCodeChange(event: any) {
		this.verify_confirm_code = event.target.value;
	}

	placeOrder() {
		if (this.confirm_code == this.verify_confirm_code) {
			const order = this.order;
			var apiUrl = this.httpService.mainUrl + 'placeOrder';
			this.http.post(apiUrl, order).subscribe(async (response: any) => {
				if (response.status == 1) {
					let data = response.data;
					this.cart = {
						items: [],
						user_id: this.user._id,
						session_id: null,
						shipping_type: 1,
						subtotal: 0,
						total: 0,
						tax_type: 0,
						tax: 0,
						discount: 0,
						discount_type: 0,
						shipping_charge: 0,
					};
					this.order = data;
					this.iscart = true;
					this.checkout = false;
					this.isAddressSelected = false;
					this.isConfirmed = false;
					this.completed = true;
					await this.updateUserList({ type: 1, items: [] });
					this.notify.generateToast(
						'success',
						'Success!',
						response.message
					);
				}
			});
		} else {
			this.notify.generateToast(
				'warning',
				'Warning!',
				'Invalid Verification Code'
			);
		}
	}

	paymentMethodChange(element: any, value: any) {
		this.order.payment_method = value;
	}

	// for guest order
	async verifyCartType(element: any) {
		this.submited = true;
		if (this.guestForm.invalid) {
			this.submited = true;
			return;
		}

		if (this.guestForm.valid) {
			this.submited = false;
			this.iscartCalculated = false;
			await this.calculateTotal();
			if (!this.order.order_number) {
				await this.addDataToOrder();
			}
		}
	}
}

import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
	GET_CART,
	SET_CART,
	reducer,
	Auth,
	SET_AUTH,
	GET_AUTH,
} from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
import { animationFrameScheduler, Subscription } from 'rxjs';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import {
	IModalDialogButton,
	ModalDialogService,
	IModalDialogSettings,
	SimpleModalComponent,
} from 'ngx-modal-dialog';
import * as dates from 'src/app/helpers';
import * as $ from 'jquery';

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit {
	@Input() orders: any = [];
	isMobile: boolean = false;
	mobileWidthLimit: any = 769;

	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
		public cd: ChangeDetectorRef,
		private store: Store<reducers.AppState>,
		private modalService: ModalDialogService,
		private viewRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		this.isMobile = false;
		if (window.innerWidth < this.mobileWidthLimit) {
			this.isMobile = true;
		}
	}

	getOrderDate(date: string) {
		return dates._generateDateFormat(date, 'DDDD, MM D, YYYY, hh:ii, AP');
	}

	isValidReturn(order: any, product: any) {
		let flag = false;
		if (order.status === 'Delivered') {
			let dt: any = new Date(order.updatedAt);
			let return_date = dates._generateTimes(
				dt,
				'days',
				product.return_days
			);
			if (dates._getDifference(dt, return_date) > 1) {
				flag = true;
			}
		}
		return flag;
	}
}

import {
	NgModule,
	NO_ERRORS_SCHEMA,
	CUSTOM_ELEMENTS_SCHEMA,
	ViewContainerRef,
} from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import {
	CommonModule,
	HashLocationStrategy,
	LocationStrategy,
	PathLocationStrategy,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
	BrowserAnimationsModule,
	NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/reducers';
import { AppRoutingModule } from './app-routing.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToasterModule } from 'angular2-toaster';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { ClickOutsideModule } from 'ng-click-outside';
// other services,routes,models etc
import { AuthGuard, ModifyMetaGuard } from './guard';

import {
	HttpService,
	EmiterService,
	ApiInterceptor,
	JsonSchemaService,
	noTransition,
	routerTransition,
	slideToBottom,
	slideToLeft,
	slideToRight,
	slideToTop,
	A2hsService,
	ToastService,
} from 'src/app/services';
// routes
// components
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

declare global {
	interface Window {
		Paytm?: any;
	}
}

import {
	HomeComponent,
	SearchComponent,
	AboutUsComponent,
	FaqComponent,
	ContactUsComponent,
	ProductComponent,
	ProductDetailComponent,
	PrivacyPolicyComponent,
	TermsConditionsComponent,
	LoginComponent,
	ForgotPasswordComponent,
	MyProfileComponent,
	CartComponent,
	ProductReviewDetailsComponent,
	WriteProductReviewComponent,
	ReturnPolicyComponent,
	InfrastructureComponent,
	CertificatesComponent,
	RegisterComponent,
	OrderSuccessComponent,
	OrderListComponent,
	OrderDetailsComponent,
	NewCartComponent,
} from './components';
import {
	BreadcrumbComponent,
	FooterComponent,
	HeaderComponent,
	LoaderComponent,
} from './common';
import { Error404Component, MainComponent } from './container';
import { LightboxModule } from 'ngx-lightbox';
import { ModalComponent } from './common/modal/modal.component';

@NgModule({
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		MainComponent,
		Error404Component,
		HomeComponent,
		SearchComponent,
		AboutUsComponent,
		ContactUsComponent,
		ProductComponent,
		ProductDetailComponent,
		PrivacyPolicyComponent,
		TermsConditionsComponent,
		BreadcrumbComponent,
		LoginComponent,
		ForgotPasswordComponent,
		MyProfileComponent,
		CartComponent,
		ProductReviewDetailsComponent,
		WriteProductReviewComponent,
		LoaderComponent,
		FaqComponent,
		ReturnPolicyComponent,
		InfrastructureComponent,
		CertificatesComponent,
		RegisterComponent,
		OrderSuccessComponent,
		OrderListComponent,
		OrderDetailsComponent,
		ModalComponent,
		NewCartComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		ClickOutsideModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		BrowserAnimationsModule,
		NoopAnimationsModule,
		HttpClientModule,
		StoreModule.forRoot({ main: reducer }),
		NgxSkeletonLoaderModule.forRoot({ loadingText: 'Loading...' }),
		ToasterModule.forRoot(),
		ModalDialogModule.forRoot(),
		CarouselModule,
		NgxSliderModule,
		LightboxModule,
	],
	providers: [
		HttpService,
		EmiterService,
		ToastService,
		Meta,
		A2hsService,
		AuthGuard,
		ModifyMetaGuard,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

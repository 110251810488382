<app-breadcrumb></app-breadcrumb>
<section class="user-profile">
	<div class="container">
		<div class="row text-center text-lg-start justify-content-center">
			<div class="col-lg-12 mt-30 mt-lg-0">
				<div class="my-profile">
					<div class="lef-sidebar">
						<ul>
							<li id="personal" [ngClass]="{'active': this.activePage === 'personal'}">
								<a routerLink="/profile/personal">
									<i class="icon fa fa-home"></i>
									<span>{{user.first_name}}</span>
								</a>
							</li>
							<li id="address" [ngClass]="{'active': this.activePage === 'address'}">
								<a routerLink="/profile/address">
									<i class="icon fa fa-map-marker"></i>My Address
								</a>
							</li>
							<li id="orders" [ngClass]="{'active': this.activePage === 'orders'}">
								<a routerLink="/profile/orders">
									<i class="icon fa fa-shopping-bag"></i>My Orders
								</a>
							</li>
							<li id="review" [ngClass]="{'active': this.activePage === 'review'}">
								<a routerLink="/profile/review">
									<i class="icon fa fa-star"></i>My Review
								</a>
							</li>
						</ul>
					</div>
					<div class="right-sidebar">
						<div class="page personal" *ngIf="this.activePage === 'personal'">
							<div class="row">
								<div class="col-md-12">
									<div class="title">
										<h1>
											My Profile
										</h1>
									</div>
									<div class="content">
										<div class="row">
											<div class="col-md-12 col-lg-6 ">
												<div class="form-group">
													<label>First Name</label>
													<input type="text" name="first_name" [(ngModel)]="user.first_name" class="form-control" placeholder="First Name">
												</div>
											</div>
											<div class="col-md-12 col-lg-6 ">
												<div class="form-group">
													<label>Last Name</label>
													<input type="text" name="last_name" [(ngModel)]="user.last_name" class="form-control" placeholder="Last Name">
												</div>
											</div>
											<div class="col-md-12 col-lg-6 ">
												<div class="form-group">
													<label>Mobile</label>
													<input type="text" maxlength="10" minlength="10" name="mobile" [(ngModel)]="user.mobile" class="form-control" placeholder="Your Mobile">
												</div>
											</div>
											<div class="col-md-12 col-lg-6 ">
												<div class="form-group">
													<label>Email</label>
													<input type="email" disabled name="email" [(ngModel)]="user.email" class="form-control" placeholder="Your Email">
												</div>
											</div>
											<div class="col-md-12 col-lg-6 ">
												<div class="form-group">
													<label>Change Password</label>
													<input type="password" name="password" [(ngModel)]="user.password" class="form-control" placeholder="Your Password">
												</div>
											</div>
											<div class="col-md-12 col-lg-12 ">
												<div class="form-group text-center">
													<label>&nbsp;</label>
													<button (click)="updateProfile()" type="button" class="btn-theme mt-4">Update</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="page address" *ngIf="this.activePage === 'address'">
							<div class="row">
								<div class="col-md-12">
									<div class="title">
										<h1>
											{{isAddAddress === false ? 'My Address' : (!newAddress._id ? 'Add Address' : 'Update Address')}}
										</h1>
										<div class="action-button">
											<button *ngIf="isAddAddress === false" type="button" class="btn btn-info-add" (click)="isAddAddress = true;">Add</button>
												<button *ngIf="isAddAddress === true" type="button" (click)="closeAddress()" class="closeButton border-theme text-theme bg-theme-hover sideMenuCls"
													>
													<i class="fas fa-times"></i>
												</button>
										</div>
									</div>
									<div class="content">
										<div class="row" *ngIf="address && address.length > 0 && isAddAddress === false">
											<div class="col-md-12">
												<ng-container *ngFor="let item of address; let index=index">
													<div class="list-group-item">
														<i *ngIf="item.type == '1'" class="fas fa-home"></i>
														<i *ngIf="item.type == '2'" class="fas fa-building"></i>
														<div class="actions">
															<i (click)="editAddress(item, index)" class="fas fa-edit"></i>
															<i (click)="removeAddress(item, index)" class="fas fa-times-circle"></i>
														</div>
														<div class="list-group-item-heading mb-1">
															{{item.person_name}} - {{item.mobile}}
														</div>
														<div class="list-group-item-text mb-1">
															{{item.address1}},{{item.address2}}
														</div>
														<div class="list-group-item-text mb-1">
															{{item.city}} - {{item.pincode}}
														</div>
													</div>
												</ng-container>
											</div>
										</div>
										<div class="row address-form" *ngIf="isAddAddress === true">
											<div class="col-md-6">
												<div class="form-group">
													<label>Contact Person</label>
													<input type="text" name="person_name" [(ngModel)]="newAddress.person_name" class="form-control" placeholder="Contact Person">
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label>Mobile</label>
													<input type="number" maxlength="10" minlength="10" name="mobile" [(ngModel)]="newAddress.mobile" class="form-control" placeholder="Your Mobile">
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group">
													<label>Address Line 1</label>
													<input type="text" name="address1" [(ngModel)]="newAddress.address1" class="form-control" placeholder="Address Line 1">
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group">
													<label>Address Line 2</label>
													<input type="text" name="address2" [(ngModel)]="newAddress.address2" class="form-control" placeholder="Address Line 2">
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label>City</label>
													<input type="text" name="city" [(ngModel)]="newAddress.city" class="form-control" placeholder="Your City">
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label>Pincode</label>
													<input type="number" name="pincode" [(ngModel)]="newAddress.pincode" class="form-control" placeholder="Your Pincode">
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group mt-3">
													<label>Adress Type</label> <br>
													<input type="radio"
														id="home"
														name="type"
														[value]="1"
														class="form-control"
														[(ngModel)]="newAddress.type"
													/>
													<label for="home">Home</label>
													<span>
														<input type="radio"
															id="office"
															name="type"
															[value]="2"
															class="form-control"
															[(ngModel)]="newAddress.type"
														/>
														<label for="office">Office</label>
													</span>
												</div>
											</div>
											<div class="col-md-12 mt-2">
												<div class="form-group">
													<button *ngIf="isAddAddress === true" type="button" class="btn-theme me-2" (click)="closeAddress()">Close</button>
													<button (click)="saveAddress()" type="button" class="btn-theme">{{newAddress._id ? 'Update' : 'Save'}}</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="page orders" *ngIf="this.activePage === 'orders'">
							<app-order-list [orders]="order"></app-order-list>
							<!-- <div class="title">
								<h1>
									My Orders
								</h1>
							</div>
							<ng-container *ngIf="order && order.length > 0; else thenOrderElseTemplate">
								<div class="content">
									<div class="row">
										<div class="col-md-12">
											<ng-container *ngFor="let item of order; let index=index">
												<div class="order-box">
													<p class="order-date">
														{{getOrderDate(item.createdAt)}}
													</p>
													<p class="order-title">
														{{item.order_number}}
													</p>
													<ng-container *ngFor="let product of item.items; let index1=index">
														<a [routerLink]="'/order/' + item._id + '/'  + product.selected">
															<div class="product-box">
																<img [attr.src]="httpService.imgURL + product.image" [attr.alt]="product.name"/>
																<div class="product-seperator">
																	<p class="name">
																		<a [routerLink]="'/product/' + httpService.compressSLUG(product.slug)">{{product.name}}</a>
																	</p>
																	<p class="qty">Qty: {{product.qty}}</p>
																	<p class="price" *ngIf="product.offer_price; else normalPrice;">Price: {{product.offer_price}}</p>
																	<ng-template #normalPrice>
																		<p class="price">Price: {{product.price}}</p>
																	</ng-template>

																	<p class="total">Total: {{product.total}}</p>
																	<p class="status">
																		<span *ngIf="item.status === 'Placed'" class="badge badge-primary bg-primary">{{item.status}}</span>
																		<span *ngIf="item.status === 'Confirmed'" class="badge badge-info bg-info">{{item.status}}</span>
																		<span *ngIf="item.status === 'Cancelled'" class="badge badge-danger bg-danger">{{item.status}}</span>
																		<span *ngIf="item.status === 'Rejected'" class="badge badge-danger bg-danger">{{item.status}}</span>
																		<span *ngIf="item.status === 'Returned'" class="badge badge-dark bg-dark">{{item.status}}</span>

																		<span *ngIf="item.status === 'Shipped'" class="badge badge-warning bg-warning">{{item.status}}</span>
																		<span *ngIf="item.status === 'Dispatched'" class="badge badge-info bg-info">{{item.status}}</span>
																		<span *ngIf="item.status === 'Delivered'" class="badge badge-success bg-success">{{item.status}}</span>
																		<button *ngIf="item.status === 'Delivered' && isValidReturn(item, product) === true">Return Order</button>
																	</p>
																</div>
															</div>
														</a>
													</ng-container>
												</div>
											</ng-container>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-template #thenOrderElseTemplate>
								<div class="content">
									<div class="row">
										<div class="col-md-12">
											<h1>No Orders Found</h1>
										</div>
									</div>
								</div>
							</ng-template> -->
						</div>
						<div class="page review" *ngIf="this.activePage === 'review'">
							<div class="title">
								<h1>
									My Reviews
								</h1>
							</div>
							<ng-container *ngIf="review && review.length > 0; else thenReviewElseTemplate">
								<div class="content">
									<div class="row">
										<div class="col-md-12">
											<div class="review_scroll">
												<ng-container *ngFor="let data of review; let index=index">
													<div class="_1I6kUh review-item">
														<div class="_2s5KOn">
															<div class="_3BTv9X" style="height: 70px; width: 70px;">
																<img class="_1Nyybr _30XEf0" [attr.alt]="data.name" [attr.src]="httpService.imgURL + data.product_image">
															</div>
														</div>
														<div class="col _2ZRGw2">
															<div class="_2jQViS">{{data.name}}</div>
															<div class="_2sq4-A">
																<div class="row">
																	<div class="hGSR34" [ngClass]="getRatingClass(data)">
																		<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTYuNSA5LjQzOWwtMy42NzQgMi4yMy45NC00LjI2LTMuMjEtMi44ODMgNC4yNTQtLjQwNEw2LjUuMTEybDEuNjkgNC4wMSA0LjI1NC40MDQtMy4yMSAyLjg4Mi45NCA0LjI2eiIvPjwvc3ZnPg==" class="_2lQ_WZ">
																		{{data.rating}}
																	</div>
																	<p class="_3yYOd2">{{data.title}}</p>
																</div>
																<div class="row">
																	<div class="_1JIVPz">
																		<div>{{data.details}}</div>
																		<span class="_2im5Gh">
																			<span>READ MORE</span>
																		</span>
																	</div>
																</div>
																<div class="row _3WQ2CU">
																	<p class="dNmCY8">{{data.user}}</p>
																	<div class="_3u1MqM">
																		<img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAnIGhlaWdodD0nMjAnIHZpZXdCb3g9JzAgMCAyMCAyMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KCTxnPgoJCTxwYXRoIGQ9J00tMi0yaDI0djI0SC0yeicgZmlsbD0nbm9uZScvPgoJCTxwYXRoIGQ9J00xMCAwQzQuNDggMCAwIDQuNDggMCAxMHM0LjQ4IDEwIDEwIDEwIDEwLTQuNDggMTAtMTBTMTUuNTIgMCAxMCAwek04IDE1bC01LTUgMS40MS0xLjQxTDggMTIuMTdsNy41OS03LjU5TDE3IDZsLTkgOXonIGZpbGw9JyMyNkJDNEUnLz4KCTwvZz4KPC9zdmc+Cg==" class="_3eQL1z">
																		<p [id]="data._id" class="_2HwDDC">
																			<span>Certified Buyer</span>
																		</p>
																	</div>
																	<p class="dNmCY8">{{data.create_date}}</p>
																</div>
															</div>
															<div class="kafrgw">
																<a
																	class="kRuXvT"
																	[routerLink]="'/product/' + data.product_slug + '/pid/' + data.product_id +'/review/' + data.attribute_id + '/order/' + data.order_id"
																	[relativeTo]="route"
																>
																	Edit
																</a>
																<span (click)="deleteReview(data, index)" class="dSL8Hv">Delete</span>
															</div>
														</div>
													</div>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-template #thenReviewElseTemplate>
								<div class="content">
									<div class="row">
										<div class="col-md-12">
											<h1>No Review Given!</h1>
										</div>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>



<app-breadcrumb></app-breadcrumb>
<section class="forgot-page">
	<div class="container">
		<div class="row text-center text-lg-start justify-content-center">
			<div class="col-xl-6 col-lg-7 col-md-10 col-sm-12 mt-30 mt-lg-0">
				<div class="card-box">
					<div class="section-title mb-25">
						<h2 class="sec-title1">Forgot Password</h2>
						<p class="fs-md mt-4 pt-1">
							Due to the assistance of our experienced and capable team members, we are engaged in providing excellent quality products in limited time frame. Reasons which have turned up to be the most preference of our valued patrons include:
						</p>
					</div>
					<form method="POST" class="forgot-form">
						<div class="row g-4">
							<div class="col-lg-12 form-group mb-0">
								<input type="text" name="username" [(ngModel)]="username" class="form-control" placeholder="Your Mobile/Email">
							</div>
							<div class="col-12 form-group mb-0">
								<button (click)="submitForgot($event)" type="button" class="btn-theme">Reset Password</button>
								<a class="login-hyper" routerLink="/login">Login</a>
							</div>
						</div>
					</form>
					<p class="form-messages mt-20 mb-0"></p>
				</div>
			</div>
		</div>
	</div>
</section>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	HomeComponent,
	SearchComponent,
	AboutUsComponent,
	ContactUsComponent,
	ProductComponent,
	ProductDetailComponent,
	PrivacyPolicyComponent,
	TermsConditionsComponent,
	LoginComponent,
	ForgotPasswordComponent,
	MyProfileComponent,
	OrderSuccessComponent,
	CartComponent,
	ProductReviewDetailsComponent,
	WriteProductReviewComponent,
	FaqComponent,
	InfrastructureComponent,
	CertificatesComponent,
	ReturnPolicyComponent,
	RegisterComponent,
	OrderListComponent,
	OrderDetailsComponent,
	NewCartComponent,
} from './components';
import {
	BreadcrumbComponent,
	FooterComponent,
	HeaderComponent,
} from './common';
import { Error404Component, MainComponent } from './container';

import { AuthGuard, ModifyMetaGuard } from './guard';
import {
	HashLocationStrategy,
	LocationStrategy,
	PathLocationStrategy,
} from '@angular/common';
const routes: Routes = [
	{
		path: '',
		redirectTo: '/',
		pathMatch: 'full',
		resolve: [ModifyMetaGuard],
	},
	// full container
	{
		path: '',
		component: MainComponent,
		children: [
			{
				path: '',
				component: HomeComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Home',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/',
					ogTitle: 'Maas Choice Fruits & Nuts Store | Home',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | Home',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/',
						image: 'http://maaschoice.in/assets/images/assets/img/LOGO-240x64.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'contact-us',
				component: ContactUsComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Contact Us',
					subject:
						'Contact Octoweb Infotech company for Website Development, Mobile Application Development, Database Management, UI UX Design',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india, get in touch',
					descrption:
						'Octoweb Infotech is a software development company offering Website Development, Mobile Application Development, Database Management, UI UX Design, etc.',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/contact-us',
					ogTitle: 'Maas Choice Fruits & Nuts Store | Contact Us',
					ogDescription:
						'Octoweb Infotech is a software development company offering Website Development, Mobile Application Development, Database Management, UI UX Design, etc.',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | Contact Us',
					twDescription:
						'Octoweb Infotech is a software development company offering Website Development, Mobile Application Development, Database Management, UI UX Design, etc.',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/contact-us',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/contact-us',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'about-us',
				component: AboutUsComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | About Us',
					subject:
						'Octoweb Infotech is a software development company in India offering Website Development, Mobile Application Development, Database Management',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india',
					descrption:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/about-us',
					ogTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					ogDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					twDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/about-us',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/about-us',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'faq',
				component: FaqComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | FAQ',
					subject:
						'Octoweb Infotech is a software development company in India offering Website Development, Mobile Application Development, Database Management',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india',
					descrption:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/faq',
					ogTitle: 'Maas Choice Fruits & Nuts Store | FAQ',
					ogDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | FAQ',
					twDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/faq',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/faq',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'infrastructure',
				component: InfrastructureComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | FAQ',
					subject:
						'Octoweb Infotech is a software development company in India offering Website Development, Mobile Application Development, Database Management',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india',
					descrption:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/faq',
					ogTitle: 'Maas Choice Fruits & Nuts Store | FAQ',
					ogDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | FAQ',
					twDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/faq',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/faq',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'certificates',
				component: CertificatesComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | FAQ',
					subject:
						'Octoweb Infotech is a software development company in India offering Website Development, Mobile Application Development, Database Management',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india',
					descrption:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/faq',
					ogTitle: 'Maas Choice Fruits & Nuts Store | FAQ',
					ogDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | FAQ',
					twDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/faq',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/faq',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'privacy-policy',
				component: PrivacyPolicyComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | About Us',
					subject:
						'Octoweb Infotech is a software development company in India offering Website Development, Mobile Application Development, Database Management',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india',
					descrption:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/about-us',
					ogTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					ogDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					twDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/about-us',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/about-us',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'return-policy',
				component: ReturnPolicyComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | About Us',
					subject:
						'Octoweb Infotech is a software development company in India offering Website Development, Mobile Application Development, Database Management',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india',
					descrption:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/about-us',
					ogTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					ogDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					twDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/about-us',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/about-us',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'terms-conditions',
				component: TermsConditionsComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | About Us',
					subject:
						'Octoweb Infotech is a software development company in India offering Website Development, Mobile Application Development, Database Management',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india',
					descrption:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/about-us',
					ogTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					ogDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					twDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/about-us',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/about-us',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			// product
			{
				path: 'search/:string',
				component: SearchComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | About Us',
					subject:
						'Octoweb Infotech is a software development company in India offering Website Development, Mobile Application Development, Database Management',
					keywords:
						'cctoweb infotech india, website development company, software development company, mobile application development company, ui ux design company, database management company, software development company in india',
					descrption:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/about-us',
					ogTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					ogDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | About Us',
					twDescription:
						'Octoweb Infotech is a software development company in India, offers Website Development, Mobile Application Development, Database management, UI UX Design',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/about-us',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/about-us',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'product',
				component: ProductComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Product',
					subject:
						'Octoweb Infotech company achievements through UI UX Design, Website and Mobile Application Development product',
					keywords:
						'website and mobile application development product, website development company in india, mobile application development, ui ux design, software development company in india, cctoweb infotech, application development product, website development',
					descrption:
						'Octoweb Infotech company achievements journey through an amazing UI UX Design, Website and Mobile Application Development product',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/product',
					ogTitle: 'Maas Choice Fruits & Nuts Store | Product',
					ogDescription:
						'Octoweb Infotech company achievements journey through an amazing UI UX Design, Website and Mobile Application Development product',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | Product',
					twDescription:
						'Octoweb Infotech company achievements journey through an amazing UI UX Design, Website and Mobile Application Development product',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/product',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/product',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'product/?:params',
				component: ProductComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Product',
					subject:
						'Octoweb Infotech company achievements through UI UX Design, Website and Mobile Application Development product',
					keywords:
						'website and mobile application development product, website development company in india, mobile application development, ui ux design, software development company in india, cctoweb infotech, application development product, website development',
					descrption:
						'Octoweb Infotech company achievements journey through an amazing UI UX Design, Website and Mobile Application Development product',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/product',
					ogTitle: 'Maas Choice Fruits & Nuts Store | Product',
					ogDescription:
						'Octoweb Infotech company achievements journey through an amazing UI UX Design, Website and Mobile Application Development product',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | Product',
					twDescription:
						'Octoweb Infotech company achievements journey through an amazing UI UX Design, Website and Mobile Application Development product',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/product',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/product',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'product/:slug',
				component: ProductDetailComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					_id: '',
					title: '',
					subject: '',
					keywords: '',
					descrption: '',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/product/:slug',
					ogTitle: '',
					ogDescription: '',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: '',
					twDescription: '',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/product/:slug',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/product/:slug',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				//'/product/:slug/review/:selected/order/:order_id
				path: 'product/:slug/pid/:product_id/review/:selected/order/:order_id',
				component: WriteProductReviewComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					_id: '',
					title: '',
					subject: '',
					keywords: '',
					descrption: '',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/product/:slug',
					ogTitle: '',
					ogDescription: '',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: '',
					twDescription: '',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/product/:slug',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/product/:slug',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'product/:slug/review/:_id',
				component: ProductReviewDetailsComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					_id: '',
					title: '',
					subject: '',
					keywords: '',
					descrption: '',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/product/:slug',
					ogTitle: '',
					ogDescription: '',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: '',
					twDescription: '',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/product/:slug',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/product/:slug',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},

			// user
			{
				path: 'login',
				component: LoginComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Login',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/login',
					ogTitle: 'Maas Choice Fruits & Nuts Store | Login',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | Login',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/login',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/login',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'register',
				component: RegisterComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Login',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/login',
					ogTitle: 'Maas Choice Fruits & Nuts Store | Login',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle: 'Maas Choice Fruits & Nuts Store | Login',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/login',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/login',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Forgot Password',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/forgot-password',
					ogTitle:
						'Maas Choice Fruits & Nuts Store | Forgot Password',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle:
						'Maas Choice Fruits & Nuts Store | Forgot Password',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/forgot-password',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/forgot-password',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'cart',
				component: NewCartComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Change Password',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/change-password',
					ogTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/change-password',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/change-password',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'order-detail/:_id/:attribute',
				component: OrderDetailsComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Change Password',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/change-password',
					ogTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/change-password',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/change-password',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'order-success',
				component: OrderSuccessComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Change Password',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/change-password',
					ogTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/change-password',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/change-password',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},
			{
				path: 'profile/:slug',
				component: MyProfileComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Change Password',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/change-password',
					ogTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/change-password',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/change-password',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},

			{
				path: 'order-list',
				component: OrderListComponent,
				canActivate: [ModifyMetaGuard],
				data: {
					title: 'Maas Choice Fruits & Nuts Store | Change Password',
					subject: 'Maas Choice Fruits & Nuts Store',
					keywords: 'Maas Choice Fruits & Nuts Store',
					descrption: 'Maas Choice Fruits & Nuts Store',
					robots: 'index,follow',
					ogUrl: 'http://maaschoice.in/change-password',
					ogTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					ogDescription: 'Maas Choice Fruits & Nuts Store',
					ogImage:
						'http://maaschoice.in/assets/icons/app-icon-128x128.png',
					twTitle:
						'Maas Choice Fruits & Nuts Store | Change Password',
					twDescription: 'Maas Choice Fruits & Nuts Store',
					schema: {
						'@context': 'http://schema.org',
						'@id': 'http://maaschoice.in/change-password',
						'@type': 'LocalBusiness',
						name: 'Octoweb Infotech',
						url: 'http://maaschoice.in/change-password',
						image: 'http://maaschoice.in/assets/icons/app-icon-128x128.png',
						telephone: '+91 932 707 2722',
						address: {
							'@type': 'PostalAddress',
							streetAddress:
								'55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD',
							addressLocality: 'Rajkot',
							postalCode: '360005',
							addressCountry: 'India',
							addressRegion: 'Gujarat',
						},
						priceRange: '10000-10000000',
						sameAs: [
							'http://www.facebook.com/maas-choice/',
							'http://www.linkedin.com/company/maas-choice/',
							'http://www.twitter.com/maas-choice',
						],
						openingHoursSpecification: [
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: [
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
								],
								opens: '09:00',
								closes: '19:00',
							},
							{
								'@type': 'OpeningHoursSpecification',
								dayOfWeek: ['Saturday'],
								opens: '09:00',
								closes: '13:00',
							},
						],
					},
				},
			},

			// 404
			{
				path: 'not-found',
				pathMatch: 'full',
				component: Error404Component,
				canActivate: [ModifyMetaGuard],
			},
		],
		canActivate: [ModifyMetaGuard],
	},
	// misc routes
	{ path: '**', redirectTo: '/not-found' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			scrollPositionRestoration: 'enabled',
			enableTracing: false,
		}),
	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy,
		},
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

<section class="product-detail space-top space-md-bottom" *ngIf="product._id !== undefined && images.length > 0; else noData;">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-lg-7 col-xl-7">
				<div class="slider-section" *ngIf="images && images.length > 0">
					<div class="thumb-slider">
						<ng-container *ngFor="let item of images; let i=index;">
							<div class="thumb" [ngClass]="activeSlide === i ? 'active' : ''" (click)="activeSlide = i; thumbSlideChanged(i);">
								<img [attr.src]="httpService.generateImagePreview(item.image)" [attr.alt]="product.name" />
							</div>
						</ng-container>
					</div>
					<div class="main-slider">
						<owl-carousel-o [options]="mainSliderConfig" #mainCarousel (changed)="mainSlideChanged($event)">
							<ng-container *ngFor="let item of images; let key=index;">
								<ng-template carouselSlide id="{{'slide_no_'+key}}">
									<div class="large">
										<img [attr.src]="httpService.generateImagePreview(item.image)" [attr.alt]="product.name" />
									</div>

								</ng-template>
							</ng-container>
						</owl-carousel-o>
						<div class="row">
							<div class="col-md-12">
								<div class="service-section">
									<div class="icon">
										<!-- <img src="assets/img/product/service-icon-1.png"/>
										<p>15 days returnable</p> -->
										<video src="assets/video/Vid1.mp4" [muted]="true" [autoplay]="true" [loop]="true" #vid1 (canplay)="vid1.play()" webkit-playsinline playsinline></video>
									</div>
									<div class="icon">
										<!-- <img src="assets/img/product/service-icon-2.png"/>
										<p>15 days returnable</p> -->
										<video src="assets/video/Vid2.mp4" [muted]="true" [autoplay]="true" [loop]="true" #vid2 (canplay)="vid2.play()" webkit-playsinline playsinline></video>
									</div>
									<div class="icon">
										<!-- <img src="assets/img/product/service-icon-3.png"/>
										<p>15 days returnable</p> -->
										<video src="assets/video/Vid3.mp4" [muted]="true" [autoplay]="true" [loop]="true" #vid3 (canplay)="vid3.play()" webkit-playsinline playsinline></video>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12 col-lg-5 col-xl-5">
				<div class="detail-section">
					<div class="product-title">
						<h4>{{product.name}}</h4>
						<div class="service-icons">
							<!-- <div class="iso-tagmark">
								<span><span></span></span>
							</div> -->
							<!-- <div class="size-guide" (click)="showSizeGuides($event)" #parent>
								Size Guide
								<img src="assets/img/product/size-chart.png"/>
								<app-modal [show]="sizeGuideShow" *ngIf="sizeGuideShow"></app-modal>
							</div> -->
						</div>
					</div>
					<div class="product-price">
						<div class="mrp" *ngIf="product.offer_price && product.offer_price > 0">M.R.P. : <del>₹{{product.price}}</del></div>
						<div class="offer-price">Outlet Price: ₹{{product.offer_price && product.offer_price > 0 ? product.offer_price : product.price}}</div>
						<div class="save"  *ngIf="product.offer_price && product.offer_price > 0">You Save: ₹{{product.price - product.offer_price}}</div>
					</div>
					<!-- <div class="ratings">
						<ng-container *ngFor="let n of ratings; let ri=index">
							<i class="fas fa-star themeColor" *ngIf=" n <= product.average_ratings; else noFillStar"></i>
							<ng-template #noFillStar>
								<i class="far fa-star"></i>
							</ng-template>
						</ng-container>
						<span *ngIf="product.total_ratings > 0">
							({{product.total_ratings}})
						</span>
					</div> -->
					<div class="variant">
						<ul class="variant-list">
							<ng-container *ngFor="let item of attributes; let i=index">
								<div class="variant-list-container">
								<li class="variant-item" [ngClass]="{'active': item._id === currentAttribute._id}" (click)="packageChnage(item._id)" >
									{{getPackageName(item)}}
								</li>
								<span class="red-color"*ngIf="!(item.stock > 5)&&(item.stock!==0)" > {{!(item.stock > 5)?(item.stock!==0)?"Left "+item.stock:"&nbsp;": "&nbsp;"}}</span>
								<!-- <div>
									<span class="red-color"*ngIf="!(item.stock > 5)&&(item.stock==0)" > {{!(item.stock > 5)?(item.stock==0)?"Left "+item.stock:"&nbsp;": "&nbsp;"}}</span>
								</div> -->
								</div>
							</ng-container>
						</ul>
					</div>
					<div class="cart">
						<div class="quantity">
							<!-- <button class="quantity-minus qut-btn" (click)="currentAttribute.stock>qty && qty = qty > 1 ? qty - 1 : qty;"> -->
							<button class="quantity-minus qut-btn" (click)="qty = qty > 1 ? qty - 1 : qty;">
								<span>-</span>
							</button>
							<input type="number" class="qty-input" min="1" max="10" [(ngModel)]="qty" [value]="qty">
							<!-- <button class="quantity-plus qut-btn" (click)="(qty = qty + 1)"> -->
							<button class="quantity-plus qut-btn" (click)="(currentAttribute.stock > qty && qty = qty + 1)">
								<span>+</span>
							</button>
						</div>
						<div class="actions">
							<a href="javascript:void(0);" (click)="addToCart(product, 0)" class="add-to-cart-btn btn-theme">Add To Cart</a>
							<a href="javascript:void(0);" (click)="addToCart(product, 1)" class="buy-now-btn btn-theme">Buy Now</a>
						</div>
						<span class="red-color">{{!(currentAttribute.stock > qty) ? "Out of Stock" : ""}}</span>
						<div class="free-shipping">
							<p><span>Free Delivery</span>: on orders above ₹499 shipped by Schlokas Agro Pvt. Ltd.</p>
						</div>
					</div>
				</div>
				<div class="accordian-section text-justify">
					<div id="accordion">
						<div class="card">
							<div class="card-header" id="description">
								<a (click)="currentAccordin = (currentAccordin == 1 ? 0 : 1);" href="javascript:void(0);" class="btn collapsed" data-toggle="collapse" data-target="#description" aria-expanded="true" aria-controls="description">
									Description
									<span class="fas" [ngClass]="currentAccordin == 1 ? 'fa-times' : 'fa-plus'"></span>
								</a>
							</div>
							<div id="description" class="collapse" aria-labelledby="Description" data-parent="#accordion" [ngClass]="{'show': currentAccordin === 1}">
								<div class="card-body">
									<div class="html-preview" [innerHTML]="product.description"></div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="ingredients">
								<a (click)="currentAccordin = (currentAccordin == 2 ? 0 : 2);" href="javascript:void(0);" class="btn collapsed" data-toggle="collapse" data-target="#ingredients" aria-expanded="false" aria-controls="ingredients">
									Ingredients
									<span class="fas" [ngClass]="currentAccordin == 2 ? 'fa-times' : 'fa-plus'"></span>
								</a>
							</div>
							<div id="ingredients" class="collapse" aria-labelledby="Ingredients" data-parent="#accordion" [ngClass]="{'show': currentAccordin === 2}">
								<div class="card-body">
									<div class="html-preview" [innerHTML]="product.ingredients"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear clearfix"></div>
	</div>
</section>
<div class="post-slide-container">
	<div class="top"></div>
	<div class="bottom"></div>
</div>
<ng-template #noData>
	<section class="product-detail space-top space-md-bottom">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="detail-section">
						<div class="product-title">
							<h4>No Product Details Found</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</ng-template>
<section >
	<div class="related-section">
		<div class="heading">
			<!-- <h4 class="title">
				Related Products
			</h4> -->
			<div class="text-center buy-bulk">
				<h1>RELATED PRODUCTS</h1>
			</div>
		</div>
		<div class="content">
			<div class="list-product text-center">
				<div class="product-list-items" *ngIf="product && product.similar && product.similar.length > 0; else noSimilar;">
					<section class="content__product-grid">
						<div class="grid-container">
							<div class="row text-center product-grid-list">
								<ng-container *ngFor="let item of product.similar; let i=index">
									<div class="product__preview-grid-item col-sm-12 col-md-6 col-lg-3" [ngClass]="'color'+(i+1)">
										<div class="inner product-6306979348665">
											<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)" class="product-card__title">
												<div class="icon">
													<img class=" lazyloaded" data-src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140" alt="" src="https://images.accentuate.io/?image=https%3A%2F%2Fcdn.accentuate.io%2F6306979348665%2F1613435986680%2FIcon-Grapefruit-Rosemary-Pink.png%3Fv%3D0&amp;c_options=h_140">
												</div>
												<h1 class="h3 product-title">{{item.name}}</h1>
												<div class="description show-for-large">
													<p class="small">{{item.category_name}}</p>
												</div>
											</a>
											<a class="product-card__image aos-init aos-animate" [routerLink]="'/product/' + httpService.compressSLUG(item.slug)" data-aos="">
												<div class="responsive-image__wrapper">
													<img class="lazyautosizes lazyloaded" [attr.data-src]="httpService.generateImagePreview(item.image)" [alt]="item.name" [attr.src]="httpService.generateImagePreview(item.image)">
												</div>
											</a>
											<!-- <div class="product-card__add">
												<ng-container *ngIf="item.attributes && item.attributes.length > 0">
													<div class="product__variant-wrapper">
														<ul class="product__radio">
															<ng-container *ngFor="let attr of item.attributes; let j=index">
																<li class="radio_6306979348665">
																	<input type="radio" [attr.id]="attr._id" [attr.name]="attr._id" [attr.value]="attr._id" [attr.data-price]="getPrice(attr)" [checked]="getChecked(attr)" (click)="changeAttribute(i, attr)">
																	<label [attr.for]="attr._id"  (click)="changeAttribute(i, attr)">
																		{{getPackageName(attr)}}
																	</label>
																</li>
															</ng-container>
														</ul>
													</div>
												</ng-container>
											</div> -->
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</section>
				</div>
				<ng-template #noSimilar>
					<div class="product-list-items">
						<h3 class="no-items">No Products Available</h3>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</section>

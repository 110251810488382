import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import 'src/assets/js/jquery-ui.min.js';
import { ToastService, HttpService, EmiterService } from 'src/app/services';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	RouterEvent,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { Store } from '@ngrx/store';
import { SET_CART, Auth } from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss'],
	providers: [ToastService],
})
export class ProductComponent implements OnInit {
	math = Math;
	public hasPage: boolean = true;
	public category: any = [];
	public category_id = '';
	public activeCategory = 'All Item';
	public products: any = [];
	public ratings = Array(5)
		.fill(0)
		.map((x, i) => i + 1);
	public result = '';
	public resultBottom = '';
	public perPage = 6;
	public totalRecords = 0;
	public pageNo = 1;
	public pageCalculated = false;
	public subscription!: Subscription;
	public user!: any;
	// new UI
	public activeAttribute: any = {};
	public cart: any = [];
	public session: any;

	constructor(
		private cd: ChangeDetectorRef,
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
		private store: Store<reducers.AppState>
	) {
		this.session = this.httpService.getSession();
		this.store.subscribe((data: any) => {
			this.cart =
				data.main &&
				data.main.cart != undefined &&
				Array.isArray(data.main.cart)
					? data.main.cart
					: [];
			this.user =
				data.main && data.main.auth != undefined && data.main.auth._id
					? data.main.auth
					: Auth;
			this.user = this.httpService.auth;
			this.session =
				data.main && data.main.session != undefined && data.main.session
					? data.main.session
					: '';
			this.session = this.httpService.getSession();
		});
		this.category_id = '';
		this.router.events.subscribe((event: any) => {
			const query: any = this.route.snapshot.queryParams;
			if (event instanceof NavigationStart) {
				if (query.category) {
					this.category_id = query.category;
				}
			}
			if (event instanceof NavigationEnd) {
				this.category_id = '';
				if (query.category) {
					this.category_id = query.category;
				}
				this.listProduct(this.pageNo);
			}
		});
	}

	ngOnInit() {
		this.user = this.httpService.auth;
		this.user = this.httpService.getAuth();
		if (this.route.snapshot.params) {
			const query: any = this.route.snapshot.queryParams;
			if (query.category) {
				this.category_id = query.category;
			}
		}
		this.emitter.emit('isloading', {
			type: 'isloading',
			data: true,
			autoClose: false,
		});
		this.getAllCategory(this.category_id);
	}

	categoryChange(category_id: any) {
		this.category_id = category_id;
		this.products = [];
		this.listProduct(this.pageNo);
	}

	changePg(pg: any) {
		this.pageNo = pg;
		this.listProduct(this.pageNo);
	}

	getAllCategory(category_id: any) {
		let params = {
			category_id: category_id ? category_id : this.category_id,
		};
		this.http
			.post(this.httpService.mainUrl + 'getMenuCategory', params)
			.subscribe((result: any) => {
				this.emitter.emit('isloading', {
					type: 'isloading',
					data: false,
				});
				if (result.status == 1) {
					this.category =
						result.data && Array.isArray(result.data)
							? result.data
							: [];
				} else {
					this.category = [];
				}
			});
	}

	loadMore() {
		this.pageNo++;
		this.listProduct(this.pageNo);
	}

	listProduct(pageNo: number) {
		this.emitter.emit('isloading', {
			type: 'isloading',
			data: true,
			autoClose: false,
		});
		let user = this.httpService.auth;
		let params = {
			category: this.category_id,
			items_per_page: this.perPage,
			page_no: pageNo,
			loginUser: user && user._id ? user._id : '',
		};
		this.http
			.post(this.httpService.mainUrl + 'getAllProduct', params)
			.subscribe((result: any) => {
				this.emitter.emit('isloading', {
					type: 'isloading',
					data: false,
				});
				if (result.status == 1) {
					const data = result.data;
					if (data.length < this.perPage) {
						this.hasPage = false;
						this.pageNo =
							this.pageNo > 0 ? this.pageNo-- : this.pageNo;
					}
					data.map((item: any) => this.products.push(item));
					if (this.products.length > 0) {
						this.activeAttribute = this.products[0].attributes[0];
						this.result = `1 to ${this.products.length}`;
						this.resultBottom = `SHOWING 1 OF ${this.products.length} PRODUCTS`;
					}
					this.pageCalculated = true;
					this.cd.detectChanges();
				} else {
					this.products = [];
					this.notify.generateToast(
						'error',
						'Error!',
						result.message
					);
				}
			});
	}

	addItemToCart(item: any, type: number) {
		let attribute = this.activeAttribute;
		const attributes = item.attributes;
		let index = attributes.findIndex(
			(item: any) => item._id === attribute._id
		);
		if (index === -1) {
			attribute = attributes[0];
		}
		if (parseInt(attribute.stock) !== 0) {
			this.addToCart(item, 0, type);
		} else {
			this.notify.generateToast(
				'warning',
				'Error!',
				'This Product is out of Stock'
			);
		}
	}

	addToCart(product: any, action: any, type: number) {
		let currentAttribute = this.activeAttribute;
		const attributes = product.attributes;
		let index = attributes.findIndex(
			(item: any) => item._id === currentAttribute._id
		);
		if (index === -1) {
			currentAttribute = attributes[0];
		}
		const cart: any = JSON.parse(JSON.stringify(this.cart.valueOf()));
		const item: any = JSON.parse(JSON.stringify({ ...product.valueOf() }));
		delete item.similar;
		delete item.reviews;
		delete item.review_images;
		delete item.images;
		delete item.attributes;
		delete item.smallest;
		delete item.largest;
		item.qty = 1;
		item.total = 0;
		item.product_id = item._id;
		item.offer = currentAttribute.offer;
		item.price = parseInt(currentAttribute.package_price);
		item.selected = currentAttribute._id;
		item.selected_attribute = currentAttribute;
		item.return_status = false;
		item.return_reason = '';
		item.current_stock = parseInt(currentAttribute.stock);
		item.last_stock = parseInt(currentAttribute.stock);
		item.stock = parseInt(currentAttribute.stock);
		if (item.offer) {
			item.offer.amount = item.offer._id
				? parseFloat(item.offer.amount)
				: 0;
		}
		if (parseInt(item.offer_price)) {
			item.total = 1 * parseInt(item.offer_price);
		} else {
			item.total = 1 * parseInt(item.price);
		}
		let ind = cart.findIndex(
			(cartItem: any) =>
				cartItem._id == item._id && cartItem.selected === item.selected
		);
		if (ind > -1) {
			cart[ind] = item;
		} else {
			cart.push(item);
		}
		this.cart = [...JSON.parse(JSON.stringify(cart.valueOf()))];

		if (this.user && this.user._id) {
			this.store.dispatch(SET_CART({ cart: this.cart }));
			this.addToUserList(1, this.cart);
			this.emitter.emit('update_cart', { updateCart: true });
			this.emitter.emit('cart_change', true, false, false);
		} else {
			if (this.session) {
				this.store.dispatch(SET_CART({ cart: this.cart }));
				this.emitter.emit('update_cart', { updateCart: true });
				this.emitter.emit('cart_change', true, false, false);
				// this.notify.generateToast('success', "Item Added!", "Your Item Added To Cart Successfully!");
			} else {
				// this.notify.generateToast('warning', 'Warning!', "Please Login First, To Add Items In Your Cart");
			}
		}
		if (action && action == 1) {
			this.router.navigate(['/cart']);
		}
	}

	addToUserList(type: number, items: any) {
		let user = this.httpService.auth;
		let params = {
			user_id: user && user._id ? user._id : '',
			items: items,
			type: type,
		};
		this.http
			.post(this.httpService.mainUrl + 'addToUserList', params)
			.subscribe((result: any) => {
				if (result.status == 1) {
					let action = 'Removed From';
					if (items.length > 0) {
						action = 'Added To';
					}
					let msg =
						type == 1
							? `Item ${action} Cart Successfully!`
							: `Item ${action} Wishlist Successfully!`;
					// this.notify.generateToast('success', 'Success!', msg);
				} else {
					// this.notify.generateToast('error', 'Error!', result.message);
				}
			});
	}

	getPackageName(attribute: any) {
		let packageType = attribute?.package_type?.toUpperCase();
		if (packageType === 'GM') {
			packageType = 'g';
		}
		if (packageType === 'KG') {
			packageType = 'Kg';
		}
		if (packageType === 'LTR') {
			packageType = 'L';
		}
		let name = `${attribute.package_value}${packageType}`;
		return name;
	}

	getPrice(attribute: any) {
		let price: any = '0';
		if (attribute.offer && parseInt(attribute.offer.amount)) {
			if (attribute.offer.type == false) {
				price = attribute.package_price - attribute.offer.amount;
			} else {
				price =
					parseInt(attribute.package_price) -
					(parseInt(attribute.offer.amount) *
						parseInt(attribute.package_price)) /
						100;
			}
		}
		return price;
	}

	getChecked(attribute: any) {
		let isChecked: boolean = false;
		isChecked = this.activeAttribute?._id === attribute._id || false;
		return isChecked;
	}

	changeAttribute(itemIndex: any, attribute: any) {
		let price: any = '0';
		if (attribute.offer && parseInt(attribute.offer.amount)) {
			if (attribute.offer.type == false) {
				price = attribute.package_price - attribute.offer.amount;
			} else {
				price =
					parseInt(attribute.package_price) -
					(parseInt(attribute.offer.amount) *
						parseInt(attribute.package_price)) /
						100;
			}
		}
		this.activeAttribute = attribute;
		this.products[itemIndex].price = attribute.package_price;
		this.products[itemIndex].offer_price = price;
		this.products[itemIndex].offer_amount =
			attribute.offer && attribute.offer.amount
				? attribute.offer.amount
				: 0;
		this.products[itemIndex].offer_type =
			attribute.offer && attribute.offer.type
				? attribute.offer.type
				: null;
		this.products[itemIndex].offer = attribute.offer
			? attribute.offer
			: null;
		this.cd.detectChanges();
	}

	randomIntFromInterval(min: number, max: number) {
		// min and max included
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	getColor(index: number) {
		const colors: any = {
			1: '#5da205',
			2: '#f78888',
			3: '#ececec',
			4: '#90ccf4',
			5: '#ececec',
			6: '#f3d250',
			7: '#f78888',
			8: '#f3d250',
			9: '#ececec',
			10: '#5da205',
			11: '#f78888',
			12: '#ececec',
		};
		var color1 = '#5da205';
		var color2 = '#f78888';
		var color3 = '#ececec';
		var color4 = '#90ccf4';
		var color5 = '#ececec';
		var colorb1 = '#f78888';
		var colorb2 = '#f3d250';
		var colorb3 = '#ececec';
		let colorSelected = colors[index + 1];
		let style = { backgroundColor: `transparentize(${colorSelected}, .3)` };
		return style;
		// return `color${this.randomIntFromInterval(1, index)}`;
	}

	getBGColor(index: number) {
		const colors: any = {
			1: '#5da205',
			2: '#f78888',
			3: '#ececec',
			4: '#90ccf4',
			5: '#ececec',
			6: '#f3d250',
			7: '#f78888',
			8: '#f3d250',
			9: '#ececec',
			10: '#5da205',
			11: '#f78888',
			12: '#ececec',
		};
		var color1 = '#5da205';
		var color2 = '#f78888';
		var color3 = '#ececec';
		var color4 = '#90ccf4';
		var color5 = '#ececec';
		var colorb1 = '#f78888';
		var colorb2 = '#f3d250';
		var colorb3 = '#ececec';
		let colorSelected = colors[index + 1];
		let style = { backgroundColor: `transparentize(${colorSelected}, .3)` };
		return style;
	}
}

<footer>
	<div class="main-footer">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-10">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-3">
							<div class="footer-link">
								<h4>Useful Links</h4>
								<ul>
									<li><a routerLink="/" routerLinkActive="active">Home</a></li>
									<li><a routerLink="/about-us" routerLinkActive="active">About Us</a></li>
									<li><a routerLink="/contact-us" routerLinkActive="active">Contact Us</a></li>
								</ul>
							</div>
						</div>
						<div class="col-sm-6 col-md-6 col-lg-3">
							<div class="footer-link">
								<h4>Products</h4>
								<ul class="categories">
									<ng-container *ngFor="let item of category; let i=index">
										<li>
											<a
												[routerLink]="'/product'"
												[queryParams]="{'category': item._id}"
											>
												{{item.name}}
												<!-- <span>({{item.counts}})</span> -->
											</a>
										</li>
									</ng-container>
								</ul>
							</div>
						</div>
						<div class="col-sm-6 col-md-6 col-lg-3">
							<div class="footer-link">
								<h4>Help & Support</h4>
								<ul>
									<li><a routerLink="/terms-conditions" routerLinkActive="active">Terms & Conditions</a></li>
									<li><a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></li>
									<li><a routerLink="/return-policy" routerLinkActive="active">Return & Refund Policy</a></li>
									<li><a routerLink="/faq" routerLinkActive="active">FAQs</a></li>
								</ul>
							</div>
						</div>
						<div class="col-sm-6 col-md-6 col-lg-3">
							<div class="footer-link">
								<h4>Contact Us</h4>
								<ul>
									<li>
										<img src="assets/img/home/mail.svg" alt="" class="img-fluid">
										<a [attr.href]="'mailto:'+company.contact_email">{{company.contact_email}}</a>
									</li>
									<li>
										<img src="assets/img/home/call.svg" alt="" class="img-fluid">
										<a [attr.href]="'tel:'+company.contact_number">{{company.contact_number}}</a>
									</li>
									<li>
										<img src="assets/img/home/whatsapp.svg" alt="" class="img-fluid">
										<a [attr.href]="'tel:'+company.contact_number">{{company.contact_number}}</a>
									</li>
								</ul>
							</div>
							<div class="footer-social"
								*ngIf="company.facebook_link || company.twitter_link || company.linkedin_link || company.pinterest_link || company.youtube_link"
							>
								<ul>
									<li><a [attr.href]="company.facebook_link"><i class="fab fa-facebook-f"></i></a></li>
									<li><a [attr.href]="company.twitter_link"><i class="fab fa-twitter"></i></a></li>
									<li><a [attr.href]="company.linkedin_link"><i class="fab fa-linkedin-in"></i></a></li>
									<li><a [attr.href]="company.pinterest_link"><i class="fab fa-pinterest"></i></a></li>
									<li><a [attr.href]="company.youtube_link"><i class="fab fa-youtube"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-12 text-center">
					<div class="copy-right">
						<!-- <p>Maa's Choice © 2022 &nbsp;|&nbsp;  Made with by <i class="fas fa-heart"></i> Codepoets</p> -->
						<p>Maa's Choice ©️ {{year}} &nbsp;|&nbsp; A brand of Schlokas Agro Private Limited</p>
						<img (click)="router.navigate(['/'])" src="assets/img/home/logo-white.svg" alt="" class="img-fluid">
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>

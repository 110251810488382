import { HttpClient } from '@angular/common/http';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { HttpService, ToastService } from 'src/app/services';
import { GET_COMPANY, Company, SET_CART, SET_AUTH } from 'src/app/store';
import * as reducers from 'src/app/store/reducers';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	email:any = "";
	year:any = "";
	sub!:Subscription;
	company!:typeof Company;
	validURL:any = "";
	public isOpenCart:boolean = false;
	public items!:any [];
	public category:any = [];
	public cart:any = {
		items!: [],
		user_id: "",
		session_id: "",
		shipping_type: 1,
		subtotal: 0,
		total: 0,
		tax_type: 0,
		tax: 0,
		discount: 0,
		discount_type: 0,
		shipping_charge: 0,
	};
	public user:any;
	iscartLoaded: boolean = false;
	iscartCalculated: boolean = false;
	constructor(
		private http: HttpClient,
		public httpService: HttpService,
		private store: Store<reducers.AppState>,
		private notfiy: ToastService,
		private sanitizer: DomSanitizer,
		private notify: ToastService,
		public router: Router,
	) {

		this.store.subscribe((state:any) => {
			const cart = state && state.main && state.main.cart != undefined && Array.isArray(state.main.cart) ? state.main.cart : [];
			const category = state.main && state.main.category != undefined && state.main.category.length > 0 ? state.main.category : [];
			this.company = state && state.main && state.main.company != undefined && state.main.company._id != undefined ? state.main.company : Company;
			if(JSON.stringify(cart) !== JSON.stringify(this.cart)){
				this.iscartCalculated = false;
			}
			if(category){
				this.category = category;
			}
		});
	}

	ngOnInit(): void {
		this.sub = this.store.subscribe((state:any) => {
			this.company = state && state.main && state.main.company != undefined && state.main.company._id != undefined ? state.main.company : Company;
			this.user = this.httpService.getAuth();
			this.cart.items = state && state.main && state.main.cart != undefined && Array.isArray(state.main.cart) ? state.main.cart : [];
			this.items = this.cart.items;
			if(this.items && Array.isArray(this.items) && this.items.length > 0 && this.iscartCalculated === false){
				this.calculateTotal();
			}
		})
		let dt:any = new Date();
		this.year = dt.getFullYear();
		if(dt.getFullYear() !== 2021){
			this.year = `2021 - ${dt.getFullYear()}`
		}
		this.user = this.httpService.getAuth();
		if(this.user && this.user._id){
			this.getUsersDetailsById(this.user._id);
		}
	}

	getUsersDetailsById(_id:any) {
		this.iscartLoaded = true;
		this.http.post(this.httpService.mainUrl + 'getUsersDetailsById',{_id: _id}).subscribe((res:any) => {
			if(res.status == 1){
				const data = res.data;
				this.user = res.data;
				this.user = {...data.valueOf()};
				this.cart.items = data.cart && data.cart != undefined && Array.isArray(data.cart) ? data.cart : [];
				this.items = this.cart.items;
				this.store.dispatch(SET_CART({cart: data.cart}));
				if(this.items && Array.isArray(this.items) && this.items.length > 0 && this.iscartCalculated === false){
					this.calculateTotal();
				}
			}else {
				this.notify.generateToast('error', "", res.message)
			}
		})
	}

	async calculateTotal(){
		this.iscartCalculated = true;
		let subtotal = 0
		let total = 0;
		let tax = 0;
		let shipping = 0;
		let discount = 0;
		let discount_price = 0;
		let self = this;
		const items:any = JSON.parse(JSON.stringify(this.items.valueOf()));
		if(items.length > 0){
			items.map((item:any, index:any) => {
				items[index].qty = items[index].qty ? items[index].qty : 1;
				if(items[index].offer_price){
					items[index].total = 	items[index].offer_price * this.cart.items[index].qty;
				}else {
					items[index].total = 	items[index].price * this.cart.items[index].qty;
				}
				subtotal += (items[index].total ? items[index].total : 0);
			})
		}
		tax = (subtotal * (this.cart.tax_type)) / 100;
		discount = (subtotal * (this.cart.discount_type))/100;
		this.cart.tax = !isNaN(tax) ? tax : 0;
		this.cart.discount = !isNaN(discount) ? discount : 0;
		discount_price = (subtotal - discount);
		subtotal = discount_price + tax ;
		this.cart.subtotal = subtotal > 0 ? subtotal : 0.0;
		this.cart.total = (subtotal + (this.cart.shipping_charge));
		this.items = JSON.parse(JSON.stringify(items));
		this.cart.items = JSON.parse(JSON.stringify(this.items));
		// this.store.dispatch(SET_CART({cart: this.cart.items}));
		// this.cd.detectChanges();
	}

	async removeItem(data:any, index:any){
		let items:any = JSON.parse(JSON.stringify(this.items.valueOf()));
		items.splice(index, 1);
		const cart = items;
		this.cart.items = JSON.parse(JSON.stringify(items));
		this.items = JSON.parse(JSON.stringify(items));
		this.store.dispatch(SET_CART({cart: this.cart.items}));
		this.iscartCalculated = false;
		this.calculateTotal();
		// this.cd.detectChanges();
		this.notify.generateToast('danger', "Item Removed!", "Item Removed From Cart Successfully!");
		if(this.user && this.user._id){
			await this.updateUserList({type: 1, items: cart});
		}
	}

	updateUserList(data:any){
		const items = (data.items && data.items.length > 0 ? data.items : [] )
		this.http.post(this.httpService.mainUrl + 'addToUserList', {type: data.type, items: items, user_id: this.user._id}).subscribe(async (response:any) => {
			this.iscartLoaded = false;
			if(response.status != 1){
				this.notify.generateToast('warning', 'Warning!', response.message);
			}else {
				// this.store.dispatch(SET_CART({cart: items}));
				// this.cart.items = items;
				// this.items = this.cart.items;
			}
			// await this.getUsersDetailsById(this.user._id);
		});
	}

	backToTop(event:any){
		$("html, body").animate({scrollTop: 0}, 3000);
	}

	inputChange(event:any){
		this.email = event.target.value;

	}

	onSusbscribe(event:any){
		event.preventDefault();
		this.http.post(this.httpService.mainUrl + 'subscribeNewsletter', {email: this.email}, {}).subscribe((res: any) => {
			if(res.status == 1){
				this.email = "";
				this.notfiy.generateToast('success', res.message, "");
			}else {
				this.notfiy.generateToast('error', res.message, "");
			}
		})
	}

	hideSideCart(element:any){
		this.isOpenCart = !this.isOpenCart;
		//sidemenu-wrapper click
		var t = ".sidemenu-wrapper";
		var e = ".sideMenuCls";
		var n = "show";
		document.getElementsByClassName('sidemenu-wrapper')[0].classList.remove('show');
		$(".sidemenu-wrapper").removeClass('show');
		element.stopPropagation();
		// $(t + " > div").on("click", function(e) {
		// 	e.stopPropagation();
		// 	$(t).addClass(n)
		// })
		// $(e).on("click", function(e) {
		// 	e.preventDefault();
		// 	e.stopPropagation();
		// 	$(t).removeClass(n);
		// });
	}

	showSideCart(element:any){
		this.isOpenCart = !this.isOpenCart;
		//sidemenu-wrapper click
		var t = ".sidemenu-wrapper";
		var e = ".sideMenuCls";
		var n = "show";
		document.getElementsByClassName('sidemenu-wrapper')[0].classList.remove('show');
		$(".sidemenu-wrapper").removeClass('show');
		element.stopPropagation();
		// $(t).on("click", function(e) {
		// 	e.stopPropagation();
		// 	$(t).removeClass(n)
		// })
		// $(t + " > div").on("click", function(e) {
		// 	e.stopPropagation();
		// 	$(t).addClass(n)
		// })
		// $(e).on("click", function(e) {
		// 	e.preventDefault();
		// 	e.stopPropagation();
		// 	$(t).removeClass(n);
		// });
	}

	moveToCart(element:any){
		document.getElementsByClassName('sidemenu-wrapper')[0].classList.remove('show');
		$(".sidemenu-wrapper").removeClass('show');
		element.stopPropagation();
		element.preventDefault();
		this.router.navigate(['/cart']);
	}

}

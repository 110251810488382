<app-breadcrumb></app-breadcrumb>
<section>
	<div class="privacy-policy">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12 col-9">
					<div class="privacy-policy-title">
						<h1>Privacy Policy</h1>
					</div>
				</div>
				<div class="col-xl-12 col-9">
					<div class="privacy-policy-box">
						<div class="privacy-policy-content">
							<p>
								We are very delighted that you have shown interest in our company. Data protection is of a particularly high priority for the management of the Maas Choice. The use of the Internet pages of the Maas Choice is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary. If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.
								The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line. By means of this data protection declaration, Maas Choice would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.
								As the controller, the Maas Choice has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone.
							</p>
							<ul>
								<li>
									<label>
										1. Definitions
									</label>
									<p>
										Our data protection declaration should be legible and understandable for the general public, as well as our customers and business partners. To ensure this, we would like to first explain the terminology used.
										In this data protection declaration, we use, inter alia, the following terms:
											• a) Personal data
										Personal data means any information relating to an identified or identifiable natural person (“data subject”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
											• b) Data subject
										Data subject is any identified or identifiable natural person, whose personal data is processed by the controller responsible for the processing.
											• c) Processing
										Processing is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
											• d) Restriction of processing
										Restriction of processing is the marking of stored personal data with the aim of limiting their processing in the future.
											• e) Profiling
										Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person's performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.
											• f) Pseudonymisation
										Pseudonymisation is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed to an identified or identifiable natural person.
											• g) Controller or controller responsible for the processing
										Controller or controller responsible for the processing is the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.
											• h) Processor
										Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.
											• i) Recipient
										Recipient is a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing of those data by those public authorities shall be in compliance with the applicable data protection rules according to the purposes of the processing.
											• j) Third party
										Third party is a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.
											• k) Consent
										Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.
									</p>
								</li>
								<li>
									<label>
										2. Scope and origin of the data
									</label>
									<p>
										We process personal data (“data”) in accordance with the principles of data avoidance and data economy, providing this is necessary, we are permitted to do so by the applicable law, we are obliged to do so or you have provided your consent.
									</p>
									<ul>
										<li>
											<label>
												2.1 Data relating to the business relationship
											</label>
											<p>
												The customer's master data, especially company, name, language, position, industry affiliation, association memberships;
												The customer's contact data, especially current address, previous addresses, other shipping addresses, telephone numbers and email addresses;
												Contractual data such as contract start and end dates, repayment conditions;
												providing representatives or contacts are named: their master data, especially name, date of birth and tax number, as well as contact details, e.g. current address, previous addresses, other shipping addresses, telephone numbers and email addresses and interests for marketing activities;
												Master data for members of the public, e.g. current address, previous addresses, other shipping addresses, telephone numbers and email addresses;
												Income and financial circumstances in relation to self-declarations, especially proof of income, details of income, supplementary income, instalment payment obligations;
											</p>
										</li>
										<li>
											<label>
												2.2 Data relating to the business relationship
											</label>
											<p>
												We also capture data within our online services, including portals and apps, providing this is necessary to meet our contractual commitments to you or you have given your consent to this. This applies in particular to the following data:
												master data changes undertaken by you, e.g. address changes;
												time and scope of the Conditions of Use for Online Services accepted by you.
											</p>
										</li>
									</ul>
								</li>
								<li>
									<label>
										4. Disclosure of your data
									</label>
									<p>
										We only disclose your personal data to third parties if you have consented to this data disclosure, or if we are entitled or obliged to disclose data because of some other permission.
									</p>
								</li>
								<li>
									<label>
										5. Duration of data storage
									</label>
									<p>
										We process and store your personal data only for as long as necessary in the performance of contractual or legal obligations. If there is no longer any legal basis for processing your data, we shall delete the data or, where this is not possible, we shall block any personal reference to you in our systems in accordance with data protection regulations.
									</p>
								</li>
								<li>
									<label>
										6. Cookies
									</label>
									<p>
										The Internet pages of the Maas Choice use cookies. Cookies are text files that are stored in a computer system via an Internet browser.
										Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the data subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.
										Through the use of cookies, the Maas Choice can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.
										By means of a cookie, the information and offers on our website can be optimized with the user in mind. Cookies allow us, as previously mentioned, to recognize our website users. The purpose of this recognition is to make it easier for users to utilize our website. The website user that uses cookies, e.g. does not have to enter access data each time the website is accessed, because this is taken over by the website, and the cookie is thus stored on the user's computer system. Another example is the cookie of a shopping cart in an online shop. The online store remembers the articles that a customer has placed in the virtual shopping cart via a cookie.
										The data subject may, at any time, prevent the setting of cookies through our website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be entirely usable.
										Each web browser offers ways to restrict and delete cookies. Visit following websites for more information:
											• Internet Explorer: https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
											• Firefox: https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies
											• Google Chrome: https://support.google.com/chrome/answer/95647?hl=en
											• Safari: https://support.apple.com/en-us/HT201265
										This website uses cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
										Cookies are small text files that can be used by websites to make a user's experience more efficient.

										This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.

										You can at any time change or withdraw your consent from the Cookie Declaration on our website.
									</p>
								</li>
								<li>
									<label>
										Necessary
									</label>
									<p>
										Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.
									</p>
								</li>
								<li>
									<label>
										Statistics
									</label>
									<p>
										Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.
									</p>
									<table class="table table-bordered table-responsive-md">
										<thead>
											<tr>
												<th>Name</th>
												<th>Provider</th>
												<th>Purpose</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>_dc_gtm_UA-#</td>
												<td>Google</td>
												<td>Used by Google Tag Manager to control the loading of a Google Analytics script tag.</td>
											</tr>
											<tr>
												<td>_ga</td>
												<td>Google</td>
												<td>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
											</tr>
											<tr>
												<td>_gid</td>
												<td>Google</td>
												<td>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
											</tr>
											<tr>
												<td>Collect</td>
												<td>Google</td>
												<td>Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.</td>
											</tr>
										</tbody>
									</table>
								</li>
								<li>
									<label>
										Marketing
									</label>
									<p>
										Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.
									</p>
									<table class="table table-bordered table-responsive-md">
										<thead>
											<tr>
												<th>Name</th>
												<th>Provider</th>
												<th>Purpose</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>fbp</td>
												<td>Meta Platforms, Inc.</td>
												<td>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</td>
											</tr>
											<tr>
												<td>ads/ga-audiences</td>
												<td>Google</td>
												<td>Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor's online behaviour across websites.</td>
											</tr>
											<tr>
												<td>Tr</td>
												<td>Meta Platforms, Inc.</td>
												<td>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</td>
											</tr>
											<tr>
												<td>VISITOR_INFO1_LIVE</td>
												<td>YouTube</td>
												<td>Tries to estimate the users' bandwidth on pages with integrated YouTube videos.</td>
											</tr>
											<tr>
												<td>YSC</td>
												<td>YouTube</td>
												<td>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
											</tr>
											<tr>
												<td>yt.innertube::nextId</td>
												<td>YouTube</td>
												<td>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
											</tr>
											<tr>
												<td>yt.innertube::requests</td>
												<td>YouTube</td>
												<td>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
											</tr>
											<tr>
												<td>yt-remote-cast-available</td>
												<td>YouTube</td>
												<td>Stores the user's video player preferences using embedded YouTube video</td>
											</tr>
											<tr>
												<td>yt-remote-cast-installed</td>
												<td>YouTube</td>
												<td>Stores the user's video player preferences using embedded YouTube video</td>
											</tr>
											<tr>
												<td>yt-remote-connected-devices</td>
												<td>YouTube</td>
												<td>Stores the user's video player preferences using embedded YouTube video</td>
											</tr>
											<tr>
												<td>yt-remote-device-id</td>
												<td>YouTube</td>
												<td>Stores the user's video player preferences using embedded YouTube video</td>
											</tr>
											<tr>
												<td>yt-remote-fast-check-period</td>
												<td>YouTube</td>
												<td>Tries to estimate the users' bandwidth on pages with integrated YouTube videos.</td>
											</tr>
											<tr>
												<td>yt-remote-session-app</td>
												<td>YouTube</td>
												<td>Tries to estimate the users' bandwidth on pages with integrated YouTube videos.</td>
											</tr>
											<tr>
												<td>yt-remote-session-name</td>
												<td>YouTube</td>
												<td>Tries to estimate the users' bandwidth on pages with integrated YouTube videos.</td>
											</tr>
										</tbody>
									</table>
								</li>
								<li>
									<label>
										7. Collection of general data and information
									</label>
									<p>
										The website of the Maas Choice collects a series of general data and information when a data subject or automated system calls up the website. This general data and information are stored in the server log files. Collected may be (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system reaches our website (so-called referrers), (4) the sub-websites, (5) the date and time of access to the Internet site, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system, and (8) any other similar data and information that may be used in the event of attacks on our information technology systems.
										When using these general data and information, the Maas Choice does not draw any conclusions about the data subject. Rather, this information is needed to (1) deliver the content of our website correctly, (2) optimize the content of our website as well as its advertisement, (3) ensure the long-term viability of our information technology systems and website technology, and (4) provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack. Therefore, the Maas Choice analyzes anonymously collected data and information statistically, with the aim of increasing the data protection and data security of our enterprise, and to ensure an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from all personal data provided by a data subject.
									</p>
								</li>
								<li>
									<label>
										8. Subscription to our newsletters
									</label>
									<p>
										On the website of the Maas Choice, users are given the opportunity to subscribe to our enterprise's newsletter. The input mask used for this purpose determines what personal data are transmitted, as well as when the newsletter is ordered from the controller.
										The Maas Choice informs its customers and business partners regularly by means of a newsletter about enterprise offers. The enterprise's newsletter may only be received by the data subject if (1) the data subject has a valid e-mail address and (2) the data subject registers for the newsletter shipping. A confirmation e-mail will be sent to the e-mail address registered by a data subject for the first time for newsletter shipping, for legal reasons, in the double opt-in procedure. This confirmation e-mail is used to prove whether the owner of the e-mail address as the data subject is authorized to receive the newsletter.
										During the registration for the newsletter, we also store the IP address of the computer system assigned by the Internet service provider (ISP) and used by the data subject at the time of the registration, as well as the date and time of the registration. The collection of this data is necessary in order to understand the (possible) misuse of the e-mail address of a data subject at a later date, and it therefore serves the aim of the legal protection of the controller.
										The personal data collected as part of a registration for the newsletter will only be used to send our newsletter. In addition, subscribers to the newsletter may be informed by e-mail, as long as this is necessary for the operation of the newsletter service or a registration in question, as this could be the case in the event of modifications to the newsletter offer, or in the event of a change in technical circumstances. There will be no transfer of personal data collected by the newsletter service to third parties. The subscription to our newsletter may be terminated by the data subject at any time. The consent to the storage of personal data, which the data subject has given for shipping the newsletter, may be revoked at any time. For the purpose of revocation of consent, a corresponding link is found in each newsletter. It is also possible to unsubscribe from the newsletter at any time directly on the website of the controller, or to communicate this to the controller in a different way.
									</p>
								</li>
								<li>
									<label>
										9. Newsletter-Tracking
									</label>
									<p>
										The newsletter of the Maas Choice contains so-called tracking pixels. A tracking pixel is a miniature graphic embedded in such e-mails, which are sent in HTML format to enable log file recording and analysis. This allows a statistical analysis of the success or failure of online marketing campaigns. Based on the embedded tracking pixel, the Maas Choice may see if and when an e-mail was opened by a data subject, and which links in the e-mail were called up by data subjects.
										Such personal data collected in the tracking pixels contained in the newsletters are stored and analyzed by the controller in order to optimize the shipping of the newsletter, as well as to adapt the content of future newsletters even better to the interests of the data subject. These personal data will not be passed on to third parties. Data subjects are at any time entitled to revoke the respective separate declaration of consent issued by means of the double-opt-in procedure. After a revocation, these personal data will be deleted by the controller. The Maas Choice automatically regards a withdrawal from the receipt of the newsletter as a revocation.
									</p>
								</li>
								<li>
									<label>
										10. Contact possibility via the website
									</label>
									<p>
										The website of the Maas Choice contains information that enables a quick electronic contact to our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address). If a data subject contacts the controller by e-mail or via a contact form, the personal data transmitted by the data subject are automatically stored. Such personal data transmitted on a voluntary basis by a data subject to the data controller are stored for the purpose of processing or contacting the data subject. There is no transfer of this personal data to third parties.
									</p>
								</li>
								<li>
									<label>
										11. Online-based Audio and Video Conferences (Conference tools) Data processing
									</label>
									<p>
										We use online conference tools, among other things, for communication with our customers. The tools we use are listed in detail below. If you communicate with us by video or audio conference using the Internet, your personal data will be collected and processed by the provider of the respective conference tool and by us. The conferencing tools collect all information that you provide/access to use the tools (email address and/or your phone number). Furthermore, the conference tools process the duration of the conference, start and end (time) of participation in the conference, number of participants and other “context information” related to the communication process (metadata).
										Furthermore, the provider of the tool processes all the technical data required for the processing of the online communication. This includes, in particular, IP addresses, MAC addresses, device IDs, device type, operating system type and version, client version, camera type, microphone or loudspeaker and the type of connection.
										Should content be exchanged, uploaded, or otherwise made available within the tool, it is also stored on the servers of the tool provider. Such content includes, but is not limited to, cloud recordings, chat/ instant messages, voicemail uploaded photos and videos, files, whiteboards, and other information shared while using the service.
										Please note that we do not have complete influence on the data processing procedures of the tools used. Our possibilities are largely determined by the corporate policy of the respective provider. Further information on data processing by the conference tools can be found in the data protection declarations of the tools used, and which we have listed below this text.
										Duration of storage
										Data collected directly by us via the video and conference tools will be deleted from our systems immediately after you request us to delete it, revoke your consent to storage, or the reason for
										storing the data no longer applies. Stored cookies remain on your end device until you delete them. Mandatory legal retention periods remain unaffected.
										We have no influence on the duration of storage of your data that is stored by the operators of the conference tools for their own purposes. For details, please directly contact the operators of the conference tools.
										right to rectify inaccurate data: you have a right to immediate rectification and/or completion, insofar as the processed personal data proves to be inaccurate or incomplete.
										right to erasure: you have the right to demand the erasure of personal data relating to you. You can demand the erasure of your data if we, e.g. no longer need the personal data for the purposes for which it was collected or otherwise processed, if we have processed the data improperly or you have legitimately objected to the processing or you have withdrawn your consent or if there is a legal obligation to erase.
										right to restriction of processing: you have the right to demand the restriction of processing of your data. This right exists in particular as regards the period required to verify the data, if you have disputed the accuracy of the data concerning you and, where you have an existing right to erasure, in the event that you would like restricted processing rather than erasure. Processing is also restricted in the event that we no longer require the data for our intended purposes, but you require the data to establish, exercise or defend legal claims, and if you have objected to processing and we are contesting the legitimacy of said objection.
									</p>
								</li>
								<li>
									<label>
										12. Comments function in the blog on the website
									</label>
									<p>
										The Maas Choice offers users the possibility to leave individual comments on individual blog contributions on a blog, which is on the website of the controller. A blog is a web-based, publicly-accessible portal, through which one or more people called bloggers or web-bloggers may post articles or write down thoughts in so-called blogposts. Blogposts may usually be commented by third parties.
										If a data subject leaves a comment on the blog published on this website, the comments made by the data subject are also stored and published, as well as information on the date of the commentary and on the user's (pseudonym) chosen by the data subject. In addition, the IP address assigned by the Internet service provider (ISP) to the data subject is also logged. This storage of the IP address takes place for security reasons, and in case the data subject violates the rights of third parties, or posts illegal content through a given comment. The storage of these personal data is, therefore, in the own interest of the data controller, so that he can exculpate in the event of an infringement. This collected personal data will not be passed to third parties, unless such a transfer is required by law or serves the aim of the defense of the data controller.
									</p>
								</li>
								<li>
									<label>
										13. Routine erasure and blocking of personal data
									</label>
									<p>
										The data controller shall process and store the personal data of the data subject only for the period necessary to achieve the purpose of storage, or as far as this is granted by the European legislator or other legislators in laws or regulations to which the controller is subject to.
										If the storage purpose is not applicable, or if a storage period prescribed by the European legislator or another competent legislator expires, the personal data are routinely blocked or erased in accordance with legal requirements.
									</p>
								</li>
								<li>
									<label>
										14. Data protection for applications and the application procedures
									</label>
									<p>
										The data controller shall collect and process the personal data of applicants for the purpose of the processing of the application procedure. The processing may also be carried out electronically. This is the case, in particular, if an applicant submits corresponding application documents by e-mail or by means of a web form on the website to the controller. If the data controller concludes an employment contract with an applicant, the submitted data will be stored for the purpose of processing the employment relationship in compliance with legal requirements. If no employment contract is concluded with the applicant by the controller, the application documents shall be automatically erased two months after notification of the refusal decision, provided that no other legitimate interests of the controller are opposed to the erasure.
									</p>
								</li>
								<li>
									<label>
										15. Data protection provisions about the application and use of Facebook
									</label>
									<p>
										On this website, the controller has integrated components of the enterprise Facebook. Facebook is a social network.
										A social network is a place for social meetings on the Internet, an online community, which usually allows users to communicate with each other and interact in a virtual space. A social network may serve as a platform for the exchange of opinions and experiences, or enable the Internet community to provide personal or business-related information. Facebook allows social network users to include the creation of private profiles, upload photos, and network through friend requests.
										The operating company of Facebook is Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, United States. If a person lives outside of the United States or Canada, the controller is the Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.
										With each call-up to one of the individual pages of this Internet website, which is operated by the controller and into which a Facebook component (Facebook plug-ins) was integrated, the web browser on the information technology system of the data subject is automatically prompted to download display of the corresponding Facebook component from Facebook through the Facebook component. An overview of all the Facebook Plug-ins may be accessed under developers.facebook.com/docs/plugins/. During the course of this technical procedure, Facebook is made aware of what specific sub-site of our website was visited by the data subject.
										If the data subject is logged in at the same time on Facebook, Facebook detects with every call-up to our website by the data subject—and for the entire duration of their stay on our Internet site—which specific sub-site of our Internet page was visited by the data subject. This information is collected through the Facebook component and associated with the respective Facebook account of the data subject. If the data subject clicks on one of the Facebook buttons integrated into our website, e.g. the "Like" button, or if the data subject submits a comment, then Facebook matches this information with the personal Facebook user account of the data subject and stores the personal data.
										Facebook always receives, through the Facebook component, information about a visit to our website by the data subject, whenever the data subject is logged in at the same time on Facebook during the time of the call-up to our website. This occurs regardless of whether the data subject clicks on the Facebook component or not. If such a transmission of information to Facebook is not desirable for the data subject, then he or she may prevent this by logging off from their Facebook account before a call-up to our website is made.
										The data protection guideline published by Facebook, which is available at facebook.com/about/privacy/, provides information about the collection, processing and use of personal data by Facebook. In addition, it is explained there what setting options Facebook offers to protect the privacy of the data subject. In addition, different configuration options are made available to allow the elimination of data transmission to Facebook. These applications may be used by the data subject to eliminate a data transmission to Facebook.
										Facebook Pixel
										This website uses the visitor action pixel from Facebook for conversion measurement. The provider of this service is Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland. However, according to Facebook, the collected data are also transferred to the USA and other third countries.
										This makes it possible to track the behavior of site visitors after they have been redirected to the provider’s website by clicking on a Facebook ad. This allows the effectiveness of Facebook ads to be evaluated for statistical and market research purposes and future advertising campaigns to be optimized.
										The collected data are anonymous for us as the operator of this website, and we cannot draw any conclusions about the identity of the users. However, the data are stored and processed by Facebook, with the effect that it is possible to establish a connection to the associated user profile and Facebook can use the data for its own advertising purposes, in accordance with the Facebook Data Use Policy. This enables Facebook to place ads on Facebook pages as well as outside Facebook. We as the site operator have no influence over this data use.
										Facebook is responsible for the data security of Facebook products. You can contact Facebook directly to assert data subject rights (e.g. requests for access) regarding the data processed by Facebook. If you should assert data subject rights with us, we will be obliged to forward them to Facebook.
										You can also disable the Custom Audiences remarketing feature in the Ad Settings section under https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen. To do this, you must be logged in to Facebook.
									</p>
								</li>
								<li>
									<label>
										16. Data protection provisions about the application and use of Google Analytics (with anonymization function)
									</label>
									<p>
										On this website, the controller has integrated the component of Google Analytics (with the anonymizer function). Google Analytics is a web analytics service. Web analytics is the collection, gathering, and analysis of data about the behavior of visitors to websites. A web analysis service collects, inter alia, data about the website from which a person has come (the so-called referrer), which sub-pages were visited, or how often and for what duration a sub-page was viewed. Web analytics are mainly used for the optimization of a website and in order to carry out a cost-benefit analysis of Internet advertising.
										The operator of the Google Analytics component is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
										The purpose of the Google Analytics component is to analyze the traffic on our website. Google uses the collected data and information, inter alia, to evaluate the use of our website and to provide online reports, which show the activities on our websites, and to provide other services concerning the use of our Internet site for us.
										Google Analytics places a cookie on the information technology system of the data subject. The definition of cookies is explained above. With the setting of the cookie, Google is enabled to analyze the use of our website. With each call-up to one of the individual pages of this Internet site, which is operated by the controller and into which a Google Analytics component was integrated, the Internet browser on the information technology system of the data subject will automatically submit data through the Google Analytics component for the purpose of online advertising and the settlement of commissions to Google. During the course of this technical procedure, the enterprise Google gains knowledge of personal information, such as the IP address of the data subject, which serves Google, inter alia, to understand the origin of visitors and clicks, and subsequently create commission settlements.
										The cookie is used to store personal information, such as the access time, the location from which the access was made, and the frequency of visits of our website by the data subject. With each visit to our Internet site, such personal data, including the IP address of the Internet access used by the data subject, will be transmitted to Google in the United States of America. These personal data are stored by Google in the United States of America. Google may pass these personal data collected through the technical procedure to third parties.
										The data subject may, as stated above, prevent the setting of cookies through our website at any time by means of a corresponding adjustment of the web browser used and thus permanently deny the setting of cookies. Such an adjustment to the Internet browser used would also prevent Google Analytics from setting a cookie on the information technology system of the data subject. In addition, cookies already in use by Google Analytics may be deleted at any time via a web browser or other software programs.
										In addition, the data subject has the possibility of objecting to a collection of data that are generated by Google Analytics, which is related to the use of this website, as well as the processing of this data by Google and the chance to preclude any such. For this purpose, the data subject must download a browser add-on under the link tools.google.com/dlpage/gaoptout and install it. This browser add-on tells Google Analytics through a JavaScript, that any data and information about the visits of Internet pages may not be transmitted to Google Analytics. The installation of the browser add-ons is considered an objection by Google. If the information technology system of the data subject is later deleted, formatted, or newly installed, then the data subject must reinstall the browser add-ons to disable Google Analytics. If the browser add-on was uninstalled by the data subject or any other person who is attributable to their sphere of competence, or is disabled, it is possible to execute the reinstallation or reactivation of the browser add-ons.
										Further information and the applicable data protection provisions of Google may be retrieved under www.google.com/intl/en/policies/privacy/ and under www.google.com/analytics/terms/us.html. Google Analytics is further explained under the following Link www.google.com/analytics/.
									</p>
								</li>
								<li>
									<label>
										17. Data protection provisions about the application and use of Google-AdWords
									</label>
									<p>
										On this website, the controller has integrated Google AdWords. Google AdWords is a service for Internet advertising that allows the advertiser to place ads in Google search engine results and the Google advertising network. Google AdWords allows an advertiser to pre-define specific keywords with the help of which an ad on Google's search results only then displayed, when the user utilizes the search engine to retrieve a keyword-relevant search result. In the Google Advertising Network, the ads are distributed on relevant web pages using an automatic algorithm, taking into account the previously defined keywords.
										The operating company of Google AdWords is Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, UNITED STATES.
										The purpose of Google AdWords is the promotion of our website by the inclusion of relevant advertising on the websites of third parties and in the search engine results of the search engine Google and an insertion of third-party advertising on our website.
										If a data subject reaches our website via a Google ad, a conversion cookie is filed on the information technology system of the data subject through Google. The definition of cookies is explained above. A conversion cookie loses its validity after 30 days and is not used to identify the data subject. If the cookie has not expired, the conversion cookie is used to check whether certain sub-pages, e.g, the shopping cart from an online shop system, were called up on our website. Through the conversion cookie, both Google and the controller can understand whether a person who reached an AdWords ad on our website generated sales, that is, executed or canceled a sale of goods.
										The data and information collected through the use of the conversion cookie is used by Google to create visit statistics for our website. These visit statistics are used in order to determine the total number of users who have been served through AdWords ads to ascertain the success or failure of each AdWords ad and to optimize our AdWords ads in the future. Neither our company nor other Google AdWords advertisers receive information from Google that could identify the data subject.
										The conversion cookie stores personal information, e.g. the Internet pages visited by the data subject. Each time we visit our Internet pages, personal data, including the IP address of the Internet access used by the data subject, is transmitted to Google in the United States of America. These personal data are stored by Google in the United States of America. Google may pass these personal data collected through the technical procedure to third parties.
										The data subject may, at any time, prevent the setting of cookies by our website, as stated above, by means of a corresponding setting of the Internet browser used and thus permanently deny the setting of cookies. Such a setting of the Internet browser used would also prevent Google from placing a conversion cookie on the information technology system of the data subject. In addition, a cookie set by Google AdWords may be deleted at any time via the Internet browser or other software programs.
										Further information and the applicable data protection provisions of Google may be retrieved under https://www.google.com/intl/en/policies/privacy/.
									</p>
								</li>
								<li>
									<label>
										18. Data protection provisions about the application and use of Instagram
									</label>
									<p>
										On this website, the controller has integrated components of the service Instagram. Instagram is a service that may be qualified as an audiovisual platform, which allows users to share photos and videos, as well as disseminate such data in other social networks.
										The operating company of the services offered by Instagram is Instagram LLC, 1 Hacker Way, Building 14 First Floor, Menlo Park, CA, UNITED STATES.
										With each call-up to one of the individual pages of this Internet site, which is operated by the controller and on which an Instagram component (Insta button) was integrated, the Internet browser on the information technology system of the data subject is automatically prompted to the download of a display of the corresponding Instagram component of Instagram. During the course of this technical procedure, Instagram becomes aware of what specific sub-page of our website was visited by the data subject.
										If the data subject is logged in at the same time on Instagram, Instagram detects with every call-up to our website by the data subject—and for the entire duration of their stay on our Internet site—which specific sub-page of our Internet page was visited by the data subject. This information is collected through the Instagram component and is associated with the respective Instagram account of the data subject. If the data subject clicks on one of the Instagram buttons integrated on our website, then Instagram matches this information with the personal Instagram user account of the data subject and stores the personal data.
										Instagram receives information via the Instagram component that the data subject has visited our website provided that the data subject is logged in at Instagram at the time of the call to our website. This occurs regardless of whether the person clicks on the Instagram button or not. If such a transmission of information to Instagram is not desirable for the data subject, then he or she can prevent this by logging off from their Instagram account before a call-up to our website is made.
										Further information and the applicable data protection provisions of Instagram may be retrieved under help.instagram.com/155833707900388 and www.instagram.com/about/legal/privacy/.
									</p>
								</li>
								<li>
									<label>
										19. Data protection provisions about the application and use of YouTube
									</label>
									<p>
										On this website, the controller has integrated components of YouTube. YouTube is an Internet video portal that enables video publishers to set video clips and other users free of charge, which also provides free viewing, review and commenting on them. YouTube allows you to publish all kinds of videos, so you can access both full movies and TV broadcasts, as well as music videos, trailers, and videos made by users via the Internet portal.
										The operating company of YouTube is YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, UNITED STATES. The YouTube, LLC is a subsidiary of Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, UNITED STATES.
										With each call-up to one of the individual pages of this Internet site, which is operated by the controller and on which a YouTube component (YouTube video) was integrated, the Internet browser on the information technology system of the data subject is automatically prompted to download a display of the corresponding YouTube component. During the course of this technical procedure, YouTube and Google gain knowledge of what specific sub-page of our website was visited by the data subject.
										If the data subject is logged in on YouTube, YouTube recognizes with each call-up to a sub-page that contains a YouTube video, which specific sub-page of our Internet site was visited by the data subject. This information is collected by YouTube and Google and assigned to the respective YouTube account of the data subject.
										YouTube and Google will receive information through the YouTube component that the data subject has visited our website, if the data subject at the time of the call to our website is logged in on YouTube; this occurs regardless of whether the person clicks on a YouTube video or not. If such a transmission of this information to YouTube and Google is not desirable for the data subject, the delivery may be prevented if the data subject logs off from their own YouTube account before a call-up to our website is made.
										YouTube's data protection provisions, available at www.google.com/intl/en/policies/privacy/, provide information about the collection, processing and use of personal data by YouTube and Google.
									</p>
								</li>
								<li>
									<label>
										20. Period for which the personal data will be stored
									</label>
									<p>
										The criteria used to determine the period of storage of personal data is the respective statutory retention period. After expiration of that period, the corresponding data is routinely deleted, as long as it is no longer necessary for the fulfillment of the contract or the initiation of a contract.
									</p>
								</li>
								<li>
									<label>
										21. Provision of personal data as statutory or contractual requirement; Requirement necessary to enter into a contract; Obligation of the data subject to provide the personal data; possible consequences of failure to provide such data
									</label>
									<p>
										We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner). Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when our company signs a contract with him or her. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded. Before personal data is provided by the data subject, the data subject must contact any employee. The employee clarifies to the data subject whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of non-provision of the personal data.
									</p>
								</li>
								<li>
									<label>
										22. Existence of automated decision-making
									</label>
									<p>
										As a responsible company, we do not use automatic decision-making or profiling.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

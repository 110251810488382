<app-breadcrumb></app-breadcrumb>
<section class="register-page">
	<div class="container">
		<div class="row text-center text-lg-start justify-content-center">
			<div class="col-xl-6 col-lg-7 col-md-10 col-sm-12 mt-30 mt-lg-0">
				<div class="card-box">
					<div class="section-title mb-25">
						<h1 class="sec-title1 text-center" >User Register</h1>
						<p class="fs-md mt-4 pt-1 text-justify" >
							Due to the assistance of our experienced and capable team members, we are engaged in providing excellent quality products in limited time frame. Reasons which have turned up to be the most preference of our valued patrons include:
						</p>
					</div>
					<form method="POST" class="register-form">
						<div class="row g-4">
							<div class="col-lg-6 form-group mb-0">
								<input type="text" name="first_name" [(ngModel)]="first_name" class="form-control" placeholder="First Name">
							</div>
							<div class="col-lg-6 form-group mb-0">
								<input type="text" name="last_name" [(ngModel)]="last_name" class="form-control" placeholder="Last Name">
							</div>
							<div class="col-lg-6 form-group mb-0">
								<input type="text" maxlength="10" minlength="10" name="mobile" [(ngModel)]="mobile" class="form-control" placeholder="Your Mobile">
							</div>
							<div class="col-lg-6 form-group mb-0">
								<input type="email" name="email" [(ngModel)]="email" class="form-control" placeholder="Your Email">
							</div>
							<div class="col-lg-6 form-group mb-0">
								<input type="password" name="password" [(ngModel)]="password" class="form-control" placeholder="Your Password">
							</div>
							<div class="col-12 form-group mb-0 d-flex  justify-content-center">
								<button (click)="submitRegister($event)" type="button" class="btn-theme">Register</button>
								<a class="login-hyper align-self-center" routerLink="/login"><u>Login</u></a>
							</div>
						</div>
					</form>
					<p class="form-messages mt-20 mb-0"></p>
				</div>
			</div>
		</div>
	</div>
</section>

import { AfterViewInit, Component, ElementRef, OnInit, Type, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import * as dates from 'src/app/helpers/date';
import * as $ from 'jquery';

@Component({
  selector: 'app-write-product-review',
  templateUrl: './write-product-review.component.html',
  styleUrls: ['./write-product-review.component.scss']
})
export class WriteProductReviewComponent implements OnInit, AfterViewInit {

	@ViewChild('reviewImage', {static: false}) reviewImage!:ElementRef;
	item = {
		_id: '',
		user_id: '',
		order_id: '',
		category_id: '',
		product_id: '',
		attribute_id: '',
		attribute_name: '',
		selected: '',
		name: '',
		slug: '',
		price: 0,
		image: '',
	};
	product_id = '';
	order_id = '';
	attribute_id = '';
	slug:any = "";
	count = 0;
	average_count = 0;
	review = {
		_id: "",
		ratings: 0,
		title: '',
		details: '',
		order_id: '',
		user_id: '',
		product_id: '',
		attribute_id: '',
		average_ratings: 0,
		total_ratings: 0,
		status: 'posted',
	};
	is_saved = false;
	uploadImages:any = [];
	images:any = [];
	user:any;
	constructor(
		private notify: ToastService,
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		public $http: HttpClient,
		private _location: Location,
		public emitter: EmiterService,
	) {
	}

	ngOnInit() {
		this.user = this.httpService.getAuth();
		const params:any = this.route.snapshot.params;
		if(params && params.slug && params.selected && params.order_id){
			this.order_id = params.order_id;
			this.attribute_id = params.selected;
			this.slug = params.slug;
			this.product_id = params.product_id;
			this.review.user_id = this.user && this.user._id ? this.user._id : '';
			this.review.order_id = this.order_id;
			this.review.attribute_id = this.attribute_id;
			this.review.product_id = this.product_id;
			this.getReview(this.item);
		}
	}

	ngAfterViewInit(): void {
		$('._131lrD .keh-7s:before').css('background', 'url(~/assets/img/loader.svg) no-repeat;')
	}

	goBack(){
		window.history.back();
		this.router.navigate(['/product']);
	}

	async onUpload(e:any) {
		var files:any = e.target.files || e.dataTransfer.files;
		if (!files.length)
			return;
		this.uploadImages = files;
		let self = this;
		for(let i = 0; i < files.length; i++){
			var formData = new FormData();
			formData.append('file_name', files[i]);
			self.$http.post(self.httpService.mainUrl + 'uploadReviewImage', formData).subscribe( async (response:any) => {
				self.reviewImage.nativeElement.value = '';
				if(response.status == 1){
					var data = response.data;
					if(data){
						self.images.unshift({image: data});
					}
				}
			});
		}
	}

	async removeImage(data:any, index:any) {
		const info = {
			'image_path': data.image,
		}
		this.$http.post(this.httpService.mainUrl + 'removeReviewImage', info).subscribe(async (response:any) => {
			if(response.status == 1){
				this.images.splice(index, 1);
			}
		});
	}

	async setRating(element:any, rating:any){
		this.review.ratings = rating;
		await this.getRatings();
	}

	getRatings(){
		let str = '';
		if(this.review.ratings == 1){
			str = "Very Bad";
			this.review.title = "Hated it!";
		}else if(this.review.ratings == 2){
			str = "Bad";
			this.review.title = "Moderate!";
		}else if(this.review.ratings == 3){
			str = "Good";
			this.review.title = "Nice!";
		}else if(this.review.ratings == 4){
			str = "Very Good";
			this.review.title = "Good choice!";
		}else if(this.review.ratings == 5){
			str = "Excellent";
			this.review.title = "Best in the market!";
		}else {
			str = "";
			this.review.title = "";
		}
		return str;
	}

	getCurrentStar(){
		let cls = '_2tZufY wvjgRW';
		if(this.review.ratings == 1){
			cls = "one_star";
		}else if(this.review.ratings == 2){
			cls = "two_star";
		}else if(this.review.ratings == 3){
			cls = "three_star";
		}else if(this.review.ratings == 4){
			cls = "four_star";
		}else if(this.review.ratings == 5){
			cls = "five_star";
		}else {
			cls = "";
		}
		return cls;
	}

	getStar(){
		let cls = '_2tZufY wvjgRW';
		if(this.review.ratings == 1){
			cls = "one_star";
		}else if(this.review.ratings == 2){
			cls = "two_star";
		}else if(this.review.ratings == 3){
			cls = "three_star";
		}else if(this.review.ratings == 4){
			cls = "four_star";
		}else if(this.review.ratings == 5){
			cls = "five_star";
		}else {
			cls = "";
		}
		return cls;
	}

	onTitleChange(event:any){
		this.review.title = event.target.value;
	}

	onDescriptionChange(event:any){
		this.review.details = event.target.value;
	}

	getReview(data:any){
		if(
			(this.review.user_id != '' &&
			this.review.product_id != '' &&
			this.review.attribute_id != '' &&
			this.review.order_id != '') || data._id
		){
			let review = {
				ratings: this.review.ratings,
				title: this.review.title,
				details: this.review.details,
				images: this.images,
				order_id: this.review.order_id,
				user_id: this.review.user_id,
				product_id: this.review.product_id,
				attribute_id: this.review.attribute_id,
				status: this.review.status,
			}
			this.$http.post(this.httpService.mainUrl + 'getReview', review).subscribe(async (response:any) => {
				if(response.status == 1){
					if(response.data && response.data.product) {
						const data = response.data;
						const product = JSON.parse(JSON.stringify(data.product));
						const attributes = JSON.parse(JSON.stringify(data.product.attributes));
						let attribute:any = null;
						attributes.map((item:any) => {
							if(JSON.stringify(item._id.toString()) === JSON.stringify(this.attribute_id.toString())){
								attribute = item;
							}
						})
						this.item.attribute_name = `${attribute.package_value}${attribute.package_type}`;
						this.item.name = product.name;
						this.item.image = product.image;
					}
					if(response.data._id){
						this.review = response.data;
						this.images = response.data.images;
						this.count = response.data.total_review;
						this.average_count = response.data.avg_review;
					}else {
						this.count = response.data.total_review;
						this.average_count = response.data.avg_review;
					}
				}
			});
		}
	}

	submitReview(){
		if(
			this.review.ratings > 0 &&
			this.review.title != '' &&
			this.review.user_id != '' &&
			this.review.product_id != '' &&
			this.review.attribute_id != '' &&
			this.review.order_id != ''
		){
			let review = {
				ratings: this.review.ratings,
				title: this.review.title,
				details: this.review.details ? this.review.details : '',
				_id: this.review._id,
				images: this.images.length > 0 ? this.images : null,
				order_id: this.review.order_id,
				user_id: this.review.user_id,
				product_id: this.review.product_id,
				attribute_id: this.review.attribute_id,
				status: this.review.status,
			}
			this.$http.post(this.httpService.mainUrl + 'submitReview', review).subscribe(async (response:any) => {
				if(response.status == 1){
					this.review = response.data;
					this.is_saved = true;
					setTimeout(() => {
						this.is_saved = false;
					}, 2500);
					this.notify.generateToast('success', 'Success!', response.message)
					await this.getReview({});
				}else {
					this.notify.generateToast('error', 'Error!', response.message)
				}
			});
		}else{
			this.notify.generateToast('error', 'Error!', 'following fields are required : [ratings,title, details, user_id, product_id, variant_id, attribute_id ,order_id]')
		}
	}

	getArray(range:any){
		range++;
		let array = Array(range).fill(0).map((_, idx) => idx)
		return array;
	}

}

<section>
	<div class="about-us">
		<div class="about-us-bg" style="background-image: url('assets/img/about/top-main-bg.webp');"></div>
		<div class="top">
			<div class="top-content top-container">
				<div class="row justify-content-start">
					<div class="col-sm-12 col-md-6">
						<div class="content-text">
							<h2>Our Journey</h2>
							<p>
								The family business of processing nuts was started over a decade ago by Kishor Vachhani by establishing a subsidiary of the Repute Group; Repute Foods Private Limited. 
								It all started with growing cashews in the West African countries and then importing the raw cashew nuts to India to process them. 
								The company gradually expanded the number of nuts it processed and supplied to wholesalers and retailers across India. 
							</p>
						</div>
					</div>
				</div>
				<div class="row justify-content-end">
					<div class="col-sm-12 col-md-6">
		 				<div class="content-text">
							<p>
								The family business was carried forward and we, the next generation gave birth to “Maa's Choice”.  
								Maa's Choice is a brand run and operated by Schlokas Agro Private Limited which is an enterprise of the parent company Repute Foods Private Limited. 
								Company's headquarter is based in Rajkot, Gujarat, India. With century old traditions and guided by our great grandfathers, our traditions and values are turned into our work ethics. 
								We are a single global group company united by strong set of values, focused to build a thriving relationship of trust with every customer by fulfilling their requirements and needs.  
							</p>
						</div>
					</div>
				</div>
				<div class="row justify-content-start mt-4">
					<div class="col-sm-12 col-md-6 mt-4">
						<div class="content-text">
							<p class="final-text">
							...and Finally, Gujarat's Largest
							Nut Manufacturing Plant!
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid relative">
			<div class="row justify-content-center who-we-are">
				<div class="col-sm-12  text-center">
					<div class="content-text">
						<h2>WHO WE ARE?</h2>
						<p>
							Tremendous efforts have been invested in making Maa's Choice Gujarat's largest and India's most hygienic and dust free plant! Our efforts have clearly made us stand out in the market. 
							In a steady development of human beings, it is a given fact that keeping a good hygiene is a crucial factor in maintaining good health. 
							We, at Maa's Choice have taken immense measures to process the nuts and dried fruits in an utterly hygienic and dust free environment ensuring pronounced safety for all the consumer goods we manufacture and process.
						</p>
					</div>
				</div>
				<div class="col-sm-12 full-video-bg">
					<div class="content-video">
						<!-- <video
							[muted]="true"
							[autoplay]="true"
							[loop]="true"
							src="https://youtu.be/_BkFbXddzBA"
						></video> -->
						<!-- <iframe 
							width="100%" 
						 src="https://www.youtube.com/embed/_BkFbXddzBA"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
							allowfullscreen
						></iframe> -->
							<iframe width="100%" src="https://www.youtube.com/embed/CUuDLXIbXAE"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
								></iframe>
					</div>
				</div>
			</div>
			<div class="row justify-content-center full-img-bg" style="background-image: url('assets/img/about/who-we-are-bg.webp');">
				<div class="col-sm-12">
					<div class="content-text">
						<h2>A D2C Brand</h2>
						<p>
							Living in the current tech revolution has evolved and changed the human lifestyle massively compared to the lifestyle we used to live a decade ago. 
							Our effort to develop this “D2C” (Direct to consumer) portal is a result of this tech outburst which is a win-win to both the manufacturer as well the end consumer. 
							Maa's Choice is India's one of the most hygienic nuts processing plant where our safety measures are way beyond other nuts processing factories throughout India. 
							With the service we provide and quality of the goods we process, our price is unbeatable with any other supplier in the whole market. 
						</p>
					</div>
				</div>
			</div>
			<div class="row justify-content-center our-journey">
				<div class="col-sm-12 text-center">
					<div class="content-text">
						<h2 id="we"> Women empowerment</h2>
						<p>
							We believe in equality! Processing nuts and dried fruits is a tedious and labor intensive task which requires a workforce with a good eye to sort the processed nuts into various different grades. 
							We have employed over 200 women in our labor force who work hard and successfully fulfill the needs of our customers. 
							These hardworking women have a strong will and match their heads equally to the patriarchal society. 
							We at Maa's Choice are constantly engaged in employing as many women as we can to uplift their social status by training them in performing skilled tasks along with helping them contribute a fair share in their household income. 
						</p>
					</div>
				</div>
				<div class="col-sm-12 col-md-12 no-padd">
					<div class="content-bg-img" >
						<img src="assets/img/about/empowering-women-bg.webp">
					</div>
				</div>
			</div>
			<div class="row justify-content-center certs">
				<div class="col-sm-12 col-md-6 col-lg-4 ">
					<div class="content-text">
							<img src="assets/img/fssai.png" alt="" class="img-fluid">
						<!-- <h4>FSSAI</h4> -->
						<p id="num">
							1239793482131
						</p>
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-lg-4 ">
					<div class="content-text">
							<img src="assets/img/tuv.png" alt="" class="img-fluid">
						<!-- <h4>TUV</h4> -->
						<!-- <p>
							N30139748
						</p> -->
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-lg-4 ">
					<div class="content-text">
							<img src="assets/img/apeda.png" alt="" class="img-fluid">
						<!-- <h4>APEDA</h4> -->
						<!-- <p>
							-
						</p> -->
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="post-slide-container">
			<div class="top"></div>
			<div class="bottom"></div>
		</div> -->
	</div>
</section>

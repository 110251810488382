<div class="cart">
	<div class="container-fluid">
		<div class="cart-notification" *ngIf="order && order.order_number;">
			<div class="cart-message">
				Thank you for shoping with us! <br/>
				Your Order is placed successfully with order number: <strong class="brand-color">{{order.order_number}}</strong>
			</div>
		</div>
		<div class="row cart-sections">
			<div class="col-sm-12 col-md-5 col-lg-4">
				<div class="sidemenu-wrapper">
					<div class="sidemenu-content">
						<div class="cart-header">
							<h3 class="widget_title">Cart <span class="cart-count">{{items ? items.length : 0}}</span></h3>
							<p class="free-shipping">Free Shipping On Orders Over ₹499 Rupees!</p>
							<!-- <button class="closeButton border-theme text-theme bg-theme-hover sideMenuCls">
								<i class="fas fa-times"></i>
							</button> -->
						</div>
						<div class="cart-section">
							<div class="widget widget_shopping_cart">
								<div class="widget_shopping_cart_content">
									<div class="items" *ngIf="items && items.length > 0; else noItem;">
										<ng-container *ngFor="let item of items; let index=index">
											<div class="item">
												<div class="image">
													<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">
														<img [attr.src]="httpService.imgURL + item.image" [attr.alt]="item.name">
													</a>
												</div>
												<div class="main">
													<div class="main-top">
														<div class="main-top-left">
															<h3 class="title">
																<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">{{item.name}}</a>
															</h3>
															<ul class="properties-key-value">
																<li data-key="Flavor"><span class="properties-key-value-key">Category</span><span class="properties-key-value-spacer">: </span><span class="properties-key-value-value">{{item.category_name}}</span></li>
																<li data-key="Pack Size"><span class="properties-key-value-key">Pack Size</span><span class="properties-key-value-spacer">: </span><span class="properties-key-value-value">{{getPackageName(item.selected_attribute)}}</span></li>
															</ul>
														</div>
														<button class="remove" aria-label="remove Slate" (click)="removeItem(item, index)">
															<svg width="9" viewBox="0 0 10 10">
																<path d="M9.677 8.118a1.102 1.102 0 11-1.559 1.56L5 6.558 1.882 9.677a1.102 1.102 0 11-1.56-1.559L3.442 5 .323 1.882A1.102 1.102 0 111.882.322L5 3.442 8.118.323a1.102 1.102 0 111.56 1.559L6.558 5l3.118 3.118z" fill="#cacaca" fill-rule="nonzero"></path>
															</svg>
														</button>
													</div>
													<div class="main-bottom">
														<div class="quantity-selector">
															<button aria-label="decrease quantity" (click)="item.qty = item.qty > 1 ? item.qty - 1 : item.qty; calculateTotal();updateCart();">
																<svg width="10" viewBox="0 0 12 2" xmlns="http://www.w3.org/2000/svg">
																	<path d="M11.25 0H.75C.3 0 0 .4 0 1s.3 1 .75 1h10.5c.45 0 .75-.4.75-1s-.3-1-.75-1z" fill="#7A7A7A" fill-rule="nonzero"></path>
																</svg>
															</button>
															<input type="text" [(ngModel)]="item.qty" [value]="item.qty">
															<button class="down" aria-label="increase quantity"  (click)="(item.stock > item.qty && item.qty = item.qty + 1); calculateTotal();updateCart();">
																<svg width="10" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
																	<path d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 002 0V7h4a1 1 0 000-2z" fill="currentColor" fill-rule="nonzero"></path>
																</svg>
															</button>
														</div>
														<span class="price">₹{{item.offer_price && item.offer_price > 0 ? item.offer_price : item.price}}</span>
													</div>
														<span class="red-color">{{!(item.stock > item.qty)?"Out of Stock":""}}</span>
												</div>
											</div>
										</ng-container>
									</div>
									<ng-template #noItem>
										<div class="items">
											<div class="main">
												<div class="main-top">
													<div class="main-top-left">
														<h3 class="title">
															No Items In Cart
														</h3>
													</div>
												</div>
											</div>
										</div>
									</ng-template>
								</div>
							</div>
						</div>
						<div class="cart-footer">
							<div class="cart-footer-row">
								<span class="flex">Shipping</span>
								<span class="shipping-cost">
									<span>CALCULATED AT CHECKOUT</span>
								</span>
							</div>
								<div class="cart-footer-row" *ngIf="items && items.length > 0">
									<strong class="flex">Payment Charges</strong>
									<strong class="slidecart-subtotal">+ {{!isCOD?(0 | currency:'INR':'symbol-narrow':'2.2-2' ): (50| currency:'INR':'symbol-narrow':'2.2-2' )}}
									</strong>
								</div>
							<div class="cart-footer-row" *ngIf="items && items.length > 0">
								<strong class="flex">Subtotal</strong>
								<strong class="slidecart-subtotal">
								{{!isCOD ?(cart.subtotal | currency:'INR':'symbol-narrow':'2.2-2' ): (cart.subtotal+50 | currency:'INR':'symbol-narrow':'2.2-2')}}
								</strong>
							</div>
							<!-- <button name="checkout" class="btn button-full" (click)="moveToCart($event); showCart = false;">Continue Shopping</button> -->
							<a class="footer-continue btn button-full" routerLink="/product">Continue Shopping</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-7 col-lg-8">
				<div class="payout-wrapper">
					<div class="login-section" *ngIf="address.length > 0 && user._id;">
							<h2 class="title" tabindex="-1">
								Select Address
							</h2>
						<select
							class="form-select"
							placeholder="Address Type"
							name="address_id"
							[disabled]="checkout"
							id="address_id"
							[ngModelOptions]="{standalone: true}"
							[value]="currentAddress._id"
							(change)="onAddressSelect($event, 1)"
						>
							<option value="" disabled>Select an address</option>
							<ng-container *ngFor="let item of address; let index=index">
								<option [attr.value]="item._id">
									{{item.person_name}}, {{item.mobile}}, {{item.address1}}, {{item.city}}, {{item.pincode}},
								</option>
							</ng-container>
						</select>
						<div class="shipping-form" *ngIf="currentAddress._id">
							<div class="cart-footer-row">
							<h2 class="title" tabindex="-1">
								Shipping Address
							</h2>
								<a class="footer-continue btn button-full-edit" routerLink="/profile/address">Edit</a>
							</div>
							<div class="field">
								<div class="form-row">
									<select
										class="form-select"
										placeholder="Country/Origin"
										name="country"
										[disabled]="true"
										id="country"
									>
										<option value="india" selected>India</option>
									</select>
								</div>
							</div>
								<div class="field">
									<div class="form-row">
										<input type="text" name="email" id="email" [disabled]="true" placeholder="Email" value="email"
											class="form-control" [(ngModel)]="email" />
									</div>
								</div>
							<div class="field">
								<div class="form-row">
									<input
										type="text"
										name="person_name"
										id="person_name"
										[disabled]="true"
										placeholder="Full name"
										class="form-control"
										[(ngModel)]="currentAddress.person_name"
									/>
								</div>
							</div>
							<div class="field">
								<div class="form-row">
									<textarea
										name="address1"
										id="address1"
										[disabled]="true"
										placeholder="Address"
										class="form-control"
										[(ngModel)]="currentAddress.address1"
									></textarea>
								</div>
							</div>
							<div class="field">
								<div class="form-row">
									<textarea
										name="address2"
										id="address2"
										[disabled]="true"
										placeholder="Lankdmark, Building, Apartment, etc..."
										class="form-control"
										[(ngModel)]="currentAddress.address2"
									></textarea>
								</div>
							</div>
							<div class="field field-half me-1">
								<div class="form-row">
									<input
										type="text"
										class="form-control"
										placeholder="City"
										name="city"
										id="city"
										[disabled]="true"
										[(ngModel)]="currentAddress.city"
									/>
								</div>
							</div>
							<!-- <div class="field field-third">
								<div class="form-row">
									<input
										type="text"
										class="form-control"
										placeholder="State"
										name="state"
										id="state"
										[disabled]="true"
										[(ngModel)]="currentAddress.state"
									/>
								</div>
							</div> -->
							<div class="field field-half">
								<div class="form-row">
									<input
										type="text"
										class="form-control"
										placeholder="Pincode"
										name="pincode"
										id="pincode"
										[disabled]="true"
										[(ngModel)]="currentAddress.pincode"
									/>
								</div>
							</div>
							<div class="field">
								<div class="form-row">
									<input
										type="text"
										name="mobile"
										id="mobile"
										placeholder="Phone"
										class="form-control"
										[disabled]="true"
										[(ngModel)]="currentAddress.mobile"
									/>
								</div>
							</div>
							<div class="field">
								<div class="form-row">
									<select
										class="form-select"
										placeholder="Address Type"
										name="type"
										id="type"
										[disabled]="true"
										[(ngModel)]="currentAddress.type"
									>
										<option value="">Select an option…</option>
										<option value="1">Home</option>
										<option value="2">Office</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="guest-section" *ngIf="address.length == 0 && !user._id;">
						<div class="section--contact-information">
							<div class="payout-header">
								<div class="header-wrapper">
									<h2 class="title" tabindex="-1">
										Contact information
									</h2>
									<p class="layout-flex__item">
										Already have an account? 										<a routerLink="/login" [queryParams]="{redirection: 'cart'}"> Log in</a>
									</p>
								</div>
							</div>
							<div class="guest-email">
								<div class="form-row">
									<form action="#" method="post" [formGroup]="guestForm">
										<input
											type="email"
											name="email"
											id="email"
											placeholder="Email"
											class="form-control"
											[(ngModel)]="guest_address.email"
											formControlName="email"
											[ngModelOptions]="{standalone: true}"
											[ngClass]="{'input': true, 'is-danger': submited && f.email.errors}"
										/>
										<span *ngIf="submited && f.email?.errors" class="invalid-feedback">
											<span *ngIf="f.email.errors?.required">Email Is Required</span>
										</span>
									</form>
								</div>
							</div>
						</div>
						<form action="#" method="post" [formGroup]="guestForm">
							<div class="shipping-form floating-labels">
								<h2 class="title" tabindex="-1">
									Shipping Address
								</h2>
								<div class="field">
									<div class="form-row">
										<select
											class="form-select"
											placeholder="Country/Origin"
											name="country"
											id="country"
											[(ngModel)]="guest_address.country"
											formControlName="country"
											[ngModelOptions]="{standalone: true}"
										>
											<option value="india">India</option>
										</select>
									</div>
								</div>
								<div class="field">
									<div class="form-row">
										<input
											type="text"
											name="person_name"
											id="person_name"
											placeholder="Full Name"
											class="form-control"
											[(ngModel)]="guest_address.person_name"
											formControlName="person_name"
											[ngModelOptions]="{standalone: true}"
											[ngClass]="{'input': true, 'is-danger': submited && f.person_name.errors}"
										/>
										<span *ngIf="submited && f.person_name?.errors" class="invalid-feedback">
											<span *ngIf="f.person_name.errors?.required">Person Name Is Required</span>
											<span *ngIf="f.person_name.errors?.minlength">Person Name Should 4 Digit Long</span>
										</span>
									</div>
								</div>
								<div class="field">
									<div class="form-row">
										<textarea
											name="address1"
											id="address1"
											placeholder="Address"
											class="form-control"
											formControlName="address1"
											[ngModelOptions]="{standalone: true}"
											[(ngModel)]="guest_address.address1"
											[ngClass]="{'input': true, 'is-danger': submited && f.address1.errors}"
										></textarea>
										<span *ngIf="submited && f.address1?.errors" class="invalid-feedback">
											<span *ngIf="f.address1.errors?.required">Address Line1 Is Required</span>
											<span *ngIf="f.address1.errors?.minlength">Address Line1 Should 4 Digit Long</span>
										</span>
									</div>
								</div>
								<div class="field">
									<div class="form-row">
										<textarea
											name="address2"
											id="address2"
											formControlName="address2"
											placeholder="Lankdmark, Building, Apartment, etc..."
											class="form-control"
											[ngModelOptions]="{standalone: true}"
											[(ngModel)]="guest_address.address2"
											[ngClass]="{'input': true, 'is-danger': submited && f.address2.errors}"
										></textarea>
										<span *ngIf="submited && f.address2?.errors" class="invalid-feedback">
											<span *ngIf="f.address2.errors?.required">Address Line2 Is Required</span>
											<span *ngIf="f.address2.errors?.minlength">Address Line2 Should 4 Digit Long</span>
										</span>
									</div>
								</div>
								<div class="field field-third">
									<div class="form-row">
										<input
											type="text"
											class="form-control"
											placeholder="City"
											name="city"
											id="city"
											formControlName="city"
											[ngModelOptions]="{standalone: true}"
											[(ngModel)]="guest_address.city"
											[ngClass]="{'input': true, 'is-danger': submited && f.city.errors}"
										/>
										<span *ngIf="submited && f.city?.errors" class="invalid-feedback">
											<span *ngIf="f.city.errors?.required">Town/City Is Required</span>
											<span *ngIf="f.city.errors?.minlength">Town/City Should 4 Digit Long</span>
										</span>
									</div>
								</div>
								<div class="field field-third ms-1 me-1">
									<div class="form-row">
										<input
											type="text"
											class="form-control "
											placeholder="State"
											name="state"
											id="state"
											formControlName="state"
											[ngModelOptions]="{standalone: true}"
											[(ngModel)]="guest_address.state"
											[ngClass]="{'input': true, 'is-danger': submited && f.state.errors}"
										/>
										<span *ngIf="submited && f.state?.errors" class="invalid-feedback">
											<span *ngIf="f.state.errors?.required">Town/City Is Required</span>
											<span *ngIf="f.state.errors?.minlength">Town/City Should 4 Digit Long</span>
										</span>
									</div>
								</div>
								<div class="field field-third">
									<div class="form-row">
										<input
											type="text"
											class="form-control"
											placeholder="Pincode"
											name="pincode"
											id="pincode"
											formControlName="pincode"
											(change)="onAddressSelect($event, 2)"
											[ngModelOptions]="{standalone: true}"
											[(ngModel)]="guest_address.pincode"
											[ngClass]="{'input': true, 'is-danger': submited && f.pincode.errors}"
										/>
										<span *ngIf="submited && f.pincode?.errors" class="invalid-feedback">
											<span *ngIf="f.pincode.errors?.required">Postcode/ZIP Is Required</span>
											<span *ngIf="f.pincode.errors?.minlength || f.pincode.errors?.maxlength">Postcode Should Be 6 Digit Long</span>
										</span>
									</div>
								</div>
								<div class="field">
									<div class="form-row">
										<input
											type="text"
											name="mobile"
											id="mobile"
											placeholder="Phone"
											class="form-control"
											formControlName="mobile"
											[ngModelOptions]="{standalone: true}"
											[(ngModel)]="guest_address.mobile"
											[ngClass]="{'input': true, 'is-danger': submited && f.mobile.errors}"
										/>
										<span *ngIf="submited && f.mobile?.errors" class="invalid-feedback">
											<span *ngIf="f.mobile.errors?.required">Mobile Is Required</span>
											<span *ngIf="f.mobile.errors?.minlength || f.mobile.errors?.maxlength">Mobile Number Should 10 Digit Long</span>
										</span>
									</div>
								</div>
								<div class="field">
									<div class="form-row">
										<select
											class="form-select"
											placeholder="Address Type"
											name="type"
											id="type"
											formControlName="type"
											[ngModelOptions]="{standalone: true}"
											[(ngModel)]="guest_address.type"
											[ngClass]="{'input': true, 'is-danger': submited && f.type.errors}"
										>
											<option value="">Select an option…</option>
											<option value="Home">Home</option>
											<option value="Office">Office</option>
										</select>
										<span *ngIf="f.type?.errors" class="invalid-feedback">
											<span *ngIf="f.type.errors?.required">Address Type Is Required</span>
										</span>
									</div>
								</div>
							</div>
						</form>
					</div>
					<br />
					<div class="checkout-footer">
						<h2 class="title" tabindex="-1">
							Shipping Method
						</h2>
						<p><span></span>&nbsp;Free Standard Shipping <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3 - 5 Business Days) </p>
						<!-- <a [ngStyle]="{'pointer-events': !(user._id && currentAddress._id && isValidAddress === true) && !(guest_address.pincode && session && isValidAddress === true) ? 'none' : 'all'}" class="footer-continue btn button-full" href="javascript:void(0);" (click)="finalSubmitOrder($event, ((user._id && currentAddress._id && isValidAddress === true) ? 1 : 2))">Continue To Payment</a> -->
					</div>
					<br />
						<div class="checkout-footer">
							
							<h2 class="title" tabindex="-1">
								Payment Method
							</h2>
							<div class="variant-list">
								<span class="variant-item" [ngClass]="{'active': isCOD}" (click)="isCOD=true">
									Cash On Delivery
								</span>
								<span class="variant-item" [ngClass]="{'active': !isCOD}" (click)="isCOD=false">
									Pay Online
								</span>
							</div>
							<span *ngIf="isCOD">Note: You have selected Cash on delivery which adds extra {{ 50 |currency:'INR'}} to your invoice amount.</span>
							<br />
							<br/>	
							<br />
							<a 
								class="footer-continue btn button-full" href="javascript:void(0);" *ngIf="!isCOD"
								(click)="finalSubmitOrder($event, user._id ,  currentAddress._id , isValidAddress,isCOD,guest_address,session)">Continue
								To Payment</a>
							<a class="footer-continue btn button-full" href="javascript:void(0);" *ngIf="isCOD"
								(click)="finalSubmitOrder($event, user._id ,  currentAddress._id , isValidAddress,isCOD,guest_address,session)">Place your order</a>
						</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Paytm Getway -->
	<div id="paytm-checkoutjs"></div>
	<!-- End Paytm Getway -->
</div>

<app-breadcrumb></app-breadcrumb>
<div class="order-detail">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-12">
				<div class="order-title">
					<h1>Order Details</h1>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="!order._id || !order.order_number">
			<div class="col-md-12 col-12">
				<div class="order-box">
					<ngx-skeleton-loader count="50" appearance="" animation="progress"></ngx-skeleton-loader>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="order && order._id">
			<div class="col-md-12 col-12">
				<div class="order-box">
					<div class="order-header">
						<div class="order-number">
							<p>
								Order <span>{{order.order_number}}</span>
							</p>
							<p>
								Order Placed On <span>{{getOrderDate(order.createdAt, 1)}}</span>
							</p>
						</div>
						<div class="request_support" *ngIf="order.status === 'Delivered'">
							<a [routerLink]="'/product/' + selectedItem.slug + '/pid/' + selectedItem._id +'/review/' + attribute_id + '/order/' + order_id" >
								<img src="assets/img/write-review.svg" [alt]="order.order_number"/>
								Write Review
							</a>
						</div>
						<div class="order-invoice" *ngIf="order && order.file">
							<a
								target="_blank"
								[attr.download]="order.order_number"
								[attr.href]="httpService.imgURL + order.file"
							>
								<img src="assets/img/download.svg" [alt]="order.order_number"/>
								Download Invoice
							</a>
						</div>
					</div>
					<div class="order-content">
						<div class="product-list">
							<div class="product-title">
								Product Details
								<div class="order-status">
									{{order.status}} On {{getOrderDate(order.updatedAt, 2)}}
								</div>
							</div>
							<ng-container *ngFor="let item of order.items; let i=index">
								<div class="product-item">
									<div class="catalog">
										<div class="checks" *ngIf="checkRefundable(order)">
											<input type="checkbox" [value]="item._id" [id]="item._id" [checked]="isChecked(item._id)" (click)="setReturnItem(item)"/>
										</div>
										<div class="media">
											<img [attr.src]="httpService.generateImagePreview(item.image)" [attr.alt]="item.name"/>
										</div>
										<div class="item-title">
											<h4>
												<a [routerLink]="'/product/' + httpService.compressSLUG(item.slug)">{{item.name}}</a>
											</h4>
											<small>{{item.category_name}}</small><br />
											<small>Weight: {{item.selected_attribute.package_value+item.selected_attribute.package_type}}</small>
										</div>
									</div>
									<div class="qty">
										Qty: <span>{{item.qty}}</span>
									</div>
									<div class="price">
										<span>₹{{item.total}}</span>
									</div>
								</div>
							</ng-container>
						</div>
						<div class="row address">
							<div class="col-md-12 col-12 col-lg-6">
								<div class="shipping">
									<div class="title">
										<h3>Shipping Address</h3>
									</div>
									<div class="info">
										{{order.address.person_name}} - Mo ({{order.address.mobile}})<br/>
										{{order.address.address1}}<br/>
										{{order.address.address2}}<br/>
										{{order.address.city}} {{order.address.pincode}}
									</div>
								</div>
							</div>
							<div class="col-md-12 col-12 col-lg-6">
								<div class="billing">
									<div class="title">
										<h3>Billing Address</h3>
									</div>
									<div class="info">
										{{order.address.person_name}} - Mo ({{order.address.mobile}})<br/>
										{{order.address.address1}}<br/>
										{{order.address.address2}}<br/>
										{{order.address.city}} {{order.address.pincode}}
									</div>
								</div>
							</div>
						</div>
						<div class="summary">
							<div class="title">
								<h3>Order Summary</h3>
							</div>
							<div class="charges">
								<h5>Item(S) Subtotal:</h5>
								<span>₹ {{order.sub_total}}</span>
							</div>
							<div class="charges">
								<h5>COD charges:</h5>
								<span>₹  {{order.shipping}}</span>
							</div>
								<div class="charges">
									<h5>Shipping Charges:</h5>
									<span>₹ {{order.shipping}}</span>
								</div>
							<div class="charges">
								<h5>Total:</h5>
								<span>₹  {{order.sub_total+order.shipping}}</span>
							</div>
							<div class="charges">
								<h5>Promotion Applied:</h5>
								<span>₹  {{order.discount}}</span>
							</div>
							<div class="grand-total">
								<h5>Grand Total:</h5>
								<span>₹  {{order.final_total}}</span>
							</div>
						</div>
					</div>
					<div class="order-footer">
						<h5>Payment Method: <span>{{order.payment_method}}</span></h5>
					
					</div>
					<div class="returns">
						<div class="return_section" *ngIf="order.return_status !== 0">
							<div class="return_title">
								<span [ngClass]="getReturnStatusClass(order, 1)">Return & Refund {{getReturnStatusClass(order, 2)}}</span>
								<span class="return_number" *ngIf="order.return_id">&nbsp;
									(
										<span>Refund ID: </span>
										<span class="return-id">{{'#' + year + '-' + month}}{{order.return_id}}</span>
									)
								</span>
							</div>
							<div class="return_details">
								<span>Your shipment has been {{order.status}}. ₹{{order.final_total}} as refund will be added to your Original Payment Method within next 7 Working Business Days. </span>
								<span class="reason" *ngIf="order.return_status != 0 && order.return_reason != ''"><span>Return Reason &nbsp;</span>: {{order.return_reason}}</span>
							</div>
						</div>
						<div class="return_reason" *ngIf="isReturnPress === true && order.return_status == 0 && order.return_reason == '' && order.return_submit == 1">
							<select

								class="form-control"
								[(ngModel)]="return_reason"
								name="return_reason"
								placeholder="Select Valid Reason For Return This Item"
							>
								<option value="">SELECT</option>
								<option value="Packaging/Product Damaged">Packaging/Product Damaged</option>
								<option value="Got Wrong Product Delivered">Got Wrong Product Delivered</option>
								<option value="Product Not As Expected">Product Not As Expected</option>
								<option value="No Longer Needed">No Longer Needed</option>
								<option value="Delivery Took Too Long">Delivery Took Too Long</option>
								<option value="Other">Other - Specify Reason</option>
							</select>
							<input
								*ngIf="return_reason ==='Other'"
								class="form-control mt-4"
								[(ngModel)]="return_reason_other"
								name="return_reason"
								placeholder="Enter Valid Reason For Return This Item" />
							<button class="btn-theme mt-3" type="button" *ngIf="order.return_status == 0 && order.return_reason == '' && order.return_submit == 1" (click)="returnOrder(order)">Submit Return</button>
							<button class="btn-theme mt-2 btn-danger" type="button" *ngIf="order.return_status == 0 && order.return_reason == '' && order.return_submit == 1" (click)="isReturnPress = false;order.return_submit = 0;return_reason = '';">Cancel Return</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';

import * as $ from 'jquery';
import { EmiterService, HttpService } from 'src/app/services';
import { GET_COMPANY, SET_COMPANY, SET_CATEGORY, SET_SESSION, LOG_OUT } from 'src/app/store';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	category:any = [];
	public user:any;
	public session:any;
	constructor(
		private router: Router,
		private http: HttpClient,
		private httpService: HttpService,
		private store: Store,
		private emiter: EmiterService,
	){
		router.events.forEach((event) => {
			if(event instanceof NavigationStart) {
				if(event.url === '/logout'){
					this.logoutUser();
				}
				this.onLoad("");
				this.emiter.emit('isloading', true);
			}
			if(event instanceof NavigationEnd) {
				if(event.url === '/logout'){
					this.logoutUser();
				}
				this.onLoad("");
				this.emiter.emit('isloading', true);
			}
			if(event instanceof NavigationCancel) {
				this.emiter.emit('isloading', true);
			}
			if(event instanceof NavigationError) {
				this.emiter.emit('isloading', true);
			}
			if(event instanceof RoutesRecognized) {
				this.onLoad("");
				this.emiter.emit('isloading', true);
			}
		});
		this.store.subscribe((data:any) => {
			const company = data.main && data.main.company != undefined && data.main.company._id != undefined && data.main.company._id ? data.main.company : null;
			const user = data.main && data.main.auth != undefined && data.main.auth._id != undefined && data.main.auth._id ? data.main.auth : null;
			if(!company){
				this.fetchCompanyDetails();
			}
			if(user && user._id){
				this.user = user;
			}
		})
		if(!this.user || !this.user._id){
			this.session = this.generateSession();
			this.store.dispatch(SET_SESSION({session: this.session}));
		}
	}

	ngOnInit() {
		// this.getMenuCategory();
	}

	onScroll(event:any){
		var B = document.body; //IE 'quirks'
		var D = document.documentElement; //IE with doctype
		D = (D.clientHeight) ? D : B;
		var t:any, e:any, n:any, s:any = "";
        e = $(".header-sticky");
        t = "is-sticky";
        let _thisScrollTop:any = $(event.target).scrollTop();
		if (D.scrollTop > 720){
			e.addClass(t)
		} else{
			e.removeClass(t);
		}
		if ( (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2 ) {
			e.removeClass(t);
		} else {
			e.addClass(t);
		}

		const nav = document.getElementsByClassName('navbar-collapse')[0];
		const classes = nav.classList.toString();
		const isOpen = classes.indexOf('show') > -1;
		if (D.scrollTop == 0) {
			e.removeClass(t);
		}

	}

	onLoad(event:any){
		if($("[data-bg-src]").length > 0){
			$("[data-bg-src]").each(function(index:any, element:any) {
				var e = $(element).attr("data-bg-src");
				$(element).css({"background-image": "url(" + e + ")"});
			})
		}
	}

	logoutUser() {
		this.store.dispatch(LOG_OUT());
		localStorage.clear();
		sessionStorage.clear();
		this.router.navigate(['/']);
	}

	fetchCompanyDetails(){
		this.http.post(this.httpService.mainUrl + 'fetchCompanyDetails',{}).subscribe((res:any) => {
			if(res.status == 1){
				let data = res.data;
				data.mapURL = this.httpService.getSafeURL(`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29558.493311396942!2d${data.long}!3d${data.lat}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc010eafe67f38f17!2sRepute%20Foods%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1640250106126!5m2!1sen!2sin`, 'RESOURCE');
				this.store.dispatch(SET_COMPANY({company: data}));
			}
		})
	}

	getMenuCategory(){
		this.http.post(this.httpService.mainUrl + 'getMenuCategory', {}).subscribe((result: any) => {
			this.emiter.emit("isloading", {type: "isloading", data: false});
			if(result.status == 1){
				this.category = result.data && Array.isArray(result.data) ? result.data : [];
				this.category.push({_id: "", name: "All Product", children: [{_id: "", name: "View All Product"}]});
				this.store.dispatch(SET_CATEGORY({category: result.data.valueOf()}));
			}else{
				this.category = [];
				this.category.push({_id: "", name: "All Product", children: [{_id: "", name: "View All Product"}]});
			}
		})
	}

	generateSession() {
		const uuid:any = uuidv4();
		return uuid;
	}
}

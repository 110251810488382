import { HttpClient } from '@angular/common/http';
import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	ActivatedRoute,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	RoutesRecognized,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { EmiterService, HttpService, ToastService } from 'src/app/services';
import {
	GET_COMPANY,
	Company,
	SET_AUTH,
	Auth,
	LOG_OUT,
	SET_CATEGORY,
	SET_CART,
} from 'src/app/store';
import * as reducers from 'src/app/store/reducers';
import * as helper from 'src/app/helpers';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	@ViewChild('navbarToggler', { static: true }) navbarToggler!: ElementRef;
	category: any = [];
	flagCart: any = false;
	public flagRemove: any = false;
	url: any = 'home';
	auth: any;
	sub!: Subscription;
	searchString: any = '';
	isMobile: boolean = false;
	mobileWidthLimit: any = 992;
	width: number = 1200;
	isHome: boolean = false;
	scrolled: boolean = false;
	checkScroll = 530;
	openMegaMenu = false;
	openSub1 = '';
	openSub2 = '';
	openSub3 = '';
	showCart: boolean = false;
	iscartLoaded: boolean = false;
	iscartCalculated: boolean = false;
	public qty: any = 1;
	public items!: any[];
	public cart: any = {
		items: [],
		user_id: '',
		session_id: '',
		shipping_type: 1,
		subtotal: 0,
		total: 0,
		tax_type: 0,
		tax: 0,
		discount: 0,
		discount_type: 0,
		shipping_charge: 0,
	};
	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public httpService: HttpService,
		private http: HttpClient,
		private store: Store<reducers.AppState>,
		private emiter: EmiterService,
		private notify: ToastService
	) {
		this.emiter.on('cart_change').subscribe((res: any) => {
			console.log('sdhgsd', res);
			if (res.data && res.type === 'cart_change') {
				console.log('res', res);
				this.showCart = true;
				if (!res.remove) {
					this.flagCart = false;
					setTimeout(() => {
						this.flagCart = true;
						this.flagRemove = false;
					}, 500);
				} else {
					this.flagRemove = true;
					this.flagCart = false;
					if (res.isCheckout) {
						this.flagCart = true;
						this.showCart = false;
						setTimeout(() => {
							this.flagCart = false;
							this.flagRemove = false;
						}, 500);
					} else {
						// this.flagCart = true;
						setTimeout(() => {
							this.flagCart = true;
							this.flagRemove = false;
						}, 500);
					}
				}
			}
		});
		this.sub = this.store.subscribe((state: any) => {
			let params: any = this.route.snapshot.queryParams;
			// const auth = state.main && state.main.auth  && state.main.auth._id ? state.main.auth : Auth;
			this.auth = this.httpService.auth;
			let flag = true;
			if (params && params.data) {
				let data = JSON.parse(params.data);
				if (data.STATUS === 'TXN_FAILURE') {
					flag = false;
				}
			}
			if (flag === true) {
				this.cart.items =
					state.main &&
					state.main.cart != undefined &&
					Array.isArray(state.main.cart)
						? state.main.cart
						: [];
				this.items = this.cart.items;
				this.calculateTotal();
			}
			// this.auth = data.main && data.main.auth != undefined && data.main.auth._id ? data.main.auth : Auth;

			const category =
				state.main &&
				state.main.category != undefined &&
				state.main.category.length > 0
					? state.main.category
					: [];
			this.category = category;
		});
		this.isMobile = false;
		if (window.innerWidth < this.mobileWidthLimit) {
			this.isMobile = true;
		}
		this.width = window.outerWidth;
		let uri = this.router.url.split('/')[1];
		if (uri === 'home') {
			this.isHome = true;
		}
		var B = document.body; //IE 'quirks'
		var D = document.documentElement; //IE with doctype
		D = D.clientHeight ? D : B;
		if (uri === 'home') {
			this.scrolled = D.scrollTop > this.checkScroll ? true : false;
		} else {
			this.scrolled = false;
		}
		this.getMenuCategory();
	}

	ngOnInit(): void {
		this.isMobile = false;
		var B = document.body; //IE 'quirks'
		var D = document.documentElement; //IE with doctype
		D = D.clientHeight ? D : B;
		this.sub = this.store.subscribe((state: any) => {
			let params: any = this.route.snapshot.queryParams;
			let flag = true;
			if (params && params.data) {
				let data = JSON.parse(params.data);
				if (data.STATUS !== 'TXN_FAILURE') {
					flag = false;
				}
			}
			if (flag === true) {
				this.cart.items =
					state.main &&
					state.main.cart != undefined &&
					Array.isArray(state.main.cart)
						? state.main.cart
						: [];
				this.items = this.cart.items;
				this.calculateTotal();
			}
			// this.auth = data.main && data.main.auth != undefined && data.main.auth._id ? data.main.auth : Auth;
			this.auth = this.httpService.auth;
		});
		if (window.innerWidth < this.mobileWidthLimit) {
			this.isMobile = true;
		}
		this.width = window.outerWidth;
		let uri = this.router.url.split('/')[1];
		if (uri === 'home') {
			this.isHome = true;
		}
		if (uri === 'home') {
			this.scrolled = D.scrollTop > this.checkScroll ? true : false;
		} else {
			this.scrolled = false;
		}
	}

	getBg() {
		let style: any = '';
		if (this.router.url === '/') {
			if (!this.scrolled) {
				style = { backgroundColor: 'transparent' };
			} else {
				// style = {backgroundColor: 'var(--mainBG)'};
				// style = {backgroundColor: `#fff`};
				style = { backgroundColor: `transparent` };
			}
		} else {
			// style = {backgroundColor: `#fff `};
			style = { backgroundColor: `transparent` };
		}
		return style;
	}

	toggleMegaMenu(event: any) {
		event.preventDefault();
		this.openMegaMenu = !this.openMegaMenu;
		// this.scrolled = this.openMegaMenu;
	}

	navBarTogglerIsVisible() {
		return this.navbarToggler.nativeElement.offsetParent !== null;
	}

	collapseNav() {
		if (this.navBarTogglerIsVisible()) {
			this.navbarToggler.nativeElement.click();
		}
	}
	toggleMenuButton() {
		const toggler = document.getElementsByClassName('navbar-toggler')[0];
		const isOpen = toggler.classList.toString().indexOf('collapsed') > -1;
		this.scrolled = false;
	}

	toggleMenu(event: any) {
		const toggler = document.getElementsByClassName('navbar-toggler')[0];
		const isOpen = toggler.classList.toString().indexOf('collapsed') > -1;
		if (!isOpen) {
			this.scrolled = true;
		} else {
			this.scrolled = !this.scrolled;
		}
	}

	checkClose(event: any) {
		event.preventDefault();
		var B = document.body; //IE 'quirks'
		var D = document.documentElement; //IE with doctype
		D = D.clientHeight ? D : B;
		const toggler = document.getElementsByClassName('navbar-toggler')[0];
		const nav = document.getElementsByClassName('navbar-collapse')[0];
		const isOpen = nav.classList.toString().indexOf('show') > -1 || false;
		if (
			event.target.className !== 'nav-link product' &&
			event.target.className !== 'fa fa-caret-down product'
		) {
			this.openMegaMenu = false;
			this.openSub1 = this.openSub1 = this.openSub1 = '';
		}
		if (event.target.className.toString() !== 'fas fa-bars') {
			// toggler.classList.add('collapsed');
			// nav.classList.remove('show');
			// nav.classList.add('collapse');
		}
		if (event.target.className.toString() === 'fas fa-bars') {
			// this.scrolled = !this.scrolled;
			// if(!this.scrolled){
			// 	nav.classList.remove('show');
			// 	nav.classList.add('collapse');
			// }
		}
	}

	changeActiveCategory(category_id: string, level: number) {
		if (level == 1) {
			this.openSub1 =
				this.openSub1 === category_id.toString()
					? ''
					: category_id.toString();
		}
		if (level == 2) {
			this.openSub2 =
				this.openSub2 === category_id.toString()
					? ''
					: category_id.toString();
		}
		if (level == 3) {
			this.openSub3 =
				this.openSub3 === category_id.toString()
					? ''
					: category_id.toString();
		}
	}

	searchChange(event: any) {
		this.searchString = '';
		if (event.target.value && event.target.value.length > 0) {
			this.searchString = event.target.value;
		}
	}

	onScroll(event: any) {
		this.isMobile = false;
		if (window.innerWidth < this.mobileWidthLimit) {
			this.isMobile = true;
		}
		this.width = window.outerWidth;
		let uri = this.router.url.split('/')[1];
		if (uri === 'home') {
			this.isHome = true;
		}
		var B = document.body; //IE 'quirks'
		var D = document.documentElement; //IE with doctype
		D = D.clientHeight ? D : B;
		const nav = document.getElementsByClassName('navbar-collapse')[0];
		const isOpen = nav.classList.toString().indexOf('show') > -1;
		if (uri === 'home') {
			this.scrolled = D.scrollTop > this.checkScroll ? true : false;
		} else {
			this.scrolled = D.scrollTop > 210 ? true : false;
		}
	}

	onLoad(event: any) {
		this.isMobile = false;
		if (window.innerWidth < this.mobileWidthLimit) {
			this.isMobile = true;
		}
	}

	onResize(event: any) {
		this.isMobile = false;
		if (window.innerWidth < this.mobileWidthLimit) {
			this.isMobile = true;
		}
	}

	onSearchSubmit(element: any) {
		element.preventDefault();
		this.router.navigate(['/search', this.searchString]);
	}

	getMenuCategory() {
		this.http
			.post(this.httpService.mainUrl + 'getMenuCategory', {})
			.subscribe((result: any) => {
				this.emiter.emit('isloading', {
					type: 'isloading',
					data: false,
				});
				if (result.status == 1) {
					this.category =
						result.data && Array.isArray(result.data)
							? result.data
							: [];
					this.category.push({
						_id: '',
						name: 'All Product',
						children: [{ _id: '', name: 'View All Product' }],
					});
					this.store.dispatch(
						SET_CATEGORY({ category: result.data.valueOf() })
					);
				} else {
					this.category = [];
					this.category.push({
						_id: '',
						name: 'All Product',
						children: [{ _id: '', name: 'View All Product' }],
					});
				}
			});
	}

	async calculateTotal() {
		this.iscartCalculated = true;
		let subtotal = 0;
		let total = 0;
		let tax = 0;
		let shipping = 0;
		let discount = 0;
		let discount_price = 0;
		let self = this;
		const items: any = JSON.parse(JSON.stringify(this.items.valueOf()));
		if (items.length > 0) {
			items.map((item: any, index: any) => {
				items[index].qty = items[index].qty ? items[index].qty : 1;
				if (parseInt(items[index].offer_price) > 0) {
					items[index].total =
						items[index].offer_price * this.cart.items[index].qty;
				} else {
					items[index].total =
						items[index].price * this.cart.items[index].qty;
				}
				subtotal += items[index].total ? items[index].total : 0;
			});
		}
		tax = (subtotal * this.cart.tax_type) / 100;
		discount = (subtotal * this.cart.discount_type) / 100;
		this.cart.tax = !isNaN(tax) ? tax : 0;
		this.cart.discount = !isNaN(discount) ? discount : 0;
		discount_price = subtotal - discount;
		subtotal = discount_price + tax;
		this.cart.subtotal = subtotal > 0 ? subtotal : 0.0;
		this.cart.total = subtotal + this.cart.shipping_charge;
		this.items = JSON.parse(JSON.stringify(items));
		this.cart.items = JSON.parse(JSON.stringify(this.items));
		// this.store.dispatch(SET_CART({cart: items}));
	}

	updateCart() {
		this.flagRemove = true;
		setTimeout(() => {
			this.flagRemove = false;
		}, 500);
		this.store.dispatch(SET_CART({ cart: this.cart.items }));
	}

	async removeItem(data: any, index: any) {
		// this.flagRemove = true;
		let items: any = JSON.parse(JSON.stringify(this.items.valueOf()));
		items.splice(index, 1);
		const cart = items;
		this.cart.items = JSON.parse(JSON.stringify(items));
		this.items = JSON.parse(JSON.stringify(items));
		this.store.dispatch(SET_CART({ cart: this.cart.items }));
		this.iscartCalculated = false;
		this.calculateTotal();
		// this.cd.detectChanges();
		// this.notify.generateToast('danger', "Item Removed!", "Item Removed From Cart Successfully!");
		this.emiter.emit('cart_change', true, true, false);
		if (this.auth && this.auth._id) {
			this.updateUserList({ type: 1, items: cart });
		}
	}

	updateUserList(data: any) {
		const items = data.items && data.items.length > 0 ? data.items : [];
		this.http
			.post(this.httpService.mainUrl + 'addToUserList', {
				type: data.type,
				items: items,
				user_id: this.auth._id,
			})
			.subscribe(async (response: any) => {
				this.iscartLoaded = false;
				if (response.status != 1) {
					this.notify.generateToast(
						'warning',
						'Warning!',
						response.message
					);
				} else {
					// this.store.dispatch(SET_CART({cart: items}));
					// this.cart.items = items;
					// this.items = this.cart.items;
				}
				// await this.getUsersDetailsById(this.user._id);
			});
	}

	moveToCart(element: any) {
		this.flagRemove = true;
		setTimeout(() => {
			this.flagRemove = false;
		}, 500);
		document
			.getElementsByClassName('sidemenu-wrapper')[0]
			.classList.remove('show');
		$('.sidemenu-wrapper').removeClass('show');
		element.stopPropagation();
		element.preventDefault();
		this.router.navigate(['/cart']);
	}

	getPackageName(attribute: any) {
		let packageType = attribute?.package_type?.toUpperCase();
		if (packageType === 'GM') {
			packageType = 'g';
		}
		if (packageType === 'KG') {
			packageType = 'Kg';
		}
		if (packageType === 'LTR') {
			packageType = 'L';
		}
		let name = `${attribute.package_value}${packageType}`;
		return name;
	}
	onClickedOutside(e: Event, flag: boolean) {
		if (this.flagCart && this.showCart && !this.flagRemove) {
			this.flagCart = false;
			this.showCart = false;
		}
	}
	onClickedInSide(e: Event) {
		this.showCart = true;
		this.flagRemove = true;
		setTimeout(() => {
			this.flagRemove = false;
		}, 500);

		setTimeout(() => {
			this.flagCart = true;
		}, 500);
	}

	ngOnDestroy() {}
}

<app-breadcrumb></app-breadcrumb>
<section>
	<div class="certificate">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="certificate-title">
						<h1>Certificates</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="certificate-box">
						<div class="certificate-content">
							<p>
								MAA never chooses second best for her child and so maa’s choice neither would. Maa's Choice carries a large selection of snack nuts, nut mixes, trail mixes, and salad toppings, as well as a wide range of other products.
								Our high standards set us distinct, and our commitment to quality ensures that we continue to stock pantries, refrigerators, and freezers with the best Maa's Choice products on a daily basis. We feel enthusiastic certificate Maa's healthy eating habits since we know she chooses the best ingredients without sacrificing on the ingredients she doesn't want. All Maa's Choice products are approved by India's Food Safety and Standards Authority.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
